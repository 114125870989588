import React, { PureComponent } from 'react';

import Button from '../../../controls/Button';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { unite } from '../../../../common/helpers/unite';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import { LexicalEditor } from 'lexical';
import EditorViewer from '../../../controls/EditorControl/EditorViewer';
import EditorControl from '../../../controls/EditorControl/EditorControl';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import FluidHeight from '../../FluidHeight';

interface Props extends WithTranslation {
  disabled?: boolean;
  boardCard: BoardCard;
  newCard: boolean;
  onDescriptionChange: (value: string) => void;
  descriptionEditor: LexicalEditor | null;
  setDescriptionEditor: (editor: LexicalEditor | null) => void;
  showUpdatingBtns: boolean;
  setShowUpdatingBtns: (value: boolean) => void;
  cardAttachments: AttachmentDTO[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  editor: LexicalEditor | null;
  status: TRequestStatus;
  showLexical: boolean;
  form: {
    description: string | null;
  };
}

class AddCardDescription extends PureComponent<Props, State> {
  lexicalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.lexicalRef = React.createRef();
    this.state = {
      editor: null,
      status: 'idle',
      showLexical: false,
      form: {
        description: this.props.boardCard.description ?? null,
      },
    };
  }

  componentDidUpdate(): void {
    if (this.props.newCard) {
      this.setState({ showLexical: false });
    }
  }

  setShowLexical = (value: boolean) => {
    this.setState({
      showLexical: value,
      status: value ? 'idle' : this.state.status,
    });
  };

  setDescription = (value: string | null) => {
    this.setState({ form: { description: value } });
  };

  render() {
    const { t, boardCard, disabled, showUpdatingBtns } = this.props;
    const { showLexical, form } = this.state;

    return (
      <div>
        <FluidHeight>
          <div className="flex-row fill">
            <div className="column pb-sm">
              {boardCard.description.length === 0 && !showLexical && (
                <div
                  className={unite(
                    'lexical-button-trigger',
                    `add-description-${boardCard.id}`,
                    { hidden: showLexical },
                  )}
                  style={{ display: showLexical ? 'none' : 'block' }}
                >
                  {!disabled ? (
                    <Button
                      className="secondary-button fill text-left flex-h-start py-sm px-sm"
                      disabled={disabled}
                      onClick={() => {
                        this.setShowLexical(true);
                        this.props.setShowUpdatingBtns(true);
                      }}
                    >
                      <span className="faint-text text-sm">
                        {t('typeDescription')}
                      </span>
                    </Button>
                  ) : (
                    <div className="card opaque">
                      <span className="text-xs faint-text">
                        {t('noDescription')}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {boardCard.description.length > 0 && !showLexical && (
                <div>
                  <div className="pb-xs">
                    <EditorViewer
                      editorState={form.description ?? boardCard.description}
                      namespace={`description-viewer-${boardCard.id}`}
                    />
                  </div>
                  {!disabled && (
                    <Button
                      className="secondary-button lexical-button-trigger"
                      onClick={() => {
                        this.setShowLexical(true);
                        this.props.setShowUpdatingBtns(true);
                      }}
                    >
                      {(() => {
                        try {
                          JSON.parse(boardCard.description);
                          return (
                            <>
                              <span className="fad fa-pencil icon accent-text-green"></span>
                            </>
                          );
                        } catch {
                          return (
                            <>
                              <span className="fas fa-radiation-alt text-xl pr-2xs accent-text-red"></span>{' '}
                              <span className="fad fa-exclamation-triangle text-xl pr-2xs accent-text-red"></span>{' '}
                              <span className="fad fa-flask text-xl pr-sm accent-text-red"></span>{' '}
                            </>
                          );
                        }
                      })()}
                      <span className="text text-left">{t('edit')}</span>
                    </Button>
                  )}
                </div>
              )}
              {showLexical && (
                <>
                  <EditorControl
                    onChange={this.setDescription}
                    namespace={`description-editor-${boardCard.id}`}
                    initialEditorState={boardCard.description ?? null}
                    cardAttachments={this.props.cardAttachments}
                  />
                  <div
                    className={`flex-row flex-v-center ${showUpdatingBtns ? 'my-xs' : ''}`}
                  >
                    {showUpdatingBtns && !disabled && (
                      <>
                        <Button
                          className="primary-button mx-xs"
                          onClick={() => {
                            this.props.onDescriptionChange(
                              form.description ?? '',
                            );
                            this.props.setShowUpdatingBtns(false);
                            this.setShowLexical(false);
                          }}
                        >
                          <span className="text">{t('updateDescription')}</span>
                        </Button>
                        <Button
                          className="secondary-button mr-xs"
                          onClick={() => {
                            this.props.setShowUpdatingBtns(false);
                            this.setShowLexical(false);
                          }}
                        >
                          <span className="text">{t('cancel')}</span>
                        </Button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </FluidHeight>
      </div>
    );
  }
}

export default withStyledTranslation('addCardDescription')(AddCardDescription);
