import React from 'react';
import LinkButton from '../../../controls/LinkButton';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { useTranslation } from 'react-i18next';

interface CreateBoardButtonProps {
  disabled: boolean;
  team?: TeamDTO;
  shrinkOnMobile?: boolean;
}

const CreateBoardButton: React.FC<CreateBoardButtonProps> = ({
  disabled,
  team,
  shrinkOnMobile,
}) => {
  const { t } = useTranslation('home');

  return (
    <LinkButton
      to={team ? `/board-add/${team.id}/${team.name}` : '/board-add'}
      disabled={disabled}
      className="secondary-button"
    >
      {shrinkOnMobile ? (
        <>
          <div className="hidden-mobile">
            <span className="fad fa-layer-plus icon pr-xs"></span>
            <span className="text no-wrap">{t('createBoard')}</span>
          </div>
          <div className="visible-mobile">
            <span className="fad fa-layer-plus"></span>
            <span className="sr-only">{t('createBoard')}</span>
          </div>
        </>
      ) : (
        <>
          <span className="fad fa-layer-plus icon pr-xs"></span>
          <span className="text no-wrap">{t('createBoard')}</span>
        </>
      )}
    </LinkButton>
  );
};

export default CreateBoardButton;
