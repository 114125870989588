import React, { useContext } from 'react';
import Button from '../../controls/Button';
import { TContextMenu } from '../../../common/types/ContextMenu';
import DirectionalButton from '../../controls/DirectionalButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import BoardContext from '../../../common/contexts/BoardContext';

interface Props extends WithTranslation {
  directionalButton: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  toggleShowConfirmDelete?: () => void;
}

const CardOptions: React.FC<Props> = (props) => {
  const { t, directionalButton, toggleShowConfirmDelete } = props;
  const boardContext = useContext(BoardContext);

  return (
    <>
      <li>
        <DirectionalButton
          directionalButton={directionalButton}
          className="flex-h-spread"
        />
      </li>
      <li>
        <Button
          className="ghost-button"
          onClick={toggleShowConfirmDelete}
          disabled={
            !['admin', 'owner'].includes(boardContext?.board?.user.role)
          }
        >
          <span className="fal fa-trash icon"></span>
          <span className="text">{t('delete')}</span>
        </Button>
      </li>
    </>
  );
};

export default withStyledTranslation('boardArchivedCardFlyout')(CardOptions);
