import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import { useEffect, useRef } from 'react';
import { $wrapNodes } from '@lexical/selection';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
} from '@lexical/list';
import { $createCodeNode } from '@lexical/code';

export type BlockType =
  | 'paragraph'
  | 'quote'
  | 'code'
  | 'h1'
  | 'h2'
  | 'ul'
  | 'ol'
  | 'check';

export const supportedBlockTypes = new Set([
  'paragraph',
  'quote',
  'code',
  'h1',
  'h2',
  'ul',
  'ol',
  'check',
]);

export const blockTypeToBlockName = {
  code: 'Code Block',
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  ol: 'Numbered List',
  ul: 'Bulleted List',
  check: 'Check List',
  paragraph: 'Normal',
  quote: 'Quote',
};

export default function BlockMenuOptions({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
  setBlockType,
}: {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: React.RefObject<HTMLDivElement>;
  setShowBlockOptionsDropDown: (val: boolean) => void;
  setBlockType: (val: BlockType) => void;
}) {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatList = (listType: 'number' | 'bullet' | 'check') => {
    if (listType === 'number' && blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      setBlockType('ol');
    } else if (listType === 'bullet' && blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      setBlockType('ul');
    } else if (listType === 'check' && blockType !== 'check') {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
      setBlockType('check');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatCode = () => {
    if (blockType !== 'code') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <>
      <li>
        <button
          className="ghost-button"
          onClick={formatParagraph}
        >
          <span className="icon fas fa-text-size" />
          <span className="text">Normal</span>
          {blockType === 'paragraph' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatLargeHeading}
        >
          <span className="icon fas fa-h1" />
          <span className="text">Large Heading</span>
          {blockType === 'h1' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatSmallHeading}
        >
          <span className="icon fas fa-h2" />
          <span className="text">Small Heading</span>
          {blockType === 'h2' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('bullet')}
        >
          <span className="icon fas fa-list" />
          <span className="text">Bullet List</span>
          {blockType === 'ul' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('number')}
        >
          <span className="icon fas fa-list-ol" />
          <span className="text">Numbered List</span>
          {blockType === 'ol' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={() => formatList('check')}
        >
          <span className="icon fas fa-check-square" />
          <span className="text">Check List</span>
          {blockType === 'check' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatQuote}
        >
          <span className="icon fas fa-quote-right" />
          <span className="text">Quote</span>
          {blockType === 'quote' && <span className="active" />}
        </button>
      </li>
      <li>
        <button
          className="ghost-button"
          onClick={formatCode}
        >
          <span className="icon fas fa-code" />
          <span className="text">Code Block</span>
          {blockType === 'code' && <span className="active" />}
        </button>
      </li>
    </>
  );
}
