import { AutoLinkPlugin as LexicalAutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';

const URL_MATCHER = /https?:\/\/[^\s]+/g;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    return match
      ? {
          index: match.index,
          length: match[0].length,
          text: match[0],
          url: match[0],
        }
      : null;
  },
];

export default function AutoLinkPlugin() {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
}
