import { Component } from 'react';
import LinkButton from '../../controls/LinkButton';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

type Props = WithTranslation;

class LegalFooter extends Component<Props> {
  changeLanguage = (_: unknown, value: unknown) => {
    const newLang = String(value);
    this.props.i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  render() {
    const { t } = this.props;

    return (
      <div
        className="legal-footer"
        style={{}}
      >
        <p>
          <span className="text-xs">{t('agreementText')}</span>{' '}
          <LinkButton
            to={`${process.env.REACT_APP_DOCS_BASE_URL}/terms-of-service`}
            target="_blank"
            className="link-button"
            isRoute={false}
          >
            <span className="text text-xs">{t('termsAndConditions')}</span>
          </LinkButton>{' '}
          <span className="text-xs">{t('andText')}</span>{' '}
          <LinkButton
            to={`${process.env.REACT_APP_DOCS_BASE_URL}/privacy-policy`}
            target="_blank"
            className="link-button"
            isRoute={false}
          >
            <span className="text text-xs">{t('privacyPolicy')}</span>
          </LinkButton>
          .
        </p>
        {/* <ComboBox
          id="language-select"
          label={t('language.chooseLanguage')}
          title={t('language.chooseLanguage')}
          value={localStorage.getItem('language') || 'en_US'}
          options={LANGUAGE_OPTIONS}
          onChange={this.changeLanguage}
          formGroupClassname="pb-0 mt-sm flex-h-center"
          getValue={(option) => option.value}
          getLabel={(option) => option.label}
          srOnly={true}
        /> */}
        <span className="text-xs">{t('rightsReserved')}</span>
      </div>
    );
  }
}
export default withStyledTranslation('legalFooter')(LegalFooter);
