import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { BoardDTO } from '../../../../common/api/dtos/Board';
import {
  getBoardSettings,
  updateBoardSettings,
} from '../../../../common/api/endpoints/board';
import AppContext, {
  IAppContext,
} from '../../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../../common/contexts/NotificationsContext';
import BoardContext from '../../../../common/contexts/BoardContext';
import {
  applyBranding,
  setLocalStorageBoardColor,
} from '../../../../common/helpers/boardColor';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import MenuTabs from '../../../controls/MenuTabs';
import { withContextAdapters } from '../../../partials/ContextAdapter/withContextAdapter';
import BoardTopbar from '../../../partials/Topbar/BoardTopbar';
import AppearanceTab from '../../../partials/board/BoardSettings/AppearanceTab';
import GeneralInformationTab from '../../../partials/board/BoardSettings/GeneralInformationTab';
import HouseKeepingTab from '../../../partials/board/BoardSettings/HouseKeepingTab';
import { MsgUpdateBoardSettings, MsgWs } from '../BoardProvider/wsHandlers';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import CircleOff from '../../../animations/icons/circle_off';
import CircleOn from '../../../animations/icons/circle_on';
import {
  IBoardContext,
  UpdateBoardSettingsData,
} from '../../../../common/interfaces/BoardContext';

interface FormData {
  name: string;
  description: string;
  cardNrPrefix: string;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
  color: string;
  thumbnail: string;
  storageUsage?: number;
}

interface GeneralInfo {
  name: string;
  description: string;
  cardNrPrefix: string;
  columnCardLimitState: 'disabled' | 'enabled' | 'enforced';
  color: string;
  thumbnail: string;
}

interface Appearance {
  color: string;
  thumbnail: string;
}

interface RouteParams {
  routeBoardId: string;
}

interface FormUpdateStatus {
  general: TRequestStatus;
  appearance: TRequestStatus;
}

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  isOwner: boolean;
  setBoardSettings: (
    boardSettings: UpdateBoardSettingsData,
    callback?: () => void,
  ) => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
}
type ExternalProps = RouteComponentProps<RouteParams>;
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  formStatus: TRequestStatus;
  generalTabStatus: TRequestStatus;
  formUpdateStatus: FormUpdateStatus;
  archiveStatus: TRequestStatus;
  formData: FormData;
  favorite: boolean;
  activeTab: string;
  initialBoardData?: FormData;
}

class BoardSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    applyBranding(this.props.match.params.routeBoardId);

    this.state = {
      generalTabStatus: 'idle',
      formStatus: 'idle',
      formUpdateStatus: {
        general: 'idle',
        appearance: 'idle',
      },
      archiveStatus: 'idle',
      formData: {
        name: '',
        description: '',
        cardNrPrefix: '',
        columnCardLimitState: 'disabled',
        color: '',
        thumbnail: '',
        storageUsage: 0,
      },
      favorite: false,
      activeTab: 'general',
    };
  }

  componentDidMount() {
    this.fetchBoardSettings(this.props.match.params.routeBoardId);

    this.props.addWsListener(
      'update_board_settings',
      this.handleWsUpdateFields,
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    if (prevState.activeTab !== this.state.activeTab) {
      this.setActiveTabStatus(prevState);
      this.resetForm();
    }
  }

  resetForm = (): void => {
    if (this.state.initialBoardData) {
      this.setState({
        formData: this.state.initialBoardData,
      });
    }
  };

  componentWillUnmount(): void {
    this.props.removeWsListener(
      'update_board_settings',
      this.handleWsUpdateFields,
    );
  }

  // update fields when board settings update ws message is received
  handleWsUpdateFields = (message: MsgWs) => {
    const wsMessage = message as MsgUpdateBoardSettings;

    this.setState((prevState) => {
      return {
        formData: {
          ...prevState.formData,
          ...wsMessage.data,
        },
        initialBoardData: {
          ...prevState.initialBoardData,
          ...wsMessage.data,
        },
      };
    });
  };

  setActiveTabStatus = (prevState: Readonly<State>) => {
    const prevActiveTabStatus =
      prevState.formUpdateStatus[
        this.state.activeTab as keyof FormUpdateStatus
      ];
    const activeTabStatus =
      prevActiveTabStatus !== 'success' ? prevActiveTabStatus : 'idle';
    this.setState({
      formUpdateStatus: {
        ...prevState.formUpdateStatus,
        [this.state.activeTab]: activeTabStatus,
      },
    });
  };

  updateForm = <K extends keyof FormData>(field: K, value: FormData[K]) => {
    const formData = this.state.formData;
    this.setState({
      formData: {
        ...formData,
        [field]: value,
      },
    });
  };

  fetchBoardSettings = async (id: string) => {
    this.setState({
      generalTabStatus: 'loading',
    });

    try {
      const currentBoardSettings = await getBoardSettings(id);

      this.setState({
        generalTabStatus: 'success',
        formData: currentBoardSettings as BoardDTO,
        favorite: currentBoardSettings.favorite || false,
        initialBoardData: currentBoardSettings as BoardDTO,
      });

      setLocalStorageBoardColor(id, String(currentBoardSettings.color));
    } catch (err) {
      this.setState({
        generalTabStatus: 'error',
      });
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  updateBoardSettings = async (id: string, body: FormData) => {
    this.setState({
      formUpdateStatus: {
        ...this.state.formUpdateStatus,
        [this.state.activeTab]: 'loading',
      },
    });

    try {
      const boardSettings = await updateBoardSettings(id, body);

      this.setState(
        {
          formUpdateStatus: {
            ...this.state.formUpdateStatus,
            [this.state.activeTab]: 'success',
          },
          formData: { ...boardSettings } as BoardDTO,
          initialBoardData: { ...boardSettings } as BoardDTO,
        },
        () => {
          this.props.setBoardSettings(boardSettings);
          localStorage.setItem(
            `board-${id}-color`,
            String(boardSettings.color),
          );
        },
      );
    } catch (err) {
      this.setState(
        {
          formUpdateStatus: {
            ...this.state.formUpdateStatus,
            [this.state.activeTab]: 'error',
          },
        },
        () => {
          showErrorNotifications(err, this.props.setMessages);
        },
      );
    }
  };

  handleSubmit = () => {
    this.setState({
      formStatus: 'loading',
    });

    this.updateBoardSettings(
      this.props.match.params.routeBoardId,
      this.state.formData,
    );
  };

  toggleFavorite = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        favorite: !prevState.favorite,
      };
    });
  };

  setActiveTab = (tab: string) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const { t } = this.props;
    const generalInfoData: GeneralInfo = {
      name: this.state.formData.name,
      description: this.state.formData.description,
      cardNrPrefix: this.state.formData.cardNrPrefix,
      columnCardLimitState: this.state.formData.columnCardLimitState,
      color: this.state.formData.color,
      thumbnail: this.state.formData.thumbnail,
    };
    const appearanceData: Appearance = {
      color: this.state.formData.color,
      thumbnail: this.state.formData.thumbnail,
    };

    return (
      <>
        <BoardTopbar
          boardName={this.state.formData.name}
          favorite={this.state.favorite}
          routeBoardId={this.props.match.params.routeBoardId}
          toggleFavorite={this.toggleFavorite}
          enableFilter={false}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
        />
        <div className="flex-row fill">
          <div className="column pt-0">
            <div className="flex-row">
              <div className="column pb-2xs">
                <h2 className="primary-title h3 normalcase">
                  {t('boardSettings')}
                </h2>
              </div>
            </div>
            <MenuTabs
              parentUrl={this.props.match.url}
              vertical={true}
              tabs={[
                {
                  id: 'general',
                  label: t('general'),
                  icon: 'fal fa-circle',
                  animatedOffIcon: <CircleOff />,
                  animatedOnIcon: <CircleOn />,
                  content: (
                    <>
                      <GeneralInformationTab
                        formData={generalInfoData}
                        pageStatus={this.state.generalTabStatus}
                        formStatus={this.state.formUpdateStatus.general}
                        updateForm={this.updateForm}
                        handleSubmit={this.handleSubmit}
                        {...this.props}
                      />
                    </>
                  ),
                },
                {
                  id: 'board-appearance',
                  label: t('appearance'),
                  icon: 'fal fa-brush',
                  content: (
                    <>
                      <AppearanceTab
                        formData={appearanceData}
                        boardName={this.state.formData.name}
                        initialFormData={
                          this.state.initialBoardData
                            ? {
                                color: this.state.initialBoardData?.color,
                                thumbnail:
                                  this.state.initialBoardData?.thumbnail,
                              }
                            : undefined
                        }
                        formUpdateStatus={
                          this.state.formUpdateStatus.appearance
                        }
                        updateForm={this.updateForm}
                        handleSubmit={this.handleSubmit}
                        resetForm={this.resetForm}
                        {...this.props}
                      />
                    </>
                  ),
                },
                {
                  id: 'housekeeping',
                  label: t('housekeeping'),
                  icon: 'fal fa-wrench',
                  content: (
                    <HouseKeepingTab
                      boardInfo={{
                        storageUsage: this.state.formData.storageUsage,
                      }}
                      {...this.props}
                    />
                  ),
                },
              ]}
              defaultTab="general"
              {...this.props}
            />
          </div>
        </div>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages ?? (() => false),
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      isOwner: ctx.board.user.role === 'owner',
      setBoardSettings: ctx.updateBoardSettings ?? (() => false),
      addWsListener: ctx.addWsListener,
      removeWsListener: ctx.removeWsListener,
    };
  },
};
const ContextAdapter = withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('boardSettings')(BoardSettings),
  AppContextAdapter,
  BoardContextAdapter,
);

export default ContextAdapter;
