import React, { ChangeEvent, Component } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import ComboBox from '../../../controls/ComboBox';
import ToggleSwitch from '../../../controls/ToggleSwitch';
import { THEME_OPTIONS } from '../../../../common/configs/ThemeOptions';
import Accordion from '../../../controls/Accordion';
import { WithTranslation } from 'react-i18next';
import { LANGUAGE_OPTIONS } from '../../../../common/configs/LanguageOptions';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { HOUR_OPTIONS } from '../../../../common/configs/HourOptions';
import dayjsHelper from '../../../../common/helpers/dayjsHelper';

interface State {
  language: string;
  timeFormat: number;
}

class Application extends Component<WithTranslation, State> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: WithTranslation) {
    super(props);

    this.state = {
      language: 'en_US',
      timeFormat: 12,
    };
  }

  setAccountTheme = (_: unknown, value: unknown) => {
    this.context.loggedUser?.setAccountSettings({ theme: String(value) });
  };

  setAccountHighContrast = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      highContrast: event.target.checked,
    });
  };

  setAccountReducedMotion = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      reducedMotion: event.target.checked,
    });
  };

  setAccountColorBlindHelpers = (event: ChangeEvent<HTMLInputElement>) => {
    this.context.loggedUser?.setAccountSettings({
      colorBlindHelpers: event.target.checked,
    });
  };

  setAccountLanguage = (_: unknown, value: unknown) => {
    const newLang = String(value);
    this.props.i18n.changeLanguage(newLang);

    this.setState({
      language: newLang,
    });

    this.context.loggedUser?.setAccountSettings({
      language: newLang,
    });
  };

  setAccountTimeFormat = (_: unknown, value: unknown) => {
    const newTimeFormat = Number(value);

    this.setState({
      timeFormat: newTimeFormat,
    });

    this.context.loggedUser?.setAccountSettings({
      timeFormat: newTimeFormat,
    });
  };

  componentDidMount(): void {
    const accountSettings = this.context.loggedUser!.accountSettings;

    this.setState({
      timeFormat: accountSettings.timeFormat ?? 12,
    });
  }

  render() {
    const { t } = this.props;
    const loggedUser = this.context.loggedUser!;
    const accountSettings = loggedUser.accountSettings;
    const experiments = this.context.experimentFlags || {};

    const translatedThemeOptions = THEME_OPTIONS.map((option) => ({
      ...option,
      label: t(`themeOptions.${option.value}`),
    }));

    return (
      <>
        <div className="flex-row">
          <div className="column pb-xs">
            <h1 className="primary-title h3 normalcase">
              {t('applicationSettingsTitle')}
            </h1>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pt-2xs">
            <Accordion
              accordionSlug="theme"
              isOpen={true}
              iconClasses="fal fa-brush icon"
              title={t('theme.title')}
              subheading={t('theme.subtitle')}
            >
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-adjust icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('theme.interfaceMode')}</span>
                  </div>
                  <div className="column pb-xs">
                    <ComboBox
                      id="toggle-theme"
                      label={t('theme.theme')}
                      value={accountSettings.theme}
                      disabled={false}
                      options={translatedThemeOptions} // Assume this is defined elsewhere in your code
                      onChange={this.setAccountTheme}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(op) => op.value}
                      getLabel={(op) => op?.label}
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>
            <Accordion
              accordionSlug="account-language"
              isOpen={false}
              iconClasses="fal fa-globe icon"
              title={t('language.title')}
              subheading={t('language.subtitle')}
              disabled={false}
            >
              {experiments.locales && (
                <div className="accordion-row">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column pb-xs">
                      <span className="fal fa-language icon"></span>
                    </div>
                    <div className="column fill pb-xs">
                      <span>{t('language.displayLanguage')}</span>
                    </div>
                    <div className="column pb-2xs">
                      <ComboBox
                        id="language-select"
                        label={t('language.chooseLanguage')}
                        value={accountSettings.language}
                        options={LANGUAGE_OPTIONS} // Assume this is defined elsewhere in your code
                        onChange={this.setAccountLanguage}
                        formGroupClassname="pb-0 mb-0"
                        getValue={(option) => option.value}
                        getLabel={(option) => option.label}
                        disabled={false}
                        srOnly={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="accordion-row">
                <div className="flex-row no-reflow squeeze flex-v-center">
                  <div className="column pb-xs">
                    <span className="fal fa-clock icon"></span>
                  </div>
                  <div className="column fill pb-xs">
                    <span>{t('language.timeFormat')}</span>
                  </div>
                  <div className="column pb-2xs">
                    <ComboBox
                      id="hour-format"
                      label={t('language.chooseTimeFormat')}
                      title={t('language.chooseTimeFormat')}
                      value={this.state.timeFormat.toString()}
                      options={HOUR_OPTIONS}
                      onChange={this.setAccountTimeFormat}
                      formGroupClassname="pb-0 mb-0"
                      getValue={(option) => option.value}
                      getLabel={(option) =>
                        dayjsHelper(new Date()).format(
                          option && option.value == '12' ? 'hh:mm A' : 'HH:mm',
                        )
                      }
                      srOnly={true}
                    />
                  </div>
                </div>
              </div>
            </Accordion>

            <Accordion
              accordionSlug="accessibility"
              isOpen={false}
              iconClasses="fal fa-universal-access icon"
              title={t('accessibility.title')}
              subheading={t('accessibility.subtitle')}
              disabled={false}
            >
              <ToggleSwitch
                id="accountReducedMotion"
                name="accountReducedMotion"
                changeMethod={this.setAccountReducedMotion}
                isChecked={accountSettings.reducedMotion}
                multiline={true}
                tight={true}
              >
                <label htmlFor="accountReducedMotion">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-stars icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.reduceMotion')}</span>
                      <br />
                      <small className="faint-text">
                        {t('accessibility.reduceMotionDesc')}
                      </small>
                    </div>
                  </div>

                  <span className="state">
                    {accountSettings.reducedMotion ? t('On') : t('Off')}
                  </span>
                </label>
              </ToggleSwitch>
              <ToggleSwitch
                id="accountColorBlindHelpers"
                name="accountColorBlindHelpers"
                changeMethod={this.setAccountColorBlindHelpers}
                isChecked={accountSettings.colorBlindHelpers}
                multiline={true}
                tight={true}
                isDisabled={false}
              >
                <label htmlFor="accountColorBlindHelpers">
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span className="fal fa-tint-slash icon"></span>
                    </div>
                    <div className="column py-0">
                      <span>{t('accessibility.colorBlindHelpers')}</span>
                      <br />
                      <small className="faint-text">
                        {t('accessibility.colorBlindHelpersDesc')}
                      </small>
                    </div>
                  </div>
                  <span className="state">
                    {accountSettings.colorBlindHelpers ? t('On') : t('Off')}
                  </span>
                </label>
              </ToggleSwitch>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('applicationSettings')(Application);
Application.contextType = AppContext;
