import React, { ChangeEvent } from 'react';

interface Props {
  id: string;
  name: string;
  checked: boolean;
  changeMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  srOnly?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<Props> = ({
  id,
  name,
  checked,
  changeMethod,
  className,
  required,
  disabled,
  label,
  srOnly,
  onBlur,
}) => {
  return (
    <>
      <input
        id={id}
        type="radio"
        name={name}
        disabled={disabled}
        onChange={changeMethod}
        className={className}
        onBlur={onBlur}
        checked={checked}
      />
      {label && (
        <label
          htmlFor={id}
          className={srOnly ? 'sr-only' : ''}
        >
          {label}
          {required ? '*' : ''}
        </label>
      )}
    </>
  );
};

export default Radio;
