import React from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import TextBox from '../../controls/TextBox';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import { InviteeDTO, MemberDTO } from '../../../common/api/dtos/Member';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface AppContextProps {
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  boardId: string;
  members: (MemberDTO | InviteeDTO)[];
  addMember: (member: MemberDTO | InviteeDTO) => void;
}
interface ExternalProps {
  memberQuery: string;
  setMemberQuery: (value: string) => void;
  isValidQueryMember: boolean;
  userSearchOff?: boolean;
  status: TRequestStatus;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  status: TRequestStatus;
  serverErrors: string[];
}

class MemberSearchMenu extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      status: this.props.status,
      serverErrors: [],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <div className="fill">
            <TextBox
              type="text"
              name="userSearch"
              id="userSearch"
              value={this.props.memberQuery}
              onChange={(e) => this.props.setMemberQuery(e.target.value)}
              srOnly={true}
              label={t('nameOrEmail')}
              placeholder={t('nameOrEmail')}
              tight={true}
              autoComplete="off"
              ariaAutocomplete="none"
              className={`${this.props.userSearchOff ? 'hidden-mobile' : null}`}
            />
          </div>
        </li>
        {this.props.isValidQueryMember && (
          <li>
            <div className="fill">
              <small className="faint-text separator text-chunk">
                {t('newMembersCannotBeInvited')}
              </small>
            </div>
          </li>
        )}
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      members: ctx.board.members,
      addMember: ctx.addMember,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('memberSearchMenu')(MemberSearchMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
