import React from 'react';
import { DroppableProvided } from '@wavemyth/react-beautiful-dnd';

interface Props {
  className?: string;

  // the below is for drag n drop functionality only
  // do not add anything below if it's not for drag n drop (for easier readability)
  provided?: DroppableProvided;
}

export default class BoardColumnBody extends React.Component<Props> {
  scrollContentRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.scrollContentRef = React.createRef();
  }

  render() {
    return (
      <div
        className="flex-row tightest fill"
        ref={this.scrollContentRef}
      >
        <div
          className="column"
          ref={this.props.provided?.innerRef}
          {...this.props.provided?.droppableProps}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
