import React, { Component, KeyboardEvent } from 'react';
import LinkButton from '../../../controls/LinkButton';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '@wavemyth/react-beautiful-dnd';
import { getDropStyle } from '../../../../common/helpers/getDropStyle';
import { createSlug } from '../../../../common/helpers/createSlug';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { BoardItemDTO, BoardDTO } from '../../../../common/api/dtos/Board';
import AppContext from '../../../../common/contexts/AppContext';
import BoardListingCardExpanded from './BoardListingCardExpanded';
import {
  getBoard,
  setFavoriteBoard,
} from '../../../../common/api/endpoints/board';
import Button from '../../../controls/Button';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';

interface Props extends WithTranslation {
  board: BoardItemDTO;
  className?: string;
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  showReorder?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  small?: boolean;
  subscription:
    | 'subscribed'
    | 'unsubscribed'
    | 'willCancel'
    | 'willDelete'
    | 'paymentIssue'
    | 'none';
  callback?: () => void;
}

interface State {
  isFavorite: boolean;
  isExpanded: boolean;
  isAnimating: boolean;
  expandedBoardDetails: Partial<BoardDTO> | null;
  loading: boolean;
  favoriteStatus: 'idle' | 'loading' | 'success';
}

class BoardListingCard extends Component<Props, State> {
  buttonRef = React.createRef<HTMLButtonElement>();
  expansionTimeout: NodeJS.Timeout | null = null;
  constructor(props: Props) {
    super(props);
    this.state = {
      isFavorite: false,
      isExpanded: false,
      isAnimating: false,
      expandedBoardDetails: null,
      loading: false,
      favoriteStatus: 'idle',
    };
  }

  setFavorite = async () => {
    this.setState({
      favoriteStatus: 'loading',
    });

    try {
      await setFavoriteBoard(this.props.board.id, !this.state.isFavorite);

      this.setState(
        {
          favoriteStatus: 'success',
          isFavorite: !this.state.isFavorite,
        },
        () => {
          if (this.props.callback) {
            this.props.callback();
          }
        },
      );
    } catch (error) {
      showErrorNotifications(error, this.context.setMessages);
    } finally {
      this.setState({
        favoriteStatus: 'idle',
      });
    }
  };

  componentDidMount(): void {
    this.setState({ isFavorite: this.props.board.favorite });

    if (this.buttonRef.current) {
      this.buttonRef.current.addEventListener(
        'mouseenter',
        this.handleMouseEnter,
      );
      this.buttonRef.current.addEventListener(
        'mouseleave',
        this.handleMouseLeave,
      );
    }
  }

  componentWillUnmount(): void {
    if (this.buttonRef.current) {
      this.buttonRef.current.removeEventListener(
        'mouseenter',
        this.handleMouseEnter,
      );
      this.buttonRef.current.removeEventListener(
        'mouseleave',
        this.handleMouseLeave,
      );
    }
  }

  handleMouseEnter = (ev: MouseEvent) => {
    if (ev.target instanceof HTMLElement) {
      this.expansionTimeout = setTimeout(async () => {
        if (!this.state.expandedBoardDetails) {
          this.setState({ loading: true });
          const expandedBoardDetails = await getBoard(this.props.board.id);
          this.setState({ expandedBoardDetails, loading: false });
        }
        this.setState({ isExpanded: true, isAnimating: true });
      }, 400);
    }
  };

  handleMouseLeave = (ev: MouseEvent) => {
    if (ev.target instanceof HTMLElement) {
      if (this.expansionTimeout) {
        clearTimeout(this.expansionTimeout);
      }
      this.setState({ isExpanded: false, isAnimating: true });
    }
  };

  handleSetFavorite = (
    ev: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (ev.type === 'mousedown') {
      ev.preventDefault();
    }
    if (ev.type === 'click') {
      ev.preventDefault();
    }
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Tab') {
      ev.preventDefault();
    }
    if (ev.type === 'keydown' && (ev as KeyboardEvent).key !== 'Enter') return;
    this.setFavorite();
  };

  evaluateURL() {
    const cleanedName = this.props.board.name.replaceAll(/%/g, '');
    const boardSlug = createSlug(cleanedName);
    return `/board/${this.props.board.id}/view/${boardSlug}`;
  }
  render() {
    const flagEnabled = this.context.experimentFlags?.boardFlashcards;
    const expandable = !this.props.small && flagEnabled;

    return (
      <LinkButton
        to={this.evaluateURL()}
        className={[
          'card-button flex-v-start',
          this.props.className,
          expandable ? 'expandable' : '',
          this.state.isExpanded ? 'expanded' : '',
          this.state.isAnimating ? 'animating' : '',
          this.props.board.color ? `accent-${this.props.board.color}` : '',
        ].join(' ')}
        disabled={this.props.showReorder || this.props.disabled}
      >
        <div className="fill">
          <div className="fill flex-v-center">
            {this.props.subscription == 'willDelete' &&
              this.props.board.paid && (
                <span
                  className="fas fa-clock negative-text top-tight right-tight"
                  style={{ pointerEvents: 'all' }}
                  title="This board is scheduled for deletion"
                >
                  <span className="sr-only">
                    This board is scheduled for deletion
                  </span>
                </span>
              )}
            <div
              className={[
                'thumbnail-frame-component',
                this.props.board.color
                  ? `accent-${this.props.board.color}`
                  : '',
                this.props.small ? `small mr-xs` : `mr-sm`,
              ].join(' ')}
              ref={this.props.innerRef}
              {...this.props.provided?.draggableProps}
              {...this.props.provided?.dragHandleProps}
              style={
                this.props.snapshot
                  ? getDropStyle(
                      this.props.provided?.draggableProps.style,
                      this.props.snapshot,
                    )
                  : this.props.provided?.draggableProps.style
              }
            >
              <Thumbnail
                thumbnailData={this.props.board.thumbnail}
                classes={`radius ${this.props.small ? 'size-48' : 'size-64'}`}
                title={this.props.board.name}
              />
            </div>
            <div className="fill">
              <div className="flex-row fill  py-0">
                <div className="column py-0">
                  <h3 className="text-400 text-sm normalcase multiline-ellipsis l1 mb-2xs">
                    {this.props.board.name}
                  </h3>
                  {this.props.board.description && (
                    <p className="text-300 faint-text text-xs multiline-ellipsis l1">
                      {this.props.board.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {flagEnabled && this.props.small !== true && (
            <div className="expandable-container">
              <div className="expandable-body">
                {this.state.isExpanded && (
                  <>
                    <Button
                      className="ghost-button top right"
                      onClick={this.handleSetFavorite}
                      onKeyDown={this.handleSetFavorite}
                    >
                      {this.state.favoriteStatus === 'loading' ? (
                        <span className="loader"></span>
                      ) : (
                        <span
                          className={`${this.state.isFavorite ? 'fas' : 'fal'} fa-star
                          }`}
                        ></span>
                      )}
                    </Button>
                    <BoardListingCardExpanded
                      boardId={this.props.board.id}
                      color={this.props.board.color}
                      boardDetails={this.state.expandedBoardDetails}
                      loading={this.state.loading}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </LinkButton>
    );
  }
}

export default withStyledTranslation('boardListingCard')(BoardListingCard);
BoardListingCard.contextType = AppContext;
