import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
// import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import {
  $convertToMarkdownString,
  $convertFromMarkdownString,
} from '@lexical/markdown';
import React, { PureComponent } from 'react';
import CustomPlugins from './plugins/CustomPlugins';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorConfig,
  LexicalEditor,
} from 'lexical';
import { CUSTOM_TRANSFORMERS } from './transformers/CustomTransformers';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { EditorState } from 'draft-js';
import CONDUIT_BRIDGE from '../EditorControl/themes/conduit';

interface Props {
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  card: { id: string; description: string };
  handleChange: (value: string, cardId?: string) => void;
  namespace: string;
  setEditor: (editor: LexicalEditor) => void;
  editor: LexicalEditor | null;
  value: string;
  updateTrigger?: string;
  showLexical?: boolean; // used when lexical is opened with a separate button
  setShowLexical?: (value: boolean) => void; // used when lexical is opened with a  separate button
  buttonTriggered?: boolean; // used when lexical is opened with a separate button
  updateDescription?: boolean;
  cancelDescription?: boolean;
  setShowUpdatingBtns?: (value: boolean) => void;
}

interface State {
  isEditorFocused: boolean;
}

export default class LexicalControl extends PureComponent<Props, State> {
  editorStateRef: React.RefObject<EditorState>;
  initialEditorConfig: InitialConfigType;
  lexicalRef: React.RefObject<HTMLDivElement>;
  handleClickOutsideFunction: (e: MouseEvent) => void;
  removeNodeListener: () => void;
  removeEditableListener: () => void;
  editorRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.editorStateRef = React.createRef();
    this.lexicalRef = React.createRef();
    this.editorRef = React.createRef();
    this.handleClickOutsideFunction = (e) => {
      this.handleClickOutside(e, () => this.handleClose(), this.lexicalRef);
    };
    this.initialEditorConfig = {
      // The editor theme
      editorState: () => null,
      editable: this.props.editor
        ? this.props.editor.isEditable()
        : this.props.disabled
          ? false
          : false,
      theme: CONDUIT_BRIDGE,
      namespace: this.props.namespace,
      // Handling of errors during update
      onError(error: Error) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
      ],
    };
    this.state = {
      isEditorFocused: false,
    };
    this.removeNodeListener = () => false;
    this.removeEditableListener = () => false;
  }

  componentDidMount() {
    this.lexicalRef.current?.addEventListener(
      'mousedown',
      this.handleMouseDown,
    );

    this.lexicalRef.current?.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.handleCancel();
        e.stopPropagation();
      }
    });
    // if a link contains a "borddo" href, make it into a button
    const createDOM = LinkNode.prototype.createDOM;
    LinkNode.prototype.createDOM = function (config: EditorConfig) {
      const dom = createDOM.apply(this, [config]);
      const hrefLink = dom.getAttribute('href');
      if (hrefLink && hrefLink.includes('borddo')) {
        dom.setAttribute('target', '_self');
        const urlLink = new URL(hrefLink);
        // if the route has a pathname, we set it as a title, otherwise we set the href
        const getLinkTitle =
          urlLink.pathname.length > 1 && decodeURI(urlLink.pathname)
            ? decodeURI(urlLink.pathname).split('/').slice(-1)[0]
            : urlLink.href;
        dom.setAttribute('title', getLinkTitle);

        dom.classList.remove('editor-link');
        dom.classList.add('secondary-button');
      } else dom.setAttribute('target', '_blank');
      return dom;
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.updateDescription) {
      this.handleClose();
    }

    if (this.props.cancelDescription) {
      this.handleCancel();
    }

    if (this.props.editor !== prevProps.editor) {
      // remove previous listener when editor changes
      this.removeNodeListener();
      this.removeEditableListener();
      document.removeEventListener('click', this.handleClickOutsideFunction);

      // not needed when lexical is opened with a separate button (only for add comment, this might change)
      if (!this.props.buttonTriggered) {
        this.removeEditableListener =
          this.props.editor?.registerEditableListener((isEditable) => {
            if (isEditable) {
              this.setEditorFocus(true);
              document.addEventListener(
                'click',
                this.handleClickOutsideFunction,
              );
            } else {
              this.setEditorFocus(false);
              document.removeEventListener(
                'click',
                this.handleClickOutsideFunction,
              );
            }
          }) || (() => false);
      }

      // add target _blank or _self on each link
      this.removeNodeListener =
        this.props.editor?.registerNodeTransform(LinkNode, (node) => {
          if (!node) return;

          node.__target = '_blank';

          if (node.__url.includes('borddo')) {
            node.__target = '_self';
          }
        }) || (() => false);

      // add target _blank when a new link node is created, if the link contains a "borddo" href, make it into a button
      const createDOM = LinkNode.prototype.createDOM;
      LinkNode.prototype.createDOM = function (config: EditorConfig) {
        const dom = createDOM.apply(this, [config]);
        const hrefLink = dom.getAttribute('href');
        if (hrefLink && hrefLink.includes('borddo')) {
          dom.setAttribute('target', '_self');
          const urlLink = new URL(hrefLink);
          // if the route has a pathname, we set it as a title, otherwise we set the href
          const getLinkTitle =
            urlLink.pathname.length > 1 && decodeURI(urlLink.pathname)
              ? decodeURI(urlLink.pathname).split('/').slice(-1)[0]
              : urlLink.href;
          dom.setAttribute('title', getLinkTitle);

          dom.classList.remove('editor-link');
          dom.classList.add('secondary-button');
        } else dom.setAttribute('target', '_blank');
        return dom;
      };
    }

    // used when lexical is opened with a separate button
    if (
      prevProps.showLexical !== this.props.showLexical &&
      this.props.showLexical === true
    ) {
      this.handleOpen();
    }

    document.addEventListener('keyup', this.handleFocus);
  }

  componentWillUnmount(): void {
    document.removeEventListener('click', this.handleClickOutsideFunction);
    document.removeEventListener('keyup', this.handleFocus);

    // remove lexical link node listener when component unmounts
    this.removeNodeListener();
  }

  handleFocus = (e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      if (
        document.activeElement?.closest('.lexical-control') ===
        this.lexicalRef.current
      ) {
        this.props.editor?.setEditable(true);

        this.props.editor?.update(() => {
          const root = $getRoot();

          if (!root.getChildren().length) {
            const paragraphNode = $createParagraphNode();
            const textNode = $createTextNode('');
            paragraphNode.append(textNode);

            root.append(paragraphNode);
            root.selectEnd();
          }
        });
      } else {
        this.props.editor?.setEditable(false);
        if (this.props.setShowUpdatingBtns && this.state.isEditorFocused)
          this.props.setShowUpdatingBtns(true);
      }
    }
  };

  handleMouseDown = (e: MouseEvent) => {
    if (e.button === 2 || e.button === 1) {
      e.preventDefault();
    }
  };

  handleOpen = () => {
    this.props.editor?.setEditable(true);
    this.props.editor?.focus();

    this.props.editor?.update(() => {
      const root = $getRoot();

      if (!root.getChildren().length) {
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode('');
        paragraphNode.append(textNode);

        root.append(paragraphNode);
        root.selectEnd();
      }
    });

    // used when lexical is opened with a separate button
    if (this.props.buttonTriggered) {
      this.setEditorFocus(true);
    }

    setTimeout(() => {
      (
        this.lexicalRef.current?.querySelector(
          '.editor-input',
        ) as HTMLDivElement
      ).focus();
    }, 50);
  };

  handleClose = () => {
    // update card description on editor close | remove focus classes
    // editor is initialized in BoardCardFlyout.tsx state as null, and the setter is in CustomPlugins.tsx
    // we need it to access lexical editor methods
    this.props.editor &&
      this.props.editor.getEditorState().read(() => {
        const markdown = $convertToMarkdownString(
          CUSTOM_TRANSFORMERS,
        ).replaceAll(/\n{2}/gm, '\n');

        if (markdown !== this.props.value) {
          this.props.handleChange(markdown);
        }
        this.props.editor?.setEditable(false);

        // used when lexical is opened with a separate button
        this.props.setShowLexical &&
          this.props.buttonTriggered &&
          this.props.setShowLexical(false); // used when lexical is opened with a separate button
      });
  };

  setEditorFocus = (value: boolean) => {
    this.setState({
      isEditorFocused: value,
    });
    if (this.props.setShowUpdatingBtns) this.props.setShowUpdatingBtns(value);
  };

  handleContentClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;

    if (target.closest('.editor-link')) {
      return;
    }

    if (
      this.props.editor &&
      !this.props.editor.isEditable() &&
      this.lexicalRef.current?.dataset.disabledContentEdit !== 'true'
    ) {
      this.handleOpen();
    }
  };

  handleClickOutside = (
    ev: Event,
    callback: () => void,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const selection = window.getSelection();
    const isTextSelected = selection && selection.toString().length > 0;
    const linkEditor = document.querySelector('.link-editor');
    const target = ev.target as HTMLElement;

    if (
      !isTextSelected &&
      ref &&
      !ref.current?.contains(target) &&
      !linkEditor?.contains(target)
    ) {
      callback();
    }
  };

  handleCancel = () => {
    this.props.editor?.update(() => {
      const markdown = $convertToMarkdownString(CUSTOM_TRANSFORMERS).replaceAll(
        /\n{2}/gm,
        '\n',
      );

      if (markdown !== this.props.value) {
        $convertFromMarkdownString(this.props.value, CUSTOM_TRANSFORMERS);
      }
    });
  };

  render() {
    return (
      <LexicalComposer initialConfig={this.initialEditorConfig}>
        <div
          className={[
            'lexical-control',
            this.state.isEditorFocused ? 'focus-within' : '',
          ].join(' ')}
          ref={this.lexicalRef}
          onClick={this.handleContentClick}
          data-disabled-content-edit={this.props.disabled}
        >
          {/* <ToolbarPlugin /> */}
          <div
            className="editor-inner"
            style={{
              position: 'relative',
            }}
          >
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  style={{
                    marginTop: '-10px',
                    paddingTop: '30px',
                    minHeight: '100px',
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='100'%3E%3Crect width='100%25' height='100%25' fill='none'/%3E%3Ctext x='50%25' y='25%25' font-size='12' font-weight='700' font-family='Arial,Helvetica' fill='white' opacity='.1' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 100, 100)'%3E⚠️LEGACY%3C/text%3E%3C/svg%3E")`,
                  }}
                  className={[
                    'card editor-input opaque',
                    this.state.isEditorFocused ? 'focus' : '',
                    this.props.className,
                  ].join(' ')}
                  spellCheck={this.state.isEditorFocused}
                  disabled={this.props.disabled}
                />
              }
              placeholder={
                <div
                  className="editor-placeholder faint-text"
                  style={{
                    position: 'absolute',
                    top: '31px',
                    left: '17px',
                    height: 0,
                  }}
                >
                  <p>{this.props.placeholder}</p>
                </div>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
              onChange={() => {
                // Handle editor state change here
              }}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <CustomPlugins
              card={this.props.card}
              handleChange={this.props.handleChange}
              setEditor={this.props.setEditor}
              handleClose={this.handleClose}
              value={this.props.value}
              updateTrigger={this.props.updateTrigger}
              prevEditor={this.props.editor}
            />
            <ClearEditorPlugin />
          </div>
        </div>
      </LexicalComposer>
    );
  }
}
