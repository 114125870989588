import React from 'react';
import Button from '../../controls/Button';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import AppContext from '../../../common/contexts/AppContext';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton';
import { TContextMenu } from '../../../common/types/ContextMenu';
import LinkButton from '../../controls/LinkButton';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { RouteComponentProps } from 'react-router-dom';
import { leaveBoard } from '../../../common/api/endpoints/member';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import RequestStatus from '../../partials/RequestStatus/RequestStatus';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface RouteParams {
  routeBoardId: string;
}

interface Props extends RouteComponentProps<RouteParams>, WithTranslation {
  isOwner: boolean;
  fixedPositionParent?: boolean;
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
  routeBoardId: string;
}

interface State {
  leaveStatus: TRequestStatus;
}

class LeaveOperationsMenu extends React.Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      leaveStatus: 'idle',
    };
  }

  leaveBoard = async () => {
    this.setState({ leaveStatus: 'loading' });

    try {
      await leaveBoard(this.props.routeBoardId);

      this.setState({
        leaveStatus: 'success',
      });
      this.props.history.replace(`/`);
    } catch (err) {
      const errors = Array.isArray(err) ? err : [err];
      this.setState({ leaveStatus: 'error' });
      showErrorNotifications(errors, this.context.notifications.setMessages);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        {this.props.isOwner ? (
          <>
            <li>
              <div
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'normal',
                }}
              >
                <small className="column py-0">
                  {t('ownerMessage')}
                  <hr />
                  <LinkButton
                    to="../board-settings/housekeeping"
                    className="secondary-button fill"
                  >
                    <span className="icon fal fa-archive"></span>
                    <span className="text">{t('boardArchival')}</span>
                  </LinkButton>
                </small>
              </div>
            </li>
          </>
        ) : (
          <>
            <li>
              <div
                style={{
                  maxWidth: '200px',
                  whiteSpace: 'normal',
                }}
              >
                <small className="column py-0">
                  {t('leavingBoardMessage')}
                </small>
              </div>
            </li>
            <li>
              <hr />
            </li>
            <li>
              <Button
                className="ghost-button fill"
                disabled={this.state.leaveStatus === 'loading'}
                onClick={this.leaveBoard}
              >
                {this.state.leaveStatus === 'loading' ? (
                  <RequestStatus status={this.state.leaveStatus} />
                ) : (
                  <span className="fas fa-exclamation-circle icon accent-text-red"></span>
                )}
                <span className="text">{t('leaveBoardNow')}</span>
              </Button>
            </li>
          </>
        )}
      </>
    );
  }
}

export default withStyledTranslation('leaveOperationsMenu')(
  LeaveOperationsMenu,
);
