import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageMessage from '../partials/PageMessage/PageMessage';
import AppContext from '../../common/contexts/AppContext';
import { TRequestStatus } from '../../common/types/RequestStatus';
import { ErrorNotificationPayload } from '../../common/helpers/errorNotificationPayload';
import { confirmDeleteSelf } from '../../common/api/endpoints/user';
import Button from '../controls/Button';

interface RouteParams {
  token: string;
}

interface Props extends RouteComponentProps<RouteParams> {
  removeLoggedUser: () => void;
  setSessionExpired: (sessionExpired: boolean) => void;
}

interface State {
  confirmStatus: TRequestStatus;
  message: string;
  title: string;
}

class ConfirmAccountDeletion extends Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmStatus: 'loading',
      message: '',
      title: 'Account Deletion Confirmation',
    };
  }

  componentDidMount(): void {
    this.confirmAccountDelete();
  }

  removeUser = () => {
    this.props.setSessionExpired(true);
    this.props.removeLoggedUser();
    this.props.history.replace('/login');
  };

  handleRedirect = () => {
    this.state.confirmStatus === 'success'
      ? this.removeUser()
      : this.props.history.replace('/');
  };

  confirmAccountDelete = async () => {
    try {
      await confirmDeleteSelf(this.props.match.params.token);
      this.setState({
        message:
          'Account Deleted Successfully! You will be redirected to login page.',
        confirmStatus: 'success',
      });
      setTimeout(() => {
        this.removeUser();
      }, 5000);
    } catch (err) {
      const errors = Array.isArray(err) ? err : [err];
      if (errors[0] instanceof ErrorNotificationPayload) {
        this.setState({
          message: errors[0].validationCode
            ? (errors[0].path !== null ? errors[0].path : '') +
              errors[0].validationCode
            : errors[0].message
              ? errors[0].message
              : '',
          confirmStatus: 'error',
          title: 'Expired Link',
        });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.confirmStatus && (
          <PageMessage>
            <h1 className="primary-title normalcase pb-xs">
              {this.state.title}
            </h1>
            <p>{this.state.message}</p>
            <ul className="control-list-component flex-h-center">
              <li>
                <Button
                  className={'secondary-button'}
                  onClick={this.handleRedirect}
                >
                  <span className="text">
                    {this.state.confirmStatus === 'success'
                      ? 'Login'
                      : 'Homepage'}
                  </span>
                </Button>
              </li>
            </ul>
          </PageMessage>
        )}
      </>
    );
  }
}

export default ConfirmAccountDeletion;
