import React from 'react';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TContextMenu } from '../../../common/types/ContextMenu';
import PriorityManageMenu from '../../menus/Priority/PriorityManageMenu';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import MessageBar from '../../controls/MessageBar';
import { t } from 'i18next';

interface ContextProps {
  priorities: PriorityDTO[];
  paid: boolean;
}
interface ExternalProps {
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
}
interface Props extends ContextProps, ExternalProps {}

class PriorityListUnorderable extends React.Component<Props> {
  render() {
    return (
      <ul
        id="unorderablePriorities"
        className="control-list-component vertical"
      >
        {this.props.priorities.map((priority: PriorityDTO) => {
          return (
            <PriorityManageMenu
              key={priority.id}
              dbPriority={priority}
              setSelectedContext={this.props.setSelectedContext}
              setSelectedPriority={this.props.setSelectedPriority}
            />
          );
        })}
        {this.props.paid == false && (
          <MessageBar
            type="info"
            icon="fas fa-lock"
            extraClasses="mt-xs"
          >
            <div
              className="mb-xs"
              style={{ maxWidth: '150px', whiteSpace: 'normal' }}
            >
              Upgrade to Borddo Pro and unlock up to 8 priorities per board.
            </div>
            <form
              target="_blank"
              method="POST"
              action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
            >
              <button className="secondary-button translucent">
                <span className="text no-wrap">
                  {t('cardActivity:upgradeButton')}
                </span>
              </button>
            </form>
          </MessageBar>
        )}
      </ul>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  PriorityListUnorderable,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      paid: ctx.board.paid,
      priorities: ctx.board.priorities,
    };
  },
);
