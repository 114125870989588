import requests from './baseRequest';

export const retrievePrice = async (priceId: string) => {
  // Mock data
  const mockPrices: any = {
    price_1MoBy5LkdIwHu7ixZhnattbh: {
      id: 'price_1PaEPlG9K8OEhwM32hgNf67R',
      object: 'price',
      active: true,
      currency: 'usd',
      unit_amount: 599,
      product: 'prod_1',
    },
    // Add more mock prices as needed
  };

  // Simulate an API call delay
  await new Promise((resolve) => setTimeout(resolve, 100));

  const price = mockPrices[priceId];
  if (!price) {
    throw new Error('Price not found');
  }
  return price;
};

export interface ICheckoutSession {
  status: string;
  teamId: string | null;
}

export const getCheckoutSession = async (
  id: string,
): Promise<ICheckoutSession> => {
  const result = await requests.get(`payment/checkout/${id}`);
  return await result.json();
};
