import { useState, useEffect, useContext, useCallback } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import AppContext from '../../../common/contexts/AppContext';
import Thumbnail from '../../partials/Thumbnail/Thumbnail';
import ContextMenu from '../../controls/ContextMenu';
import UserMenu from '../../menus/User/UserMenu';
import LegalMenu from '../../menus/Legal/LegalMenu';
import LinkButton from '../../controls/LinkButton';
import HelpDialog from '../Legal/HelpDialog';
import { generateLogo } from '../../../common/helpers/dynamicFavicon';

export interface Props {
  handleLogout: () => void;
  lockedAccount?: boolean;
  color?: string;
  anonymous?: boolean;
}

const Topbar: React.FC<Props> = ({
  handleLogout,
  lockedAccount,
  anonymous = false,
}) => {
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const context = useContext(AppContext);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName.toLowerCase() === 'input' ||
      activeElement?.tagName.toLowerCase() === 'textarea' ||
      activeElement?.hasAttribute('contenteditable');

    if (!isInputField && event.key === '/') {
      event.preventDefault();
      setShowHelpDialog(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return anonymous === true ? (
    <header className="topbar-component hide-print">
      <div className="bar-content">
        <div
          id="toolbarControls"
          className="toolbar-component bar-content-start"
        >
          <a
            href="/"
            className="brand-wrapper"
          >
            <div className={`brand-contain-h`}>
              <img
                src={generateLogo()}
                alt="Logo"
              />
            </div>
          </a>
        </div>
      </div>
    </header>
  ) : (
    <header className="topbar-component hide-print">
      <div className="corner corner-left">
        <div className="corner-inner"></div>
      </div>
      <div className="corner corner-right">
        <div className="corner-inner"></div>
      </div>
      <div className="bar-content">
        {showHelpDialog && (
          <HelpDialog onClose={() => setShowHelpDialog(false)} />
        )}
        <div
          id="toolbarControls"
          className="toolbar-component bar-content-start"
        >
          <LinkButton
            to="/"
            className="brand-wrapper"
          >
            <div className={`brand-contain-h`}>
              <img
                src={generateLogo()}
                alt="Logo"
              />
            </div>
          </LinkButton>
        </div>
        <div className="bar-content-end">
          <nav className="navigation-component horizontal bar-content-start">
            <ul className="nav-list">
              <li className="nav-li">
                <ContextMenu
                  dept={0}
                  title="Legal menu"
                  triggerContent={
                    <>
                      <span className="far fa-question-circle icon"></span>
                    </>
                  }
                  triggerClassDefault="ghost-button"
                  triggerClassActive="secondary-button"
                >
                  <LegalMenu onOpenHelpDialog={() => setShowHelpDialog(true)} />
                </ContextMenu>
              </li>
              <li className="nav-li">
                <Route
                  render={(props: RouteComponentProps) => (
                    <ContextMenu
                      dept={0}
                      triggerContent={
                        <div className="flex-row py-0 px-0 mx-0">
                          <div className="column flex-v-center py-0 px-0 mx-0">
                            <Thumbnail
                              classes="size-24"
                              avatarData={context.loggedUser?.avatar}
                              title={context.loggedUser?.name}
                            />
                            <span
                              className="icon fas fa-angle-down"
                              style={{ height: '0.8em' }}
                            ></span>
                          </div>
                        </div>
                      }
                      triggerClassDefault="ghost-button"
                      triggerClassActive="secondary-button"
                      {...props}
                    >
                      <UserMenu
                        loggedUser={context.loggedUser}
                        handleLogout={handleLogout}
                        lockedAccount={lockedAccount}
                      />
                    </ContextMenu>
                  )}
                />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
