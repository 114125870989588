import React from 'react';
import Button from '../../controls/Button';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { TAG_COLORS } from '../../../common/configs/tag';
import { getColorSymbol } from '../../../common/helpers/getColorSymbol';

interface Props {
  tag: TagDTO;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedTag: (tag: TagDTO) => void;
}

export default class TagManageMenu extends React.Component<Props> {
  handleClick = () => {
    this.props.setSelectedTag && this.props.setSelectedTag(this.props.tag);
    this.props.setSelectedContext && this.props.setSelectedContext('editTag');
  };

  render() {
    const symbol = getColorSymbol(TAG_COLORS, this.props.tag.color);
    return (
      <li className="fill">
        <Button
          className="ghost-button fill flex-h-spread"
          onClick={this.handleClick}
        >
          <span className={`icon accent-text-${this.props.tag.color}`}>
            <span className={`cb-only icon mr-0 ${symbol} `}></span>
            <span className={`cb-none icon fas fa-tag`}></span>
          </span>
          <span className="text">{this.props.tag.name}</span>
          <span className="fas icon fa-pencil"></span>
        </Button>
      </li>
    );
  }
}
