import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../../../controls/Button';
import { t } from 'i18next';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import {
  createCheckoutSession,
  CreateCheckoutSessionResponse,
} from '../../../../common/api/endpoints/checkout';
import Stepper from '../../../controls/Stepper';
import ElementScrollbar from '../../../partials/Scrollbars/ElementScrollbar';
import MessageBar from '../../../controls/MessageBar';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { getCheckoutSession } from '../../../../common/api/endpoints/stripe';
import { loadStripe, Stripe } from '@stripe/stripe-js';

interface Props {
  uxStep: number;
  peopleCount: number;
  onNext: () => void;
  onPrev: () => void;
}

interface CheckoutFormProps {
  name: string;
  seats: number;
  mode: string;
  priceId: string;
  handlePaymentComplete: () => void;
  stripePromise: Promise<Stripe | null> | null;
  setCheckoutSession: (data: CreateCheckoutSessionResponse | null) => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  name,
  seats,
  mode,
  priceId,
  handlePaymentComplete,
  stripePromise,
  setCheckoutSession,
}) => {
  const fetchClientSecret = useCallback(() => {
    const payload = {
      name,
      seats,
      trial: name === 'Trial',
    };

    return createCheckoutSession(payload).then((data) => {
      setCheckoutSession(data);

      return data.clientSecret;
    });
  }, [name, seats, mode, priceId, setCheckoutSession]);

  const options = {
    fetchClientSecret,
    onComplete: () => handlePaymentComplete(),
  };

  return (
    <div
      id="checkout"
      style={{
        margin: '-20px',
        borderRadius: '8px',
        overflow: 'hidden',
        maxHeight: '580px',
        overflowY: 'scroll',
      }}
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <ElementScrollbar absolute={true} />
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const StepStripe: React.FC<Props> = ({
  uxStep,
  peopleCount,
  onNext,
  onPrev,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined,
  );
  const [innerStep, setInnerStep] = useState('stepChoice');
  const [backendStatus, setBackendStatus] = useState<TRequestStatus>('idle');
  const [checkoutSession, setCheckoutSession] =
    useState<CreateCheckoutSessionResponse | null>(null);
  const [stripePromise] = useState<Promise<Stripe | null> | null>(
    loadStripe(process.env.REACT_APP_STRIPE_PK || ''),
  );
  const [teamId, setTeamId] = useState<string | null>(null);
  const checkoutSessionRef = useRef<CreateCheckoutSessionResponse | null>(null);

  useEffect(() => {
    if (checkoutSession) {
      checkoutSessionRef.current = checkoutSession;
    }
  }, [checkoutSession]);

  const handleCheckoutStep = (ev: React.MouseEvent<HTMLInputElement>) => {
    setSelectedPlan(ev.currentTarget.value);
    setInnerStep('stepCheckout');
  };

  const handlePaymentComplete = () => {
    setBackendStatus('loading');
    setInnerStep('stepResolution');
    getTeamId(0, 10);
  };

  const renderCheckoutForm = () => {
    if (selectedPlan === 'Trial') {
      return (
        <CheckoutForm
          key="Trial" // Add key to force re-render
          name="Trial"
          seats={peopleCount}
          stripePromise={stripePromise}
          mode="subscription"
          handlePaymentComplete={handlePaymentComplete}
          priceId="price_1OZYqaG9K8OEhwM3W67bg9k6"
          setCheckoutSession={setCheckoutSession}
        />
      );
    } else if (selectedPlan === 'Paid Team') {
      return (
        <CheckoutForm
          key="Paid Team" // Add key to force re-render
          name="Paid Team"
          seats={peopleCount}
          mode="payment"
          stripePromise={stripePromise}
          priceId="price_1OZqFnG9K8OEhwM3hwQ3FxsT"
          handlePaymentComplete={handlePaymentComplete}
          setCheckoutSession={setCheckoutSession}
        />
      );
    }
    return null;
  };

  const getTeamId = async (retry: number, maxRetries: number) => {
    setBackendStatus('loading');
    if (retry > maxRetries) {
      setBackendStatus('error');
      return false;
    }

    if (!checkoutSessionRef.current) {
      setTimeout(() => {
        getTeamId(retry + 1, maxRetries);
      }, 1000);
      return false;
    }
    const teamId = (await getCheckoutSession(checkoutSessionRef.current.id))
      .teamId;
    setTeamId(teamId);

    if (teamId) {
      setBackendStatus('success');
    } else {
      setTimeout(() => {
        getTeamId(retry + 1, maxRetries);
      }, 1000);
    }
  };

  const renderSuccess = () => {
    if (backendStatus === 'success') {
      return (
        <MessageBar
          type="success"
          icon="fal fa-check-circle"
        >
          <p>
            Your team has been successfully created. You can now start using
            your new team.
          </p>
        </MessageBar>
      );
    } else if (backendStatus === 'loading') {
      return (
        <MessageBar
          type="info"
          icon="fal fa-spinner-third fa-spin"
        >
          <p>Creating your team...</p>
        </MessageBar>
      );
    } else if (backendStatus === 'error') {
      return (
        <MessageBar
          type="danger"
          icon="fal fa-exclamation-circle"
        >
          <p>
            There was an error creating your team. Please wait a while and
            refresh the page manually or contact support and communicate your
            order number.
          </p>
        </MessageBar>
      );
    }
    return;
  };

  return (
    <>
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-credit-card"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div className="fill">
            <div className="flex-v-center">
              <Button
                className="ghost-button mr-xs"
                disabled={innerStep === 'stepResolution'}
                onClick={() => {
                  innerStep == 'stepCheckout'
                    ? setInnerStep('stepChoice')
                    : onPrev();
                }}
              >
                <span className="fas fa-arrow-left"></span>
                <span className="sr-only">{t('stepStripe:back')}</span>
              </Button>
              <h2 className="mb-0">
                {t('stepStripe:stepStripe.title', { count: uxStep })}
              </h2>
            </div>
            <Stepper
              steps={{
                stepChoice: (
                  <>
                    {peopleCount <= 5 && (
                      <div className="radio-container">
                        <input
                          type="radio"
                          id="plan-trial"
                          name="plan"
                          value="Trial"
                          defaultChecked={false}
                          onClick={handleCheckoutStep}
                        />
                        <label htmlFor="plan-trial">
                          <div className="flex-row no-reflow">
                            <div className="column icon-column smallest">
                              <span className="text-2xl fas fa-gift"></span>
                            </div>
                            <div className="column text-column largest flex-v-center">
                              {t('stepStripe:trialPlan')}
                            </div>
                          </div>
                        </label>
                      </div>
                    )}
                    <div className="radio-container mb-0">
                      <input
                        type="radio"
                        id="plan-paid"
                        name="plan"
                        value="Paid Team"
                        defaultChecked={false}
                        onClick={handleCheckoutStep}
                      />
                      <label htmlFor="plan-paid">
                        <div className="flex-row no-reflow">
                          <div className="column icon-column smallest">
                            <span className="text-2xl fas fa-briefcase"></span>
                          </div>
                          <div className="column text-column largest flex-v-center">
                            {t('stepStripe:paidPlan')}
                          </div>
                        </div>
                      </label>
                    </div>
                  </>
                ),
                stepCheckout: (
                  <>
                    {selectedPlan && (
                      <div className="reveal-up-1 mt-0">
                        {renderCheckoutForm()}
                      </div>
                    )}
                  </>
                ),
                stepResolution: <>{renderSuccess()}</>,
              }}
              currentStep={innerStep}
            />
            {innerStep === 'stepResolution' && teamId != null && (
              <Button
                className="primary-button"
                disabled={innerStep !== 'stepResolution'}
                onClick={onNext}
              >
                <span className="text">{t('stepStripe:next')}</span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StepStripe;
