import React, { Component } from 'react';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '@wavemyth/react-beautiful-dnd';

interface Props {
  provided?: DraggableProvided;
  innerRef?: (element: HTMLElement | null) => void;
  snapshot?: DraggableStateSnapshot;
  tabIndex?: number;
}

class BoardListingDraggable extends Component<Props> {
  render() {
    return (
      <div
        ref={this.props.innerRef}
        {...this.props.provided?.draggableProps}
        {...this.props.provided?.dragHandleProps}
        className="flex-row fill"
        tabIndex={this.props.tabIndex}
      >
        {this.props.children}
      </div>
    );
  }
}

export default BoardListingDraggable;
