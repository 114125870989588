import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import './i18n';
import App from './App';
import RootScrollbar from './components/partials/Scrollbars/RootScrollbar';

const CONTAINER = document.getElementById('root');

if (CONTAINER) {
  const ROOT = createRoot(CONTAINER);

  ROOT.render(
    <>
      <RootScrollbar offsetY={54} />
      <BrowserRouter>
        <Route
          path="/"
          component={App}
        />
      </BrowserRouter>
    </>,
  );
}
