import React, { Component } from 'react';
import { Trans, WithTranslation } from 'react-i18next';
import { TeamDTO } from '../../../common/api/dtos/Team';
import dayjsHelper from '../../../common/helpers/dayjsHelper';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import SubscriptionMessage from '../../pages/Settings/Billing/SubscriptionMessage';
import Button from '../../controls/Button';
import { NavLink } from 'react-router-dom';

interface Props extends WithTranslation {
  data: TeamDTO | null;
  status: string;
  canceled?: Date | null;
}

class TeamPlanRow extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderBadge = () => {
    const { t, data, canceled } = this.props;
    const trialEndsAt = data?.subscription.trialEndsAt;
    const trialDaysLeft = trialEndsAt
      ? dayjsHelper(trialEndsAt).diff(dayjsHelper(), 'day')
      : 0;

    return (
      <ul className="control-list-component">
        {data?.subscription.paymentState !== 'failed' && (
          <li>
            <span className="badge-text accent-blue">
              <span className="text-sm">{t('teamPlans.teamBadge')}</span>
            </span>
          </li>
        )}
        <li>
          {data?.subscription.deleteAt ? (
            <span className="flag-text accent-red">
              <span className="text-sm">{t('teamPlans.expiredBadge')}</span>
            </span>
          ) : canceled || data?.subscription.cancelAt ? (
            <span className="flag-text accent-yellow">
              <span className="text-sm">{t('teamPlans.cancelledBadge')}</span>
            </span>
          ) : trialEndsAt && trialDaysLeft > 0 ? (
            <span className="flag-text accent-yellow">
              <span className="text-sm">{t('teamPlans.trialBadge')}</span>
            </span>
          ) : data?.subscription.paymentState === 'failed' ? (
            <span className="flag-text accent-red">
              <span className="text-sm">
                {t('teamPlans.paymentIssueBadge')}
              </span>
            </span>
          ) : (
            <span className="flag-text accent-green">
              <span className="text-sm">{t('teamPlans.activeBadge')}</span>
            </span>
          )}
        </li>
      </ul>
    );
  };

  render() {
    const { t } = this.props;
    const { data, canceled } = this.props;

    const trialEndsAt = data?.subscription.trialEndsAt;
    const hasPaymentMethods = data?.subscription.hasPaymentMethods;
    const trialDaysLeft = trialEndsAt
      ? dayjsHelper(trialEndsAt).diff(dayjsHelper(), 'day')
      : 0;

    return (
      <ul className="control-list-component vertical">
        <li>
          <span>
            <Trans
              i18nKey="subscriptions:teamPlans.planStatusLabel"
              values={{
                teamName: data?.name || '',
              }}
              components={{ strong: <strong /> }}
            />
          </span>
        </li>
        <li>{this.renderBadge()}</li>
        {data?.subscription.paymentState === 'failed' && (
          <SubscriptionMessage badge={'warning'}>
            <Trans
              i18nKey="subscriptions:teamPlans.paymentIssueMessage"
              values={{
                endDate: dayjsHelper(
                  data.subscription.paymentRetryUntil,
                ).format('MMMM Do, YYYY'),
              }}
              components={{
                NavLink: (
                  <NavLink
                    className="link-button"
                    to={`/team/${this.props.data?.id}/plan-and-billing`}
                  />
                ),
                span: <span className="text" />,
              }}
            />
          </SubscriptionMessage>
        )}
        {trialEndsAt && trialDaysLeft >= 0 && (
          <SubscriptionMessage
            badge={trialDaysLeft === 0 ? 'danger' : 'warning'}
          >
            <Trans
              i18nKey="subscriptions:teamPlans.trialEnds"
              count={trialDaysLeft}
              values={{
                teamName: data?.name || '',
                daysLeft: trialDaysLeft,
              }}
              components={{ strong: <strong /> }}
            />
          </SubscriptionMessage>
        )}
        {data?.subscription.deleteAt ? (
          <>
            <li>
              <SubscriptionMessage badge="danger">
                <Trans
                  i18nKey="subscriptions:teamPlans.expirationWarning"
                  values={{
                    deleteDate: dayjsHelper(data?.subscription.deleteAt).format(
                      'MMMM Do, YYYY',
                    ),
                  }}
                  components={{ strong: <strong /> }}
                />
              </SubscriptionMessage>
            </li>
            <li className="pt-xs">
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/checkout`}
              >
                <Button
                  className="secondary-button"
                  disabled={!data?.owned}
                  type="submit"
                >
                  <span className="text">
                    {t('teamPlans.reactivateButton')}
                  </span>
                </Button>
              </form>
            </li>
          </>
        ) : canceled || data?.subscription.cancelAt ? (
          <>
            <li>
              <SubscriptionMessage badge="warning">
                <Trans
                  i18nKey="subscriptions:teamPlans.cancellationNotice"
                  values={{
                    expirationDate: dayjsHelper(
                      data?.subscription.cancelAt,
                    ).format('MMMM Do, YYYY'),
                  }}
                  components={{ strong: <strong /> }}
                />
              </SubscriptionMessage>
            </li>
            <li>
              <form
                target="_blank"
                method="POST"
                action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/changeBilling`}
              >
                <Button
                  className="secondary-button"
                  disabled={!data?.owned}
                  type="submit"
                >
                  <span className="text">
                    {t('teamPlans.manageSubscriptionButton')}
                  </span>
                </Button>
              </form>
            </li>
          </>
        ) : this.props.data?.subscription.paymentState !== 'failed' ? (
          trialEndsAt && !hasPaymentMethods && trialDaysLeft > 0 ? (
            <>
              <li>
                <form
                  target="_blank"
                  method="POST"
                  action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/changeBilling`}
                >
                  <Button
                    className="secondary-button"
                    disabled={!data?.owned}
                    type="submit"
                  >
                    <span className="text">
                      {t('teamPlans.addPaymentMethodButton')}
                    </span>
                  </Button>
                </form>
              </li>
            </>
          ) : (
            <>
              <li>
                <form
                  target="_blank"
                  method="POST"
                  action={`${process.env.REACT_APP_API_BASE_URL}/team/${data?.id}/changeBilling`}
                >
                  <Button
                    className="secondary-button"
                    disabled={!data?.owned}
                    type="submit"
                  >
                    <span className="text">
                      {t('teamPlans.manageSubscriptionButton')}
                    </span>
                  </Button>
                </form>
              </li>
            </>
          )
        ) : null}
      </ul>
    );
  }
}

export default withStyledTranslation('subscriptions')(TeamPlanRow);
