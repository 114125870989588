import React from 'react';
import { TeamDTO } from '../../../../common/api/dtos/Team';
import { useTranslation } from 'react-i18next';
import ContextMenu from '../../../controls/ContextMenu';
import { NavLink } from 'react-router-dom';
import Button from '../../../controls/Button';
import { unite } from '../../../../common/helpers/unite';
import { BoardItemDTO } from '../../../../common/api/dtos/Board';
import CreateBoardButton from './CreateBoardButton';

interface UtilityTeamBarProps {
  boards: BoardItemDTO[];
  team: TeamDTO;
  showArchive: boolean;
  toggleArchiveView: (teamId: string) => void;
  hideShelf: (teamId: string) => void;
}

const UtilityTeamBar: React.FC<UtilityTeamBarProps> = ({
  team,
  showArchive,
  toggleArchiveView,
  hideShelf,
  boards,
}) => {
  const { t } = useTranslation('home');

  return (
    <div className="column py-2xs pr-2xs">
      <ul className="control-list-component flex-h-end no-wrap">
        {boards.length !== 0 && (
          <li>
            <CreateBoardButton
              disabled={
                (team.role !== 'admin' && team.role !== 'owner') ||
                team.subscription.deleteAt !== null
              }
              team={team}
              shrinkOnMobile
            />
          </li>
        )}
        <li>
          <ContextMenu
            dept={0}
            title={t('teamOptions')}
            triggerContent={<span className="icon fas fa-ellipsis-h"></span>}
            triggerClassDefault="ghost-button"
            triggerClassActive="secondary-button"
          >
            {(team.role === 'admin' || team.role === 'owner') && (
              <>
                <li className={unite(showArchive ? 'active' : '')}>
                  <Button
                    className="ghost-button"
                    onClick={() => toggleArchiveView(team.id)}
                  >
                    <span className="fal fa-archive icon"></span>
                    <span className="text">{t('archivedBoards')}</span>
                  </Button>
                </li>
                <li>
                  <hr />
                </li>
                <li>
                  <NavLink
                    to={`/team/${team.id}/general`}
                    className="ghost-button"
                  >
                    <span className="fal fa-cog icon"></span>
                    <span className="text">{t('settings')}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/team/${team.id}/team-members`}
                    className="ghost-button"
                  >
                    <span className="fal fa-users icon"></span>
                    <span className="text">{t('members')}</span>
                    <span className="ml-xs badge-text">
                      <span className="text-xs text-700">
                        {team.memberCount}
                      </span>
                    </span>
                  </NavLink>
                </li>
              </>
            )}
            {team.role !== 'owner' && (
              <>
                <li>
                  <NavLink
                    to={`/account/membership`}
                    className="ghost-button"
                  >
                    <span className="fal fa-id-badge icon"></span>
                    <span className="text">{t('membership')}</span>
                  </NavLink>
                </li>
              </>
            )}
            <hr />
            <li>
              <Button
                className="ghost-button"
                onClick={() => hideShelf(team.id)}
              >
                <span className="fal fa-eye-slash icon"></span>
                <span className="text">{t('hideShelf')}</span>
              </Button>
            </li>
          </ContextMenu>
        </li>
      </ul>
    </div>
  );
};

export default UtilityTeamBar;
