export const VALID_PASSWORD_REGEX =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~])[A-Za-z\d/ !"#$%&'()*+,-.\\:;<=>?@[\]^_`{|}~]{8,}/;
export const SESSION_VALIDATE_INTERVAL_MS = 60 * 1000;
export const ANONYMOUS_ROUTES = [
  '/onboarding',
  '/registration',
  '/forgot-password',
  '/reset-password',
  '/resend-confirmation',
];
