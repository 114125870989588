import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  ghost?: boolean;
}

export default class ContentLoader extends Component<Props> {
  render() {
    return (
      <div
        className="placeholder card"
        style={{
          height: this.props.height,
          width: this.props.width,
          marginBottom: `${this.props.ghost ? `-${this.props.height}` : ''}`,
        }}
      >
        <div className="background"></div>
      </div>
    );
  }
}
