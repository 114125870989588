import { History, Location } from 'history';
import Joi from 'joi';
import { ChangeEvent, Component, FormEvent } from 'react';
import { LoginUserDTO } from '../../../common/api/dtos/User';
import { login } from '../../../common/api/endpoints/user';
import AppContext from '../../../common/contexts/AppContext';
import { FormErrorMsgs } from '../../../common/configs/FormErrors';
import { ErrorNotificationPayload } from '../../../common/helpers/errorNotificationPayload';
import { processJoiError } from '../../../common/helpers/processJoiError';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Button from '../../controls/Button';
import TextBox from '../../controls/TextBox';
import { customEmailValidation } from '../../pages/Auth/Registration/helper/customEmailValidation';
import { t } from 'i18next';
import errorKeyFormatter from '../../../common/helpers/errorKeyFormatter';

interface State {
  formStatus: TRequestStatus;
  errorMessage: string;
  formData: FormData;
  formErrors: FormErrors;
  serverErrors: string[];
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface FormData {
  email: string;
  password: string;
}

export interface Props {
  unlockApp: () => void;
  history: History;
  location: Location;
}

export default class LoginForm extends Component<Props, State> {
  formSchema = Joi.object({
    email: Joi.string()
      .required()
      .trim(true)
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .custom(customEmailValidation)
      .messages(FormErrorMsgs.string),
    password: Joi.string().required().messages(FormErrorMsgs.string),
  });
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      formStatus: 'idle',
      errorMessage: '',
      formData: {
        email: '',
        password: '',
      },
      formErrors: {},
      serverErrors: [],
    };
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const formData = this.state.formData;

    this.setState({
      formData: {
        ...formData,
        [event.target.name]: event.target.value,
      },
    });
  };

  validateFormField = <K extends keyof FormData>(field: K) => {
    const subSchema = this.formSchema.extract(field);

    const result = subSchema.validate(this.state.formData[field], {
      abortEarly: false,
    });

    if (result.error) {
      this.updateFormError(field, result.error.message);
    } else {
      this.updateFormError(field, '');
    }
  };

  validateForm = () => {
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });

    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors as FormErrors,
      });

      return false;
    }

    return true;
  };

  updateFormError<K extends keyof FormErrors>(field: K, value: FormErrors[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        formStatus: 'loading',
        serverErrors: [],
      });

      const body: LoginUserDTO = {
        email: this.state.formData.email,
        password: this.state.formData.password,
      };

      try {
        const loginData = await login(body);
        const sessionExpirationMs = Date.now() + loginData.expiresIn;

        localStorage.setItem(
          'session_expiration_ms',
          String(sessionExpirationMs),
        );
      } catch (err) {
        const serverErrors = this.state.serverErrors.slice();
        serverErrors.push(
          errorKeyFormatter((err as ErrorNotificationPayload[])[0]),
        );
        this.setState({
          formStatus: 'error',
          serverErrors,
        });
      }

      this.props.unlockApp();
    }
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
      >
        <TextBox
          label="Email"
          type="email"
          name="email"
          id="email"
          disabled={this.state.formStatus === 'loading'}
          onChange={this.handleInput}
          required={true}
          br={true}
          onBlur={(ev) =>
            this.validateFormField(ev.target.name as keyof FormData)
          }
          error={this.state.formErrors.email}
        />
        <TextBox
          label="Password"
          type="password"
          name="password"
          id="password"
          disabled={this.state.formStatus === 'loading'}
          onChange={this.handleInput}
          required={true}
          br={true}
          onBlur={(ev) =>
            this.validateFormField(ev.target.name as keyof FormData)
          }
          error={this.state.formErrors.password}
        />

        <Button
          type="submit"
          className="primary-button"
          disabled={this.state.formStatus === 'loading'}
          status={this.state.formStatus}
        >
          <span className="text">Log in</span>
        </Button>
        {this.state.serverErrors.length > 0 && (
          <ul
            role="alert"
            aria-live="assertive"
            className="error-list light flex-h-center"
          >
            {this.state.serverErrors.map((err: string) => {
              return (
                <li
                  key={err}
                  className="flex-v-center text-center"
                >
                  <span className="text">{t(err)}</span>
                </li>
              );
            })}
          </ul>
        )}
      </form>
    );
  }
}
