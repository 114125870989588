import { generateLogo } from './dynamicFavicon';

export function getBoardColor(id: string) {
  return localStorage.getItem(`board-${id}-color`);
}

export function applyBranding(id: string) {
  const color = String(getBoardColor(id));
  const favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  const logo: HTMLImageElement | null = document.querySelector(
    '.brand-contain-h img',
  );
  const generatedLogo = generateLogo(color);
  if (logo) {
    logo.src = generatedLogo;
  }
  if (favicon?.href) {
    favicon.href = generatedLogo;
  }
}

export function resetBranding() {
  const color = '';
  const favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  const logo: HTMLImageElement | null = document.querySelector(
    '.brand-contain-h img',
  );
  const generatedLogo = generateLogo(color);
  if (logo) {
    logo.src = generatedLogo;
  }
  if (favicon?.href) {
    favicon.href = generatedLogo;
  }
}

export function setLocalStorageBoardColor(id: string, color: string) {
  localStorage.setItem(`board-${id}-color`, color);
  applyBranding(id);
}
