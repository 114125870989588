import React, { ChangeEvent, Component } from 'react';
import AppContext from '../../../../common/contexts/AppContext';
import ToggleSwitch from '../../../controls/ToggleSwitch';
import { WithTranslation } from 'react-i18next';
import MessageBar from '../../../controls/MessageBar';
import { EXPERIMENTS } from '../../../../common/configs/Experiments';

type Props = WithTranslation & {
  history: any;
};

class Experiments extends Component<Props, { experiments: any }> {
  context!: React.ContextType<typeof AppContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      experiments: {},
    };
  }

  componentDidMount() {
    const loggedUserId = this.context.loggedUser?.id;
    if (loggedUserId) {
      const storedExperiments = this.getStoredExperiments();
      this.context.setExperimentFlags?.({
        experiments: storedExperiments,
      });
      this.setState({ experiments: storedExperiments });
    }
  }

  setExperiment =
    (experiment: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const loggedUserId = this.context.loggedUser?.id;
      if (!loggedUserId) return;

      const experiments = this.context.experimentFlags || {};
      if (experiments[experiment] !== event.target.checked) {
        experiments[experiment] = event.target.checked;
        const clientFlags = JSON.parse(
          localStorage.getItem('clientFlags') || '{}',
        );
        clientFlags[loggedUserId] = experiments;
        localStorage.setItem('clientFlags', JSON.stringify(clientFlags));
        this.context.setExperimentFlags?.({ experiments });
        this.setState({ experiments });
      }
    };

  getStoredExperiments = () => {
    const loggedUserId = this.context.loggedUser?.id;
    if (!loggedUserId) return {};

    const clientFlags = JSON.parse(localStorage.getItem('clientFlags') || '{}');
    return clientFlags[loggedUserId] || {};
  };

  get experimentFlags() {
    return this.context.experimentFlags || {};
  }

  set experimentFlags(experiments) {
    this.context.setExperimentFlags?.({ experiments });
  }

  render() {
    const experiments = this.state.experiments;

    return (
      <>
        <div className="flex-row">
          <div className="column pb-xs">
            <h1 className="primary-title h3 normalcase">Application Flags</h1>
          </div>
        </div>
        <div className="flex-row fill">
          <div className="column pt-0 pb-2xs">
            <div style={{ maxWidth: '1000px' }}>
              <MessageBar
                type="warning"
                icon="fal fa-exclamation-triangle"
              >
                <p>
                  <strong>Warning</strong> - These are experimental features
                  that may not work as expected or could break your client
                  application and may require troubleshooting. Use with caution.
                </p>
              </MessageBar>
            </div>
          </div>
        </div>
        {EXPERIMENTS.map((experiment) => (
          <div
            className="flex-row fill"
            key={experiment.id}
          >
            <div className="column py-0">
              <ToggleSwitch
                id={experiment.id}
                name={experiment.id}
                changeMethod={this.setExperiment(experiment.id)}
                isChecked={experiments[experiment.id]}
                multiline={true}
                tight={true}
              >
                <label htmlFor={experiment.id}>
                  <div className="flex-row no-reflow squeeze flex-v-center">
                    <div className="column py-0">
                      <span
                        className={`link-text ${experiment.icon} icon`}
                      ></span>
                    </div>
                    <div className="column py-0">
                      <span>{experiment.name}</span> <br />
                      <small className="faint-text">
                        {experiment.description}
                      </small>
                    </div>
                    <span className="state">
                      {experiments[experiment.id] ? 'On' : 'Off'}
                    </span>
                  </div>
                </label>
              </ToggleSwitch>
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default Experiments;
Experiments.contextType = AppContext;
