import requests from './baseRequest';

interface CheckoutSessionParams {
  name: string;
  seats: number;
  trial: boolean;
}

export interface CreateCheckoutSessionResponse {
  clientSecret: string;
  id: string;
}

export async function createCheckoutSession(
  params: CheckoutSessionParams,
): Promise<CreateCheckoutSessionResponse> {
  const result = await requests.post('payment/checkout/team', params);
  return await result.json();
}
