import React from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import Button from '../../controls/Button';
import TagsListUnorderable from '../../partials/tag/TagsListUnorderable';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedTag: (tag: TagDTO) => void;
}

interface State {
  status: TRequestStatus;
}
class TagOperationsMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'idle',
    };
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <div className="fill">
            <div className="flex-row fill">
              <div className="column py-0">
                <Button
                  className="secondary-button fill"
                  onClick={() =>
                    this.props.setSelectedContext &&
                    this.props.setSelectedContext('addTag')
                  }
                >
                  {t('addTag')}
                </Button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="fill">
            <div className="flex-row fill">
              <div className="column pt-0 pb-0">
                <TagsListUnorderable
                  setSelectedContext={this.props.setSelectedContext}
                  setSelectedTag={this.props.setSelectedTag}
                />
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('tagOperationsMenu')(TagOperationsMenu);
