import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const supportedLanguages = [
  'en_US',
  'ro_RO',
  'fr_FR',
  'en_UK',
  'it_IT',
  'de_DE',
  'es_ES',
];
const namespaces = [
  'account',
  'accountGeneral',
  'applicationSettings',
  'addCardComment',
  'addCardDescription',
  'app',
  'avatarGenerator',
  'boardCardFlyout',
  'boardArchivedCardFlyout',
  'boardCardFlyoutTemplate',
  'boardColumnExpanded',
  'boardColumnPlaceholder',
  'boardListingCard',
  'boardOperationsMenu',
  'boardTopbar',
  'cardActivity',
  'columnContext',
  'columnMenu',
  'fileInput',
  'generalInformationTab',
  'home',
  'leaveOperationsMenu',
  'legalFooter',
  'legalMenu',
  'helpDialog',
  'welcomeDialog',
  'login',
  'memberMenu',
  'memberSearchMenu',
  'membership',
  'newMenu',
  'passwordForm',
  'priorityContext',
  'priorityDetailMenu',
  'boardArchive',
  'priorityForm',
  'priorityMenu',
  'priorityOperationsMenu',
  'registration',
  'removeMemberMenu',
  'removeTagMenu',
  'subscriptions',
  'tagContext',
  'tagDetailMenu',
  'tagForm',
  'tagOperationsMenu',
  'userMenu',
  'boardCardPlaceholder',
  'userComment',
  'boardCardMembers',
  'columnArchive',
  'columnDelete',
  'columnLimit',
  'boardColumnCollapsed',
  'cardFilterMenu',
  'boardCardDescription',
  'cardDataContext',
  'cardDataMenu',
  'memberManageMenu',
  'imageViewer',
  'imageZoomer',
  'archivedCardsPage',
  'cardSubtask',
  'boardSettings',
  'appearanceTab',
  'teamSettings',
  'teamGeneral',
  'teamMembers',
  'teamPlan',
  'teamPlanRow',
  'boardCardArchived',
  'manageTeamMember',
  'teamMembership',
  'teamHousekeeping',
  'houseKeepingTab',
  'stepIntro',
  'stepStripe',
  'stepSpace',
  'stepBoard',
  'stepColumns',
  'stepTasks',
  'stepMembers',
  'stepSummary',
];

const backendOptions = {
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

const interpolationOptions = {
  escapeValue: false,
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init(
    {
      supportedLngs: supportedLanguages,
      lng: 'en_US',
      ns: namespaces,
      defaultNS: 'app',
      backend: backendOptions,
      interpolation: interpolationOptions,
    },
    () => false, // we are not using backend for now, we load them all at once
  );

export default i18n;
