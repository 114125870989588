import React from 'react';
import {
  DraggableProvided,
  DraggableStateSnapshot,
} from '@wavemyth/react-beautiful-dnd';

interface Props {
  className?: string;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
}

export default class BoardColumnHeader extends React.Component<Props> {
  render() {
    return (
      <div
        className="flex-row list-drag-head no-reflow"
        {...this.props.provided?.dragHandleProps}
      >
        {this.props.children}
      </div>
    );
  }
}
