import React, { Component, createRef, ReactNode } from 'react';

interface FluidHeightProps {
  children: ReactNode;
}

export default class FluidHeight extends Component<FluidHeightProps> {
  containerRef = createRef<HTMLDivElement>();
  resizeObserver: ResizeObserver | null = null;
  updateHeightTimeout: NodeJS.Timeout | null = null;

  componentDidMount() {
    if (this.containerRef.current) {
      this.resizeObserver = new ResizeObserver(() => {
        this.debouncedUpdateHeight();
      });
      this.resizeObserver.observe(this.containerRef.current);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver && this.containerRef.current) {
      this.resizeObserver.unobserve(this.containerRef.current);
    }
    if (this.updateHeightTimeout) {
      clearTimeout(this.updateHeightTimeout);
    }
  }

  componentDidUpdate() {
    this.updateHeight();
  }

  debouncedUpdateHeight() {
    if (this.updateHeightTimeout) {
      clearTimeout(this.updateHeightTimeout);
    }
    this.updateHeightTimeout = setTimeout(() => {
      this.updateHeight();
    }, 100); // Adjust the debounce delay as needed
  }

  updateHeight() {
    if (this.containerRef.current) {
      const container = this.containerRef.current;
      container.style.transition =
        'height 0.2s cubic-bezier(0.25, 0.8, 0.25, 1)'; // Adjusted transition property
      const currentHeight = container.offsetHeight; // Get current height
      container.style.height = 'auto'; // Temporarily set height to auto to measure full height
      const newHeight = container.scrollHeight; // Get full height from container element
      container.style.height = currentHeight + 'px'; // Revert to original height
      container.style.overflow = 'hidden'; // Add overflow hidden during animation
      requestAnimationFrame(() => {
        container.style.height = newHeight + 'px'; // Apply smooth transition to new height
        container.addEventListener('transitionend', this.handleTransitionEnd);
      });
    }
  }

  handleTransitionEnd = () => {
    if (this.containerRef.current) {
      this.containerRef.current.style.overflow = ''; // Remove overflow hidden after animation
      this.containerRef.current.removeEventListener(
        'transitionend',
        this.handleTransitionEnd,
      );
    }
  };

  render() {
    return (
      <div
        ref={this.containerRef}
        style={{
          overflow: 'hidden',
          position: 'relative',
          height: 'auto',
          willChange: 'height',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
