import React, { Component } from 'react';
import MessageBar from '../../../controls/MessageBar';

interface Props {
  badge: 'info' | 'warning' | 'danger';
  children: React.ReactNode;
}

class SubscriptionMessage extends Component<Props> {
  render() {
    const { badge, children } = this.props;
    const icon = badge === 'info' ? 'fa-info-circle' : 'fa-exclamation-circle';
    const type = badge;

    return (
      <MessageBar
        type={type}
        icon={icon}
      >
        {children}
      </MessageBar>
    );
  }
}

export default SubscriptionMessage;
