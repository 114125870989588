import React from 'react';
import Button from '../../controls/Button';
import ToggleSwitch from '../../controls/ToggleSwitch';
import PrioritiesListOrderable from '../../partials/priority/PriorityListOrdarable';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { reorderPriority } from '../../../common/api/endpoints/priority';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton';
import { TContextMenu } from '../../../common/types/ContextMenu';
import PriorityListUnorderable from '../../partials/priority/PrioritiesListUnorderable';
import { withContextAdapter } from '../../partials/ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface ContextProps {
  priorities: PriorityDTO[];
  reloadBoard: () => void;
  movePriority: (priorityId: string, index: number) => void;
}
interface ExternalProps {
  fixedPositionParent?: boolean;
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
}
interface Props extends ContextProps, ExternalProps, WithTranslation {}

interface State {
  reorder: boolean;
  priorities: PriorityDTO[];
}

class PriorityOperationsMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reorder: false,
      priorities: [],
    };
  }

  toggleReorder = () => {
    this.setState((prevState) => {
      return {
        reorder: !prevState.reorder,
      };
    });
  };

  reorderPriorities = async (
    priorities: PriorityDTO[],
    priorityId: string,
    destinationIndex: number,
  ) => {
    // const savedPriorities = this.props.priorities; // save priorities in case the request fails

    try {
      this.props.movePriority(priorityId, destinationIndex);
      await reorderPriority(priorityId, destinationIndex);
    } catch (err) {
      console.debug(err);
      this.props.reloadBoard();
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        <li>
          <hr />
        </li>
        <li>
          <div className="fill">
            <div className="flex-row fill">
              <div className="column py-0">
                <ToggleSwitch
                  id="reorderPriorities"
                  name="reorderPriorities"
                  changeMethod={this.toggleReorder}
                  isChecked={this.state.reorder}
                  tight={true}
                  labelText={t('reorderPriorities')}
                  labelClassName="fill"
                />
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column py-0">
                <hr />
                <Button
                  className="secondary-button fill"
                  onClick={() =>
                    this.props.setSelectedContext &&
                    this.props.setSelectedContext('addPriority')
                  }
                  disabled={this.state.reorder}
                >
                  {t('addPriority')}
                </Button>
              </div>
            </div>
            <div className="flex-row fill">
              <div className="column pt-0 pb-0">
                {this.state.reorder ? (
                  <PrioritiesListOrderable
                    reorderPriorities={this.reorderPriorities}
                    fixedPositionParent={this.props.fixedPositionParent}
                  />
                ) : (
                  <PriorityListUnorderable
                    setSelectedContext={this.props.setSelectedContext}
                    setSelectedPriority={this.props.setSelectedPriority}
                  />
                )}
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  withStyledTranslation('priorityOperationsMenu')(PriorityOperationsMenu),
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      priorities: ctx.board.priorities,
      reloadBoard: ctx.reloadBoard,
      movePriority: ctx.movePriority,
    };
  },
);
