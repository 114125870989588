import React, { Component, KeyboardEvent } from 'react';
import { handleClickOutside } from '../../common/helpers/handleClickOutside';
import ReactDOM from 'react-dom';
import { relativeParentOffset } from '../../common/helpers/relativeParentOffset';
import { getPortalContainer } from '../../utils/getPortalContainer';

const DROPDOWN_LABEL_OFFSET = 22;
const DROPDOWN_MARGIN = 72;

type Options<T> = Array<number | string | T>;

interface Props<T> {
  id: string;
  value: unknown;
  options: Options<T>;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  disabledValue?: string | number | T | null;
  classes?: string;
  error?: string;
  formGroupClassname?: string;
  dropDownClasses?: string;
  title?: string;
  srOnly?: boolean;
  fullWidth?: boolean;

  onChange: (
    ev: KeyboardEvent | React.MouseEvent<HTMLLIElement>,
    value: number | string | undefined | T,
  ) => void;
  getValue: (option: T) => unknown;
  getLabel: (option: T) => string;
  getDisabled?: (option: T) => boolean;
  onBlur?: (ev: React.FocusEvent<HTMLButtonElement>) => void;
}

interface State {
  showMenu: boolean;
  showError: boolean;
  verticalPos: number | undefined;
  dropUp: boolean;
  minWidth?: number;
  selectedValue: unknown;
  shiftKey: boolean;
  substring: string;
  lastKeyPress: number;
  transformStyle: React.CSSProperties | null;
  positionStyle: React.CSSProperties | null;
  widthStyle: React.CSSProperties | null;
}

class ComboBox<T> extends Component<Props<T>, State> {
  dropdownOptionsRef: React.RefObject<HTMLUListElement>;
  dropdownButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: Props<T>) {
    super(props);
    this.dropdownOptionsRef = React.createRef();
    this.dropdownButtonRef = React.createRef();
    const opensAbove = this.getOpensAbove();
    this.state = {
      showMenu: false,
      showError: false,
      verticalPos: this.dropdownOptionsRef.current?.offsetHeight,
      selectedValue: null,
      shiftKey: false,
      dropUp: opensAbove,
      substring: '',
      lastKeyPress: 0,
      transformStyle: null,
      positionStyle: null,
      widthStyle: null,
    };
  }

  // Helper to compute whether the dropdown should open above
  getOpensAbove = (): boolean => {
    const windownInnerHeight = window.innerHeight;
    if (!this.dropdownButtonRef.current || !this.dropdownOptionsRef.current)
      return false;
    return (
      windownInnerHeight -
        this.dropdownButtonRef.current.getBoundingClientRect().y <
      this.dropdownOptionsRef.current.offsetHeight + DROPDOWN_MARGIN
    );
  };

  toggleMenu = () => {
    this.setState(
      (prevState: State) => ({
        showMenu: !prevState.showMenu,
        selectedValue: !prevState.showMenu ? this.props.value : null,
      }),
      () => {
        const opensAbove = this.getOpensAbove();
        this.setState({
          verticalPos: opensAbove
            ? 0
            : this.props.label
              ? DROPDOWN_LABEL_OFFSET
              : 0,
          dropUp: opensAbove,
        });
      },
    );
  };

  handleToggleKeyDown = (ev: KeyboardEvent): void => {
    const {
      options,
      getValue: getOptionValue,
      getLabel,
      getDisabled,
    } = this.props;
    const { showMenu, selectedValue, substring, lastKeyPress } = this.state;
    const currentValue = showMenu ? selectedValue : this.props.value;
    const dropdownOptions = this.dropdownOptionsRef.current;
    const currentTime = Date.now();

    let selectedOption: T | unknown;
    // Use findIndex for clarity
    const selectedOptionIndex = options.findIndex(
      (option) => getOptionValue(option as T) === currentValue,
    );
    let optionNode: HTMLElement | null | undefined;

    const normalizedString = (str: string): string =>
      str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

    const getNextOption = (): void => {
      let nextIndex = selectedOptionIndex + 1;
      while (
        nextIndex < options.length &&
        getDisabled?.(options[nextIndex] as T)
      ) {
        nextIndex++;
      }
      if (nextIndex < options.length) {
        const nextOption = options[nextIndex];
        selectedOption = getOptionValue(nextOption as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    const getPrevOption = (): void => {
      let prevIndex = selectedOptionIndex - 1;
      while (prevIndex >= 0 && getDisabled?.(options[prevIndex] as T)) {
        prevIndex--;
      }
      if (prevIndex >= 0) {
        selectedOption = getOptionValue(options[prevIndex] as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    const getOption = (): void => {
      const optionStartsWithSubstring = options.find((option) =>
        normalizedString(String(getLabel(option as T)))
          .toLocaleLowerCase()
          .startsWith(normalizedString(substring)),
      );
      if (optionStartsWithSubstring !== undefined) {
        selectedOption = getOptionValue(optionStartsWithSubstring as T);
        optionNode = dropdownOptions?.querySelector(
          `[value="${selectedOption}"]`,
        );
      }
    };

    const selectOption = (): void => {
      if (selectedOption !== undefined && !getDisabled?.(selectedOption as T)) {
        if (dropdownOptions?.classList.contains('open')) {
          this.scrollToDropdownOption(optionNode, dropdownOptions);
          this.setState({ selectedValue: selectedOption });
        } else {
          this.props.onChange(ev as KeyboardEvent, selectedOption as T);
        }
      }
    };

    switch (ev.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        ev.preventDefault();
        getNextOption();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        ev.preventDefault();
        getPrevOption();
        break;
      case 'Tab':
        if (showMenu) {
          this.setState({ showMenu: false });
        }
        break;
      case 'Escape':
        this.setState({ showMenu: false });
        break;
      case ' ':
      case 'CapsLock':
        break;
      case 'Enter':
        if (
          showMenu &&
          selectedOption !== undefined &&
          !getDisabled?.(selectedOption as T)
        ) {
          this.props.onChange(ev as KeyboardEvent, selectedValue as T);
          this.setState({ showMenu: false });
        }
        break;
      default:
        if (currentTime - lastKeyPress < 800) {
          this.setState(
            (prevState) => ({
              ...prevState,
              substring: prevState.substring + ev.key.toLocaleLowerCase(),
            }),
            () => {
              getOption();
              selectOption();
            },
          );
        } else {
          this.setState({ substring: ev.key.toLocaleLowerCase() }, () => {
            getOption();
            selectOption();
          });
        }
    }
    selectOption();
  };

  scrollToDropdownOption(
    option: HTMLElement | null | undefined,
    dropdownOptions: HTMLUListElement | null,
  ) {
    const menuY = dropdownOptions?.getBoundingClientRect().y;
    const optionY =
      option &&
      dropdownOptions &&
      option.getBoundingClientRect().y + dropdownOptions.scrollTop;

    dropdownOptions?.querySelector('.selected')?.classList.remove('selected');
    option && option.classList.add('selected');
    option && option.focus();

    if (menuY && optionY) {
      dropdownOptions?.scrollTo({ top: optionY - menuY - 4, behavior: 'auto' });
    }
  }

  getMinWidth(): void {
    const elements =
      this.dropdownOptionsRef.current?.querySelectorAll<HTMLSpanElement>(
        'li > span',
      );
    if (!elements || elements.length === 0) return;
    const widths = Array.from(elements).map((el) => el.offsetWidth);
    this.setState({
      minWidth: (widths.length > 1 ? Math.max(...widths) : widths[0]) + 46,
    });
  }

  computeFixedPosition = () => {
    if (!this.dropdownButtonRef.current) return;

    const dropdownButtonRect =
      this.dropdownButtonRef.current.getBoundingClientRect();
    const borderOffset = 0;
    let buttonHeight = this.dropdownButtonRef.current.clientHeight;
    const buttonWidth = this.dropdownButtonRef.current.offsetWidth;
    buttonHeight += borderOffset;
    const buttonHeightOffset =
      dropdownButtonRect.top > window.innerHeight / 2
        ? -buttonHeight
        : buttonHeight;

    this.setState(
      {
        positionStyle: {
          left: dropdownButtonRect.left,
          top: dropdownButtonRect.top + buttonHeightOffset,
        },
        widthStyle: {
          width: buttonWidth,
        },
      },
      () => {
        // we put this in the callback because we need to make sure we have the correct left & top
        if (!this.dropdownOptionsRef.current || !this.dropdownButtonRef.current)
          return;
        const dropdownButtonRect =
          this.dropdownButtonRef.current.getBoundingClientRect();
        let translateY = `translateY(-${buttonHeight}px)`;
        let translateX = `translateX(calc(-100% + ${buttonWidth}px))`;

        if (relativeParentOffset(this.dropdownButtonRef.current!)) {
          translateX = `translateX(0)`;
        }

        if (dropdownButtonRect.left < window.innerWidth / 2) {
          translateX = `translateX(${borderOffset}px)`;
        }

        if (dropdownButtonRect.top > window.innerHeight / 2) {
          translateY = `translateY(calc(-100% + ${2 * buttonHeight}px)`;
        }

        this.setState({
          transformStyle: {
            transform: translateX + ' ' + translateY,
          },
        });
      },
    );
  };

  handleScrollClose = (e: Event) => {
    const target = e.target as HTMLElement;
    if (!this.state.showMenu) return;
    if (!target.contains(this.dropdownButtonRef.current)) return;
    handleClickOutside(
      e,
      () => this.setState({ showMenu: false }),
      this.dropdownButtonRef,
    );
  };

  private handleDocumentMousedown = (ev: MouseEvent) => {
    handleClickOutside(
      ev,
      () => this.setState({ showMenu: false }),
      this.dropdownOptionsRef,
      this.dropdownButtonRef,
    );
  };

  private handleDocumentKeydown = (ev: KeyboardEvent) => {
    const currentTime = new Date().getTime();
    this.setState({ lastKeyPress: currentTime });
    if (ev.shiftKey) {
      this.setState({ shiftKey: true });
    }
  };

  private handleDocumentKeyup = () => {
    this.setState({ shiftKey: false });
  };

  componentDidMount() {
    this.getMinWidth();
    // Replace anonymous functions with bound handlers
    document.addEventListener('mousedown', this.handleDocumentMousedown);
    document.addEventListener(
      'keydown',
      this.handleDocumentKeydown as unknown as EventListener,
    );
    document.addEventListener('keyup', this.handleDocumentKeyup);
    document.addEventListener('scroll', this.handleScrollClose, true);
  }

  //Need to show the error with a small delay because because when clicking an option we trigger option onBlur method which validates the field before the state is changed;
  componentDidUpdate(prevProps: Props<T>, prevState: State) {
    if (this.props.error !== prevProps.error) {
      if (this.props.error !== '' || this.props.error !== undefined) {
        setTimeout(() => {
          this.setState({
            showError: true,
          });
        }, 100);
      } else {
        this.setState({
          showError: true,
        });
      }
    }
    if (this.props.options !== prevProps.options && this.props.options.length) {
      this.getMinWidth();
    }

    if (this.state.showMenu !== prevState.showMenu) {
      if (this.state.showMenu) {
        this.computeFixedPosition();
        if (this.dropdownOptionsRef.current) {
          this.dropdownOptionsRef.current.addEventListener(
            'wheel',
            this.handlePreventPageScroll,
            { passive: false },
          );
        }
      } else {
        this.setState({
          positionStyle: null,
          transformStyle: null,
          widthStyle: null,
        });
        if (this.dropdownOptionsRef.current) {
          this.dropdownOptionsRef.current.removeEventListener(
            'wheel',
            this.handlePreventPageScroll,
          );
        }
      }
    }
  }

  componentWillUnmount(): void {
    // Remove the added event listeners
    document.removeEventListener('mousedown', this.handleDocumentMousedown);
    document.removeEventListener(
      'keydown',
      this.handleDocumentKeydown as unknown as EventListener,
    );
    document.removeEventListener('keyup', this.handleDocumentKeyup);
    document.removeEventListener('scroll', this.handleScrollClose, true);
    if (this.dropdownOptionsRef.current) {
      this.dropdownOptionsRef.current.removeEventListener(
        'wheel',
        this.handlePreventPageScroll,
      );
    }
  }

  handlePreventPageScroll = (event: WheelEvent) => {
    const dropdown = this.dropdownOptionsRef.current;
    if (
      dropdown &&
      (event.target == dropdown || dropdown.contains(event.target as Node))
    ) {
      const maxScrollTop = dropdown.scrollHeight - dropdown.clientHeight;
      if (dropdown.scrollTop === 0 && event.deltaY < 0) {
        event.preventDefault();
      } else if (dropdown.scrollTop === maxScrollTop && event.deltaY > 0) {
        event.preventDefault();
      }
    }
  };

  render() {
    const valueLabel =
      this.props.options !== undefined &&
      this.props.getLabel(
        this.props.options.find(
          (op) => this.props.getValue(op as T) === this.props.value,
        ) as T,
      );
    const allOptionsDisabled = this.props.options.every(
      (option) => this.props.getDisabled && this.props.getDisabled(option as T),
    );

    let buttonText;

    if (this.props.value !== undefined) {
      buttonText = valueLabel;
    } else {
      buttonText = this.props.disabledValue
        ? this.props.disabledValue
        : this.props.value;
    }

    const errorMessage = (
      <ul className="error-list light">
        <li>{this.props.error}</li>
      </ul>
    );

    const options = (
      <>
        <ul
          className={[
            'dropdown-options',
            this.state.transformStyle &&
            this.state.positionStyle &&
            this.state.widthStyle
              ? 'open'
              : '',
          ].join(' ')}
          ref={this.dropdownOptionsRef}
          style={{
            ...this.state.transformStyle,
            ...this.state.positionStyle,
            ...this.state.widthStyle,
            maxWidth: 'none',
          }}
        >
          {this.props.disabledValue && (
            <li className="disabled">
              <span>{this.props.disabledValue}</span>
            </li>
          )}
          {this.props.options?.map((option, index) => {
            const optionValue = this.props.getValue(option as T) as
              | string
              | number;
            return typeof this.props.getDisabled === 'function' &&
              this.props.getDisabled(option as T) === true ? (
              <li
                key={index}
                value={optionValue}
                className={
                  typeof this.props.getDisabled === 'function'
                    ? this.props.getDisabled(option as T) === true
                      ? 'disabled'
                      : ''
                    : ''
                }
                onKeyDown={this.handleToggleKeyDown}
              >
                <span>{this.props.getLabel(option as T)}</span>
              </li>
            ) : (
              <li
                key={index}
                value={optionValue}
                tabIndex={0}
                onClick={(ev) => {
                  if (this.props.value !== optionValue) {
                    this.props.onChange(ev, optionValue);
                  }
                  this.setState({
                    showMenu: false,
                  });
                }}
                onKeyDown={this.handleToggleKeyDown}
              >
                <span>{this.props.getLabel(option as T)}</span>
              </li>
            );
          })}
        </ul>
      </>
    );

    return (
      <>
        {this.props.options.length > 0 && (
          <div className={`form-group ${this.props.formGroupClassname}`}>
            <div
              className={`dropdown  ${this.props.dropDownClasses ?? ''} ${
                this.props.error ? 'error' : ''
              }`}
            >
              {this.props.label && (
                <>
                  <label
                    htmlFor={this.props.id}
                    className={`${this.props.srOnly ? 'sr-only' : ''}`}
                  >
                    {this.props.label}
                    {this.props.required && '*'}
                  </label>
                  {!this.props.srOnly && <br />}
                </>
              )}
              <button
                ref={this.dropdownButtonRef}
                className={`dropdown-toggle ${this.props.classes ?? ''}`}
                style={{
                  width: this.state.minWidth,
                  ...(this.props.fullWidth ? { minWidth: '100%' } : {}),
                }}
                id={this.props.id}
                name={this.props.id}
                title={this.props.title}
                onClick={() => {
                  this.toggleMenu();
                  //Scroll to selected option when opening the dropdown
                  setTimeout(() => {
                    this.scrollToDropdownOption(
                      this.dropdownOptionsRef.current?.querySelector(
                        `[value="${this.props.value}"]`,
                      ),
                      this.dropdownOptionsRef.current,
                    );
                  }, 10);
                }}
                onKeyDown={this.handleToggleKeyDown}
                disabled={this.props.disabled || allOptionsDisabled}
                onBlur={this.props.onBlur}
                type="button"
              >
                {buttonText}
              </button>

              {!this.state.showMenu &&
                !this.state.transformStyle &&
                !this.state.positionStyle &&
                !this.state.widthStyle &&
                options}
              {this.state.showMenu &&
                ReactDOM.createPortal(
                  options,
                  getPortalContainer('combo-box-root'),
                )}
            </div>

            {this.state.showError && this.props.error ? errorMessage : ''}
          </div>
        )}
      </>
    );
  }
}
export default ComboBox;
