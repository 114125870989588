import React from 'react';
import TagManageMenu from '../../menus/Tag/TagManageMenu';
import { TagDTO } from '../../../common/api/dtos/Tag';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { withContextAdapter } from '../ContextAdapter/withContextAdapter';
import BoardContext from '../../../common/contexts/BoardContext';
import { IBoardContext } from '../../../common/interfaces/BoardContext';
import MessageBar from '../../controls/MessageBar';
import { t } from 'i18next';

interface ContextProps {
  tags: TagDTO[];
  paid: boolean;
}
interface ExternalProps {
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedTag: (tag: TagDTO) => void;
}
interface Props extends ExternalProps, ContextProps {}

class TagsListUnorderable extends React.Component<Props> {
  render() {
    return (
      <ul className="control-list-component vertical">
        {this.props.tags.map((tag: TagDTO) => {
          return (
            <TagManageMenu
              key={tag.id}
              tag={tag}
              setSelectedContext={this.props.setSelectedContext}
              setSelectedTag={this.props.setSelectedTag}
            />
          );
        })}
        {this.props.paid == false && (
          <MessageBar
            type="info"
            icon="fas fa-lock"
            extraClasses="mt-xs"
          >
            <div
              className="mb-xs"
              style={{ maxWidth: '150px', whiteSpace: 'normal' }}
            >
              Upgrade to Borddo Pro and unlock up to 8 tags per board.
            </div>
            <form
              target="_blank"
              method="POST"
              action={`${process.env.REACT_APP_API_BASE_URL}/self/upgrade`}
            >
              <button className="secondary-button translucent">
                <span className="text no-wrap">
                  {t('cardActivity:upgradeButton')}
                </span>
              </button>
            </form>
          </MessageBar>
        )}
      </ul>
    );
  }
}

export default withContextAdapter<ExternalProps, IBoardContext, ContextProps>(
  TagsListUnorderable,
  BoardContext,
  (ctx: IBoardContext) => {
    return {
      tags: ctx.board.tags,
      paid: ctx.board.paid,
    };
  },
);
