import React from 'react';
import Button from '../../controls/Button';
import { TContextMenu } from '../../../common/types/ContextMenu';
import DirectionalButton from '../../controls/DirectionalButton';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import BoardContext from '../../../common/contexts/BoardContext';

interface Props extends WithTranslation {
  setSelectedContext?: (context: TContextMenu) => void;
  handleCollapseColumn: () => void;
}

class ColumnMenu extends React.Component<Props> {
  render() {
    const { t } = this.props;

    return (
      <>
        <li>
          <Button
            className="ghost-button"
            onClick={this.props.handleCollapseColumn}
          >
            <span className="icon">
              <span className="fas fa-compress-alt"></span>
            </span>
            <span className="text">{t('collapseColumn')}</span>
          </Button>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <DirectionalButton
            directionalButton={{
              text: t('cardLimit'),
              direction: 'right',
              disabled:
                this.context.board.user?.role !== 'owner' &&
                this.context.board.user?.role !== 'admin',
              onClick: () =>
                this.props.setSelectedContext &&
                this.props.setSelectedContext('setLimit'),
            }}
            className="flex-h-spread"
          />
        </li>
        <li>
          <DirectionalButton
            directionalButton={{
              text: t('archiveCards'),
              direction: 'right',
              disabled:
                this.context.board.user?.role !== 'owner' &&
                this.context.board.user?.role !== 'admin',
              onClick: () =>
                this.props.setSelectedContext &&
                this.props.setSelectedContext('archiveColumn'),
            }}
            className="flex-h-spread"
          />
        </li>
        <li>
          <DirectionalButton
            directionalButton={{
              text: t('deleteColumn'),
              disabled:
                this.context.board.user?.role !== 'owner' &&
                this.context.board.user?.role !== 'admin',
              direction: 'right',
              onClick: () =>
                this.props.setSelectedContext &&
                this.props.setSelectedContext('deleteColumn'),
            }}
            className="flex-h-spread"
          />
        </li>
        {this.context.board.user?.role !== 'owner' &&
          this.context.board.user?.role !== 'admin' && (
            <>
              <li>
                <hr />
              </li>
              <li>
                <p
                  className="text-sm faint-text py-xs px-xs"
                  style={{ maxWidth: '245px', whiteSpace: 'normal' }}
                >
                  <span className="fas fa-info-circle"></span>{' '}
                  <span>{t('accessNotice')}</span>
                </p>
              </li>
            </>
          )}
      </>
    );
  }
}

export default withStyledTranslation('columnMenu')(ColumnMenu);
ColumnMenu.contextType = BoardContext;
