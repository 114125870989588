import React from 'react';
import Button from '../../controls/Button';
import { PriorityDTO } from '../../../common/api/dtos/Priority';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { PRIORITY_COLORS } from '../../../common/configs/priority';
import { TColor } from '../../../common/types/Color';

interface Props {
  dbPriority: PriorityDTO;
  setSelectedContext?: (context: TContextMenu) => void;
  setSelectedPriority?: (priority: PriorityDTO) => void;
}

export default class PriorityManageMenu extends React.Component<Props> {
  handleClick = () => {
    this.props.setSelectedPriority &&
      this.props.setSelectedPriority(this.props.dbPriority);
    this.props.setSelectedContext &&
      this.props.setSelectedContext('editPriority');
  };

  render() {
    const priority: TColor = PRIORITY_COLORS.find(
      (color) => color.id === this.props.dbPriority.color,
    ) ||
      PRIORITY_COLORS[0] || { id: '', label: '', color: '', symbol: '' };
    return (
      <li className="fill">
        <Button
          className="ghost-button fill flex-h-spread"
          onClick={this.handleClick}
        >
          <span className={`icon accent-text-${priority.color}`}>
            <span className={`icon mr-0 ${priority.symbol} `}></span>
          </span>
          <span className="text">{this.props.dbPriority.name}</span>
          <span className="fas icon fa-pencil"></span>
        </Button>
      </li>
    );
  }
}
