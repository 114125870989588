import React, { useContext } from 'react';
import Notification from './Notification';
import AppContext from '../../../common/contexts/AppContext';

interface NotificationListProps {
  clearStatusMessage: (id: string) => void;
}

const NotificationList: React.FC<NotificationListProps> = ({
  clearStatusMessage,
}) => {
  const appContext = useContext(AppContext);
  return (
    <div className="notification-list-component">
      {appContext.notifications.messages.map((notification, index) => (
        <>
          <Notification
            key={notification.id}
            id={index}
            notificationData={notification}
            onDestroy={() => clearStatusMessage(notification.id)}
          />
        </>
      ))}
    </div>
  );
};

export default NotificationList;
