export const textStylingButtons = [
  { format: 'bold', icon: 'fas fa-bold', ariaLabel: 'Format Bold' },
  { format: 'italic', icon: 'fas fa-italic', ariaLabel: 'Format Italics' },
  {
    format: 'underline',
    icon: 'fas fa-underline',
    ariaLabel: 'Format Underline',
  },
  {
    format: 'strikethrough',
    icon: 'fas fa-strikethrough',
    ariaLabel: 'Format Strikethrough',
  },
  { format: 'code', icon: 'fas fa-code', ariaLabel: 'Format Code' },
];
