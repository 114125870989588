import React, { useState, useEffect } from 'react';

interface Props {
  label: string;
  name: string;
  id: string;
  minValue: number;
  maxValue: number;
  hideStepper?: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement> | number) => void;
  autoComplete?: string;
  ariaAutocomplete?: 'none' | 'inline' | 'list' | 'both';
  br?: boolean;
  placeholder?: string;
  srOnly?: boolean;
  value?: number;
  disabled?: boolean;
  required?: boolean;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  className?: string;
  tight?: boolean;
  autofocus?: boolean;
  formGroupClassNames?: string;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
}

const NumberControl: React.FC<Props> = (props) => {
  // Initialize state with the value prop.
  const [value, setValue] = useState<string>(props.value?.toString() || '');
  const [, setFocused] = useState<boolean>(false);

  // Sync state when props.value changes.
  useEffect(() => {
    setValue(props.value?.toString() || '');
  }, [props.value]);

  const onFocus = () => setFocused(true);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const increment = () => {
    if (props.disabled) return;
    const currentValue = value ? parseInt(value, 10) : props.minValue;
    if (currentValue < props.maxValue) {
      const newValue = currentValue + 1;
      setValue(newValue.toString());
      if (props.onChange) {
        props.onChange(newValue);
      }
    }
  };

  const decrement = () => {
    if (props.disabled) return;
    const currentValue = value ? parseInt(value, 10) : props.minValue;
    if (currentValue > props.minValue) {
      const newValue = currentValue - 1;
      setValue(newValue.toString());
      if (props.onChange) {
        props.onChange(newValue);
      }
    }
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const { minValue, maxValue } = props;
    const key = ev.key;
    let currentVal = ev.currentTarget.value;

    // Prevent leading zeros
    if (key === '0' && currentVal === '0') {
      ev.preventDefault();
      return;
    }

    if (key === 'Backspace') {
      currentVal = currentVal.slice(0, -1);
    }

    if (key === 'Delete') {
      ev.preventDefault();
    }

    if (key === 'Backspace' && Number(currentVal) < minValue) {
      setValue(minValue.toString());
      ev.preventDefault();
      if (props.onChange) {
        props.onChange(minValue);
      }
      return;
    }

    const proposedValue = parseInt(currentVal + key, 10);

    if (!isNaN(proposedValue)) {
      if (proposedValue < minValue) {
        setValue(minValue.toString());
        ev.preventDefault();
        if (props.onChange) {
          props.onChange(minValue);
        }
      } else if (proposedValue > maxValue) {
        setValue(maxValue.toString());
        ev.preventDefault();
        if (props.onChange) {
          props.onChange(maxValue);
        }
      }
    }
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    let inputVal = ev.target.value;
    // Trim leading zeros
    inputVal = inputVal.replace(/^0+/, '');
    if (inputVal === '') {
      inputVal = props.minValue.toString();
    }
    setValue(inputVal);
    if (props.onChange) {
      const numericValue = parseInt(inputVal, 10);
      props.onChange(numericValue);
    }
  };

  return (
    <div
      className={[
        'form-group',
        props.formGroupClassNames,
        props.tight ? 'tight' : '',
      ].join(' ')}
    >
      <label
        className={`${props.srOnly ? 'sr-only' : ''}`}
        htmlFor={props.id}
      >
        {props.label}
        {props.required ? '*' : ''}
      </label>
      {props.br && <br />}
      <div className="number-control">
        <input
          type="number"
          name={props.name}
          id={props.id}
          autoComplete={props.autoComplete}
          aria-autocomplete={props.ariaAutocomplete}
          onChange={handleChange}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={value}
          required={props.required}
          onBlur={onBlur}
          aria-invalid={`${!!props.error}`}
          autoFocus={props.autofocus}
          className={props.className}
          onKeyDown={(e) => {
            handleKeyDown(e);
            if (props.onKeyDown) {
              props.onKeyDown(e);
            }
          }}
          onFocus={onFocus}
          min={props.minValue}
          max={props.maxValue}
          pattern="\d*"
          maxLength={props.maxValue.toString().length}
        />
        {!props.hideStepper && (
          <div className="control-wrapper flex-h-center">
            <button
              className="ghost-button flex-h-center"
              type="button"
              disabled={props.disabled || value === props.maxValue.toString()}
              onClick={(e) => {
                increment();
                e.preventDefault();
              }}
            >
              <span className="fas fa-angle-up"></span>
            </button>
            <button
              className="ghost-button flex-h-center"
              type="button"
              disabled={props.disabled || value === props.minValue.toString()}
              onClick={(e) => {
                decrement();
                e.preventDefault();
              }}
            >
              <span className="fas fa-angle-down"></span>
            </button>
          </div>
        )}
      </div>
      {props.error && (
        <ul className="error-list light">
          <li>{props.error}</li>
        </ul>
      )}
    </div>
  );
};

export default NumberControl;
