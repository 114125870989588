import React, { useState, useCallback, useEffect } from 'react';
import { flushSync } from 'react-dom';
import ReactDOM from 'react-dom';
import { RouteComponentProps } from 'react-router';
import isDescendant from '../../common/helpers/isDescendant';
import { getPortalContainer } from '../../utils/getPortalContainer';

type Props = Partial<RouteComponentProps>;

const Tooltip: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredElement, setHoveredElement] = useState<HTMLElement | null>(
    null,
  );
  const [transformStyle, setTransformStyle] =
    useState<React.CSSProperties | null>(null);
  const [positionStyle, setPositionStyle] =
    useState<React.CSSProperties | null>(null);

  const updateMenuState = useCallback(
    (element: HTMLElement | null, menuState?: string) => {
      const oldElement = hoveredElement;
      flushSync(() => {
        setHoveredElement(element);
        setIsOpen(menuState === 'open');
      });
      if (menuState === 'close' && oldElement) {
        oldElement.title = oldElement.dataset.title || '';
      }
    },
    [hoveredElement],
  );

  const handleClose = useCallback(() => {
    updateMenuState(null, 'close');
  }, [updateMenuState]);

  const handleHover = useCallback(
    (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;
      if (
        target === hoveredElement ||
        isDescendant(document.querySelector('#tooltip-root'), target)
      ) {
        return;
      }
      if (target.title) {
        if (hoveredElement) {
          hoveredElement.title = hoveredElement.dataset.title || '';
        }
        updateMenuState(target, 'open');
        target.dataset.title = target.title;
        target.title = '';
      } else {
        handleClose();
      }
    },
    [hoveredElement, updateMenuState, handleClose],
  );

  const handleScrollClose = useCallback(
    (e: Event) => {
      if (
        !isDescendant(
          document.querySelector('#tooltip-root'),
          e.target as HTMLElement,
        )
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  const computeFixedPosition = useCallback((element: HTMLElement) => {
    const contextButtonRect = element.getBoundingClientRect();
    const borderOffset = 1;
    const buttonHeight = element.clientHeight + borderOffset;
    const buttonWidth = element.offsetWidth;

    setPositionStyle({
      right: 'auto',
      left: contextButtonRect.left,
      top: contextButtonRect.top + buttonHeight,
    });

    // Next tick simulation for re-computation after initial style set
    setTimeout(() => {
      const rect = element.getBoundingClientRect();
      let translateY = `translateY(0)`;
      let translateX = `translateX(calc(-100% + ${buttonWidth}px))`;
      let right = 'auto';
      let left = rect.left + 'px';

      if (rect.left < window.innerWidth / 2) {
        translateX = `translateX(${borderOffset}px)`;
      } else {
        translateX = `translateX(0)`;
        left = 'auto';
        right = window.innerWidth - rect.left - rect.width + 'px';
      }

      if (rect.top > window.innerHeight / 2) {
        translateY = `translateY(calc(-100% - ${buttonHeight - 2}px))`;
      }

      setPositionStyle((prev) => ({ ...prev, left, right }));
      setTransformStyle({ transform: `${translateX} ${translateY}` });
    }, 0);
  }, []);

  useEffect(() => {
    const mouseOverHandler = (ev: MouseEvent) => handleHover(ev);
    const scrollHandler = (e: Event) => handleScrollClose(e);
    const clickHandler = () => handleClose();
    const contextMenuHandler = () => handleClose();

    document.addEventListener('mouseover', mouseOverHandler);
    document.addEventListener('scroll', scrollHandler, true);
    document.addEventListener('click', clickHandler, true);
    document.addEventListener('contextmenu', contextMenuHandler, true);

    return () => {
      document.removeEventListener('mouseover', mouseOverHandler);
      document.removeEventListener('scroll', scrollHandler, true);
      document.removeEventListener('click', clickHandler, true);
      document.removeEventListener('contextmenu', contextMenuHandler, true);
    };
  }, [handleHover, handleScrollClose, handleClose]);

  useEffect(() => {
    if (isOpen && hoveredElement) {
      computeFixedPosition(hoveredElement);
    } else {
      setPositionStyle(null);
      setTransformStyle(null);
    }
  }, [isOpen, hoveredElement, computeFixedPosition]);

  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className="tooltip-component"
            style={{
              ...transformStyle,
              ...positionStyle,
            }}
          >
            {hoveredElement?.dataset.title && (
              <div className="tooltip-card open">
                <small style={{ wordBreak: 'normal' }}>
                  {hoveredElement.dataset.title}
                </small>
              </div>
            )}
          </div>,
          getPortalContainer('tooltip-root'),
        )}
    </>
  );
};

export default Tooltip;
