import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from 'react';

interface StepperProps {
  steps: { [key: string]: ReactNode };
  initialStep?: string;
  currentStep: string;
  reverseFirstAnimation?: boolean;
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  initialStep,
  currentStep: propCurrentStep,
  reverseFirstAnimation,
}) => {
  // Initialize state based on props
  const [currentStep, setCurrentStep] = useState(
    initialStep || Object.keys(steps)[0],
  );
  const [prevStep, setPrevStep] = useState(
    initialStep || Object.keys(steps)[0],
  );
  const [height, setHeight] = useState<number | undefined>(undefined);

  const contentRef = useRef<HTMLDivElement>(null);

  // Update element height
  const updateContentHeight = useCallback(() => {
    if (contentRef.current) {
      const element = contentRef.current;
      const parentElement = element.parentElement;
      if (parentElement) {
        let totalHeight = element.clientHeight;
        const parentComputedStyle = window.getComputedStyle(parentElement);
        totalHeight +=
          parseInt(parentComputedStyle.borderTopWidth, 10) +
          parseInt(parentComputedStyle.borderBottomWidth, 10) +
          parseInt(parentComputedStyle.paddingTop, 10) +
          parseInt(parentComputedStyle.paddingBottom, 10);
        setHeight(totalHeight);
      }
    }
  }, []);

  // Setup and cleanup ResizeObserver
  useEffect(() => {
    updateContentHeight();
    const observer = new ResizeObserver(() => {
      updateContentHeight();
    });
    if (contentRef.current) {
      observer.observe(contentRef.current);
    }
    return () => {
      if (contentRef.current) observer.unobserve(contentRef.current);
      observer.disconnect();
    };
  }, [updateContentHeight]);

  // Listen for prop changes and update state accordingly
  useEffect(() => {
    if (propCurrentStep !== currentStep) {
      setPrevStep(currentStep);
      setCurrentStep(propCurrentStep);
      updateContentHeight();
    }
  }, [propCurrentStep, currentStep, updateContentHeight]);

  const animationClass =
    Object.keys(steps).indexOf(currentStep) >
    Object.keys(steps).indexOf(prevStep)
      ? 'slide-to-left'
      : 'slide-to-right';
  const effectiveAnimationClass =
    reverseFirstAnimation && Object.keys(steps).indexOf(currentStep) === 0
      ? 'slide-to-left'
      : animationClass;

  return (
    <div className="flex-row fill">
      <div className="column">
        <div
          className="special-card fade-in-1 flex-v-center oobe-content"
          style={{ height }}
        >
          <div
            className="fill"
            style={{ overflow: 'hidden' }}
            ref={contentRef}
          >
            <div
              key={currentStep}
              className={`stepper ${effectiveAnimationClass}`}
            >
              {steps[currentStep]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
