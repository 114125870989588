import React, { Component, ChangeEvent } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button';
import TextBox from '../../../controls/TextBox';
import Joi from 'joi';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { customEmailValidation } from '../Registration/helper/customEmailValidation';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (emails: string[]) => void;
  onSkip: () => void;
  onPrev: () => void;
  organizingWith: 'justMe' | 'team' | undefined;
  peopleCount: number;
  members: string[];
}

interface State {
  emails: string[];
  formErrors: { [key: string]: string };
  hasErrors: boolean;
}

class StepMembers extends Component<Props, State> {
  emailSchema = Joi.string()
    .required()
    .trim(true)
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .custom(customEmailValidation)
    .messages(FormErrorMsgs.string);

  constructor(props: Props) {
    super(props);
    this.state = {
      emails: [
        ...this.props.members,
        ...Array(this.props.peopleCount - this.props.members.length - 1).fill(
          '',
        ),
      ],
      formErrors: {},
      hasErrors: false,
    };
  }

  componentDidMount(): void {
    if (this.props.organizingWith !== 'team') {
      this.handleSkip();
    }
  }

  handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.setState((prevState) => {
      const newEmails = [...prevState.emails];
      const formErrors = { ...prevState.formErrors };

      newEmails[index] = value;

      // Only validate if the field is not empty
      if (value.trim() === '') {
        delete formErrors[`email-${index}`];
      } else {
        // Validate the email
        const { error } = this.emailSchema.validate(value.trim());
        if (error) {
          formErrors[`email-${index}`] = error.message;
        } else {
          delete formErrors[`email-${index}`];
        }
      }

      // Check if there are any errors
      const hasErrors = Object.keys(formErrors).length > 0;

      return { emails: newEmails, formErrors, hasErrors };
    });
  };

  handleNext = () => {
    this.props.onNext(this.state.emails.filter((email) => email.trim() !== ''));
  };

  handleSkip = () => {
    this.props.onSkip();
  };

  handlePrev = () => {
    this.props.onPrev();
  };

  render() {
    const { t } = this.props;
    const { emails, formErrors, hasErrors } = this.state;

    // Determine if the "Next" button should be disabled
    const isNextButtonDisabled =
      hasErrors ||
      !emails.some(
        (email) =>
          email.trim() && !this.emailSchema.validate(email.trim()).error,
      );

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-users"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <div className="flex-v-center mb-xs">
              <Button
                className="ghost-button mr-xs"
                onClick={this.handlePrev}
              >
                <span className="fas fa-arrow-left"></span>
                <span className="sr-only">{t('stepStripe:back')}</span>
              </Button>
              <h2 className="mb-0">
                {t('step6.title', { count: this.props.uxStep })}
              </h2>
            </div>
            <div className="scrollable-container flex-v-center">
              <div className="scrollable-content">
                <p>{t('step6.description')}</p>
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className={`input-container ${
                      index > 0 &&
                      !emails[index - 1].trim() &&
                      !emails[index].trim()
                        ? 'hidden'
                        : 'reveal-up-1'
                    }`}
                  >
                    <TextBox
                      type="email"
                      value={email}
                      onChange={(event) => this.handleInputChange(index, event)}
                      placeholder={t('step6.emailPlaceholder')}
                      id={`email-${index}`}
                      name={`email-${index}`}
                      label=""
                      error={formErrors[`email-${index}`]}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="button-container">
              <Button
                className="ghost-button mr-xs px-sm"
                onClick={this.handleSkip}
              >
                {t('step6.skip')}
              </Button>
              <Button
                className="primary-button"
                onClick={this.handleNext}
                disabled={isNextButtonDisabled}
              >
                {t('step6.next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepMembers')(StepMembers);
