import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactElement,
} from 'react';
import ReactDOM from 'react-dom';
import { unite } from '../../common/helpers/unite';
import { TRequestStatus } from '../../common/types/RequestStatus';
import RequestStatus from '../partials/RequestStatus/RequestStatus';
import Button from './Button';
import { getPortalContainer } from '../../utils/getPortalContainer';

interface Props {
  size?: 'small' | 'medium' | 'large';
  title: string;
  message?: string | ReactElement;
  confirmText?: string;
  cancelText?: string;
  info?: JSX.Element;
  onConfirm: () => void;
  onCancel: () => void;
  focusableItem?: HTMLElement;
  status?: TRequestStatus;
}

const Dialog: React.FC<Props> = (props) => {
  // Destructure props
  const {
    size,
    title,
    message,
    confirmText,
    cancelText,
    info,
    onConfirm,
    onCancel,
    focusableItem,
    status,
    children,
  } = props;

  const [confirmed, setConfirmed] = useState(false);
  const dialogRef = useRef<HTMLDivElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const firstFocusableElement = useRef<HTMLElement | null>(null);
  const lastFocusableElement = useRef<HTMLElement | null>(null);
  const previouslyFocusedElement = useRef<Element | null>(null);
  const previouslyFocusedElementConfirmed = useRef<HTMLElement | null>(null);

  const setFocusableElements = useCallback(() => {
    if (dialogRef.current) {
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableContent =
        dialogRef.current.querySelectorAll<HTMLElement>(focusableElements);
      firstFocusableElement.current = focusableContent[0];
      lastFocusableElement.current =
        focusableContent[focusableContent.length - 1];
      requestAnimationFrame(() => {
        cancelRef.current?.focus();
      });
    }
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const TAB_KEY = 9;
      const ESC_KEY = 'Escape';
      const isTabPressed = e.key === 'Tab' || e.keyCode === TAB_KEY;
      const isEscPressed = e.key === ESC_KEY;

      if (isEscPressed) {
        e.preventDefault();
        onCancel();
        return;
      }
      if (!isTabPressed) return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement.current) {
          e.preventDefault();
          requestAnimationFrame(() => {
            lastFocusableElement.current?.focus();
          });
        }
      } else {
        if (document.activeElement === lastFocusableElement.current) {
          e.preventDefault();
          requestAnimationFrame(() => {
            firstFocusableElement.current?.focus();
          });
        }
      }
    },
    [onCancel],
  );

  const handleOnClick = () => {
    onConfirm();
    setConfirmed(true);
  };

  useEffect(() => {
    previouslyFocusedElement.current = document.activeElement;
    if (focusableItem) {
      previouslyFocusedElementConfirmed.current = focusableItem;
    }
    document.addEventListener('keydown', handleKeyDown);
    setFocusableElements();
    document.body.style.overflow = 'hidden';
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      requestAnimationFrame(() => {
        if (previouslyFocusedElement.current instanceof HTMLElement) {
          previouslyFocusedElement.current.focus();
        }
      });
      if (confirmed) {
        previouslyFocusedElementConfirmed.current?.focus();
      }
      document.body.style.overflow = '';
    };
  }, [handleKeyDown, setFocusableElements, focusableItem, confirmed]);

  const renderDialog = () => (
    <div
      className="dialog-structure"
      ref={dialogRef}
      tabIndex={-1}
    >
      <div className={unite('dialog-component', size ?? '')}>
        <h2 className="primary-title normalcase mb-xs">{title}</h2>
        <div className="dialog-content">
          {message && <p className="py-xs text-break-word">{message}</p>}
          {children}
          {info}
        </div>
        <div className="dialog-actions">
          <ul className="control-list-component">
            {confirmText && (
              <li>
                <Button
                  className="primary-button"
                  onClick={handleOnClick}
                  disabled={status === 'loading'}
                >
                  {status && <RequestStatus status={status} />}
                  <span className="text">{confirmText}</span>
                </Button>
              </li>
            )}
            {cancelText && (
              <li>
                <Button
                  className="secondary-button dialogCancel"
                  onClick={onCancel}
                  disabled={status === 'loading'}
                >
                  {cancelText}
                </Button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );

  // Replace the portal container with a dedicated element for the portal.

  return ReactDOM.createPortal(
    renderDialog(),
    getPortalContainer('dialog-root'),
  );
};

export default Dialog;
