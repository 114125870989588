import React from 'react';
import { createComment } from '../../../../common/api/endpoints/comment';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import Button from '../../../controls/Button';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import RequestStatus from '../../RequestStatus/RequestStatus';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import EditorControl from '../../../controls/EditorControl/EditorControl';
import AppContext from '../../../../common/contexts/AppContext';
import FluidHeight from '../../FluidHeight';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';

interface Props extends WithTranslation {
  disabled: boolean;
  boardCard: BoardCard;
  addComment: (commentData: CommentDTO) => void;
  handleAddComment?: () => void;
  newCard: boolean;
  cardAttachments: AttachmentDTO[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];

  description: string;
  tagIds: string[];
  priorityId: string | null;
}

interface State {
  form: {
    comment: string | null;
  };
  status: TRequestStatus;
  showLexical: boolean;
}

class AddCardComment extends React.Component<Props, State> {
  lexicalRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.lexicalRef = React.createRef();
    this.state = {
      form: {
        comment: null,
      },
      status: 'idle',
      showLexical: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.newCard !== this.props.newCard) {
      this.setState({
        form: {
          comment: null,
        },
        showLexical: false,
      });
    }
  }

  setComment = (value: string | null) => {
    this.setState({
      form: {
        comment: value,
      },
    });
  };

  createComment = async () => {
    if (this.state.form.comment !== null) {
      this.setState({
        status: 'loading',
      });
      try {
        const newComment = await createComment(
          this.props.boardCard.id,
          this.state.form.comment,
        );

        this.setState({
          form: {
            comment: null,
          },
          status: 'success',
        });

        this.props.addComment(newComment);
        this.props.handleAddComment && this.props.handleAddComment();
        this.setShowLexical(false);
      } catch (err) {
        console.debug(err);
        this.context.notifications.setMessages(err);
        this.setState({
          status: 'error',
        });
      }
    }
  };

  setShowLexical = (value: boolean) => {
    this.setState(
      {
        showLexical: value,
      },
      () => {
        if (value === false) {
          this.setState({
            status: 'idle',
          });
        }
      },
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div>
          <FluidHeight>
            {this.state.showLexical ? (
              <div>
                <EditorControl
                  namespace={`comment-editor-${this.props.boardCard.id}`}
                  onChange={this.setComment}
                  cardAttachments={this.props.cardAttachments}
                />
                <ul className="control-list-component">
                  <li>
                    <Button
                      className="primary-button"
                      onClick={this.createComment}
                      disabled={
                        this.state.status === 'loading' ||
                        this.state.form.comment === null
                      }
                    >
                      <RequestStatus status={this.state.status} />
                      <span className="icon fal fa-comment-alt-lines"></span>
                      <span className="text">{t('addComment')}</span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="secondary-button"
                      onClick={() => this.setShowLexical(false)}
                    >
                      <span className="text">{t('cancel')}</span>
                    </Button>
                  </li>
                </ul>
              </div>
            ) : (
              <div>
                <Button
                  className="secondary-button fill text-left flex-h-start py-sm px-sm"
                  onClick={() => this.setShowLexical(true)}
                >
                  <span className="icon fal fa-comment-alt-lines pt-3xs"></span>
                  <span className="text faint-text">{t('writeComment')}</span>
                </Button>
              </div>
            )}
          </FluidHeight>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('addCardComment')(AddCardComment);
AddCardComment.contextType = AppContext;
