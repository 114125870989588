import React from 'react';
import Button from '../../controls/Button';
import {
  CARD_DATA_ICONS,
  CARD_DATA_LABELS,
} from '../../../common/configs/CardDataLabels';
import { TCardData } from '../../../common/types/CardData';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';

interface Props extends WithTranslation {
  setSelectedCardData?: (generator: TCardData) => void;
  selectedCardData?: TCardData[];
}

interface State {
  cardData: TCardData[];
}

class CardDataMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cardData: Object.keys(CARD_DATA_LABELS) as TCardData[],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <div
            className="flex-row"
            style={{ maxWidth: '301px', flexWrap: 'wrap' }}
          >
            <div
              className="column flex-v-stretch fill py-2xs px-2xs"
              style={{ flexBasis: '33%' }}
            >
              <div className="flex-v-center text-center fill flex-h-center">
                <small
                  style={{
                    whiteSpace: 'normal',
                  }}
                >
                  {this.props.t('showOnly')}
                </small>
              </div>
            </div>
            {this.state.cardData.map((cardData: TCardData) => {
              const isActive = this.props.selectedCardData?.includes(cardData);

              return (
                <div
                  className={'column flex-v-stretch fill py-2xs px-2xs'}
                  style={{ flexBasis: '33%' }}
                  key={cardData}
                >
                  <Button
                    className={[
                      isActive ? 'active' : '',
                      'secondary-button fill',
                    ].join(' ')}
                    value={cardData}
                    onClick={() => this.props.setSelectedCardData!(cardData)}
                  >
                    <div className="fill text-center">
                      <span
                        className={`${CARD_DATA_ICONS[cardData]} py-xs ${
                          isActive ? 'accent-text' : ''
                        }`}
                      ></span>
                      <br />
                      <p
                        className="capitalized pb-xs text-xs"
                        style={{ whiteSpace: 'normal' }}
                      >
                        {t(CARD_DATA_LABELS[cardData])}
                      </p>
                    </div>
                  </Button>
                </div>
              );
            })}
          </div>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('cardDataMenu')(CardDataMenu);
