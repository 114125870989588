import React, { ChangeEvent, FormEvent, RefObject } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from '@wavemyth/react-beautiful-dnd';
import {
  deleteColumn,
  updateColumn,
} from '../../../../../common/api/endpoints/column';
import { getDropStyle } from '../../../../../common/helpers/getDropStyle';
import {
  showErrorNotifications,
  showSuccessNotifications,
} from '../../../../../common/helpers/showNotifications';
import Button from '../../../../controls/Button';
import TextBox from '../../../../controls/TextBox';
import BoardColumnHeader from '../BoardColumnLayout/BoardColumnHeader';
import BoardColumnBody from '../BoardColumnLayout/BoardColumnBody';
import BoardContext from '../../../../../common/contexts/BoardContext';
import { BoardColumnCard } from '../BoardColumnCard';
import ColumnContext from '../../../../menus/Column/ColumnContext';
import { withStyledTranslation } from '../../../StyledTranslation/StyledTranslation';
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
} from 'react-virtualized';
import { unite } from '../../../../../common/helpers/unite';
import BoardCard from '../../BoardCard/BoardCard';
import BoardCardPlaceholder from '../../BoardCard/BoardCardPlaceholder';
import { ICard } from '../../../../../common/interfaces/BoardContext';
import {
  BoardColumnExpandedProps,
  State,
  FormData,
  RowProps,
} from './partials/TypeUtils';
import { handleArchiveAllCards } from './partials/Helpers';
import VElementScrollbar from '../../../Scrollbars/VElementScrollbar';

const addEventListeners = (
  listeners: { event: string; handler: () => void }[],
) => {
  listeners.forEach(({ event, handler }) => {
    window.addEventListener(event, handler);
  });
};

const removeEventListeners = (
  listeners: { event: string; handler: () => void }[],
) => {
  listeners.forEach(({ event, handler }) => {
    window.removeEventListener(event, handler);
  });
};

class BoardColumnExpanded extends React.PureComponent<
  BoardColumnExpandedProps,
  State
> {
  addButtonRef: RefObject<HTMLButtonElement>;
  dropElement: RefObject<HTMLDivElement>;
  cache: CellMeasurerCache;
  isListenersAdded: boolean;
  isUpdatingCache: boolean;

  constructor(props: BoardColumnExpandedProps) {
    super(props);
    this.addButtonRef = React.createRef();
    this.dropElement = React.createRef();

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 141,
    });

    this.state = {
      showAddCard: false,
      showEditColumn: false,
      formData: {
        columnTitle: this.props.boardColumn.title,
        cardLimit: this.props.boardColumn.cardLimit || 0,
      },
      disableDrop: false,
    };
    this.isListenersAdded = false;
    this.isUpdatingCache = false;
  }

  componentDidMount(): void {
    this.checkOffset();
    this.addGlobalEventListeners();
    this.addColumnHeadListener();
  }

  componentDidUpdate(prevProps: Readonly<BoardColumnExpandedProps>): void {
    if (this.isUpdatingCache) return; // Avoid updates while updating cache

    const changedIndices: Set<number> = new Set();

    // Check for changes in visibleCards
    if (
      prevProps.boardColumn.visibleCards !== this.props.boardColumn.visibleCards
    ) {
      this.findChangedIndicesForICard(
        prevProps.boardColumn.visibleCards,
        this.props.boardColumn.visibleCards,
      ).forEach((index) => {
        changedIndices.add(index);
      });
      this.invalidateCache();
    }

    // Check for changes in selectedCardData
    if (prevProps.selectedCardData !== this.props.selectedCardData) {
      this.cache.clearAll(); // Clear all cache measurements only in this particular case
      this.invalidateCache();
    }

    // Measure and update cache for the changed items
    if (changedIndices.size > 0) {
      this.updateCacheAndRecomputeRowHeights(changedIndices);
    }

    if (!this.isListenersAdded) {
      this.checkOffset();
      this.addGlobalEventListeners();
      this.isListenersAdded = true;
    }
  }

  updateCacheAndRecomputeRowHeights = (changedIndices: Set<number>) => {
    this.isUpdatingCache = true;

    // Store the current scroll position
    const scrollPosition =
      this.props.listRef.current?.Grid?.state?.scrollTop || 0;

    requestAnimationFrame(() => {
      // Invalidate cache and update heights for changed indices
      changedIndices.forEach((index) => {
        console.debug(
          'Clearing cache and recomputing row height for index:',
          index,
        );

        // Clear cache for the specific row
        this.cache.clear(index, 0);

        const cardElement = document.querySelector(
          `[data-index="${index}"] .board-column-card`,
        );

        if (cardElement) {
          const newHeight = cardElement.getBoundingClientRect().height;
          const newWidth = this.cache.defaultWidth; // Fixed width

          // Update cache with new dimensions
          this.cache.set(index, 0, newWidth, newHeight);
          console.debug(
            `Index: ${index}, New Width: ${newWidth}, New Height: ${newHeight}`,
          );
        }
      });

      // Recompute row heights for affected rows
      changedIndices.forEach((index) => {
        this.props.listRef.current?.recomputeRowHeights(index);
      });

      // Scroll to the previous position to maintain UI consistency
      requestAnimationFrame(() => {
        this.props.listRef.current?.scrollToPosition(scrollPosition);
        this.isUpdatingCache = false;
      });
    });
  };

  findChangedIndicesForICard = (prevItems: ICard[], nextItems: ICard[]) => {
    const changedIndices: number[] = [];
    prevItems.forEach((prevItem, index) => {
      if (prevItem !== nextItems[index]) {
        changedIndices.push(index);
      }
    });
    return changedIndices;
  };

  componentWillUnmount(): void {
    this.removeGlobalEventListeners();
    this.removeColumnHeadListener();
  }

  addGlobalEventListeners = () => {
    const listeners = [
      { event: 'mousemove', handler: this.checkOffset },
      { event: 'resize', handler: this.checkOffset },
      { event: 'scroll', handler: this.checkOffset },
      { event: 'resize', handler: this.handleWindowResize },
    ];
    addEventListeners(listeners);
  };

  removeGlobalEventListeners = () => {
    const listeners = [
      { event: 'mousemove', handler: this.checkOffset },
      { event: 'resize', handler: this.checkOffset },
      { event: 'scroll', handler: this.checkOffset },
      { event: 'resize', handler: this.handleWindowResize },
    ];
    removeEventListeners(listeners);
  };

  addColumnHeadListener = () => {
    const columnHead = document.querySelector(
      `.card-board-list[data-rbd-draggable-id='${this.props.boardColumn.id}'] .list-drag-head`,
    ) as HTMLDivElement;
    if (columnHead) {
      columnHead.addEventListener('mousedown', (e: MouseEvent) =>
        e.stopPropagation(),
      );
    }
  };

  removeColumnHeadListener = () => {
    const columnHead = document.querySelector(
      `.card-board-list[data-rbd-draggable-id='${this.props.boardColumn.id}'] .list-drag-head`,
    ) as HTMLDivElement;
    if (columnHead) {
      columnHead.removeEventListener('mousedown', (e: MouseEvent) =>
        e.stopPropagation(),
      );
    }
  };

  checkOffset = () => {
    const childOffsetLeft =
      this.dropElement.current?.getBoundingClientRect().left;
    const flyoutOffsetLeft = document
      .querySelector('.show-flyout')
      ?.getBoundingClientRect().left;
    if (flyoutOffsetLeft && childOffsetLeft) {
      this.setState({ disableDrop: flyoutOffsetLeft - 100 < childOffsetLeft });
    } else {
      this.setState({ disableDrop: false });
    }
  };

  invalidateCache = () => {
    if (!this.isUpdatingCache && this.props.listRef.current) {
      // this.cache.clearAll(); // Clear all cache measurements
      this.props.listRef.current?.recomputeRowHeights(); // Recompute heights
    }
  };

  handleWindowResize = () => {
    this.forceUpdate();
  };

  addCardForm = (operation: 'open' | 'close') => {
    this.setState({ showAddCard: operation === 'open' }, () => {
      if (operation === 'close') {
        this.addButtonRef.current?.focus();
      }
    });
  };

  toggleShowEditColumn = () => {
    this.setState((prevState) => ({
      showEditColumn: !prevState.showEditColumn,
    }));
  };

  handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.toggleShowEditColumn();
      this.setState({
        formData: {
          columnTitle: this.props.boardColumn.title,
          cardLimit: this.props.boardColumn.cardLimit || 0,
        },
      });
    }
  };

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    this.toggleShowEditColumn();

    if (
      this.state.formData.columnTitle === this.props.boardColumn.title ||
      this.state.formData.columnTitle === ''
    )
      return;

    try {
      const data = { title: this.state.formData.columnTitle };
      const column = await updateColumn(this.props.boardColumn.id, data);
      this.props.setUpdatedColumn(column.id, column.title);
    } catch (err) {
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  handleDelete = async () => {
    const { t } = this.props;
    try {
      const result = await deleteColumn(this.props.boardColumn.id);
      this.props.setDeletedColumn(this.props.boardColumn.id);

      const notifications =
        result.cards.length === 1
          ? [
              t('deletedColumnSingular', {
                columnTitle: this.props.boardColumn.title,
              }),
            ]
          : [
              t('deletedColumn', {
                columnTitle: this.props.boardColumn.title,
                cardCount: result.cards.length,
              }),
            ];

      showSuccessNotifications(notifications, this.props.setMessages);
    } catch (err) {
      showErrorNotifications(err, this.props.setMessages);
    }
  };

  setLimit = (value: number) => {
    this.setState({
      formData: {
        cardLimit: value,
        columnTitle: this.props.boardColumn.title,
      },
    });
  };

  handleSubmitLimit = async (value: number) => {
    try {
      const data = { cardLimit: value == 0 ? null : value };
      const response = await updateColumn(this.props.boardColumn.id, data);
      this.context.reloadBoard(); // Reload board to update column card limits persistently in context
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          cardLimit: response.cardLimit || null,
        },
      }));
    } catch (err) {
      console.debug(err);
    }
  };

  updateForm = <K extends keyof FormData>(field: K, value: FormData[K]) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [field]: value,
      },
    }));
  };

  setColumnTitle = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('columnTitle', ev.target.value);

  handleCollapseColumn = () =>
    this.props.handleCollapseColumn(this.props.boardColumn.id);

  getRowRender = (cards: ICard[]) => {
    const renderRow = ({ index, key, style, parent }: RowProps) => {
      const boardCard: ICard = cards[index];
      if (!boardCard) return null;

      return (
        <CellMeasurer
          key={key}
          cache={this.cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
        >
          {({ measure }) =>
            this.renderBoardCard(boardCard, index, style, measure)
          }
        </CellMeasurer>
      );
    };

    return renderRow;
  };

  renderBoardCard = (
    boardCard: ICard,
    index: number,
    style: React.CSSProperties,
    measure: () => void,
  ) => (
    <div
      style={style}
      data-card-id={index + boardCard.id} // Set data attribute for scrolling
      ref={(el) => {
        if (el) {
          el.setAttribute('data-index', index.toString());
          requestAnimationFrame(() => {
            const currentHeight = this.cache.getHeight(index, 0);
            const newHeight = el.clientHeight;

            // Only call measure if the element height changes
            if (newHeight !== currentHeight) {
              try {
                measure();
              } catch (error) {
                console.warn('Failed to measure element:', error);
              }
            }
          });
        }
      }}
    >
      <div className="board-column-card">
        <BoardColumnCard
          boardCard={boardCard}
          index={index}
          selectedCardData={this.props.selectedCardData}
          selected={
            this.props.boardColumn.visibleCards[index].id ===
            this.props.selectedCardId
          }
          boardColumnId={this.props.boardColumn.id}
          cardNrPrefix={this.props.cardNrPrefix}
          isLast={index === this.props.boardColumn.visibleCards.length - 1}
          members={this.props.members}
          priorities={this.props.priorities}
          tags={this.props.tags}
          renderAdd={this.renderAdd}
          selectCard={this.props.selectCard}
          setCardRef={this.props.setCardRef}
          onLoad={measure}
        />
      </div>
    </div>
  );

  setListRef = (ref: List | null) => {
    (this.props.listRef as { current: List | null }).current = ref;
  };

  renderAdd = () => {
    const { t } = this.props;
    const cardLimit = this.state.formData.cardLimit || 0;
    const isDisabled =
      this.state.showAddCard ||
      (this.context.board.columnCardLimitState !== 'disabled' &&
        cardLimit > 0 &&
        this.props.boardColumn.cards.length >= cardLimit &&
        this.context.board.columnCardLimitState);
    return (
      !this.state.showAddCard && (
        <div className="flex-row tight-bottom fill add-card-button mx-0">
          <div className="column pt-0 pb-0 px-0">
            <Button
              className={unite({
                'secondary-button': true,
                fill: true,
                'flex-h-center': true,
              })}
              onClick={() => this.addCardForm('open')}
              disabled={isDisabled}
            >
              {isDisabled ? (
                t('limitEnforced')
              ) : (
                <>
                  <span className="fas fa-plus-circle text-xs mr-xs"></span>
                  <span className="text">{t('addCard')}</span>
                </>
              )}
            </Button>
          </div>
        </div>
      )
    );
  };

  renderColumnCount = () => {
    const { boardColumn } = this.props;
    const { cardLimit } = this.state.formData;

    const totalCards = boardColumn.cards.length;
    const visibleCards = boardColumn.visibleCards.length;
    const hasFilter = !!this.props.boardFilterValue;
    const hasLimit =
      this.props.columnCardLimitState !== 'disabled' && cardLimit;

    return (
      <sup className="pe-none no-wrap text-sm">
        <small>
          {hasFilter ? (
            <span>
              {visibleCards}
              {totalCards > 0 && (
                <span>
                  {' ('}
                  {totalCards}
                  {hasLimit && `/${cardLimit}`}
                  {')'}
                </span>
              )}
            </span>
          ) : hasLimit ? (
            <span>
              {totalCards}/{cardLimit}
            </span>
          ) : (
            <span>{totalCards}</span>
          )}
        </small>
      </sup>
    );
  };

  onArchiveAllCards = () => {
    handleArchiveAllCards(this.props);
  };

  onSetLimit = (value: number) => {
    this.handleSubmitLimit(value);
  };

  render() {
    const flyout = document.querySelector('.show-flyout') as HTMLElement;
    const isFlyoutOpened = !!flyout;
    const { t, boardColumn } = this.props;
    const isDraggable =
      this.context.board.user?.role === 'owner' ||
      this.context.board.user?.role === 'admin';

    return (
      <div ref={this.dropElement}>
        <div ref={this.props.ref}></div>
        <Draggable
          draggableId={boardColumn.id}
          index={this.props.index}
        >
          {(
            draggableProvided: DraggableProvided,
            draggableSnapshot: DraggableStateSnapshot,
          ) => (
            <Droppable
              droppableId={boardColumn.id}
              isDropDisabled={isFlyoutOpened && this.state.disableDrop}
              mode="virtual"
              renderClone={(
                provided: DraggableProvided,
                snapshot: DraggableStateSnapshot,
                rubric: DraggableRubric,
              ) => (
                <div
                  className={['column', 'card-board-list'].join(' ')}
                  {...draggableProvided.draggableProps}
                  style={getDropStyle(
                    draggableProvided.draggableProps.style,
                    snapshot,
                  )}
                >
                  <BoardCard
                    key={rubric.source.index}
                    disabled={false}
                    selected={
                      boardColumn.visibleCards[rubric.source.index].id ===
                      this.props.selectedCardId
                    }
                    cardNrPrefix={this.props.cardNrPrefix}
                    boardCard={boardColumn.visibleCards[rubric.source.index]}
                    columnId={boardColumn.id}
                    selectedCardData={this.props.selectedCardData}
                    tags={this.props.tags}
                    priorities={this.props.priorities}
                    members={this.props.members}
                    selectCard={this.props.selectCard}
                    setCardRef={this.props.setCardRef}
                    provided={provided}
                    snapshot={snapshot}
                    innerRef={provided.innerRef}
                  />
                </div>
              )}
            >
              {(
                droppableProvided: DroppableProvided,
                droppableSnapshot: DroppableStateSnapshot,
              ) => (
                <div
                  className={['column', 'card-board-list'].join(' ')}
                  {...draggableProvided.draggableProps}
                  ref={draggableProvided.innerRef}
                  style={getDropStyle(
                    draggableProvided.draggableProps.style,
                    draggableSnapshot,
                  )}
                >
                  <div
                    className={[
                      'list-drag-helper',
                      droppableSnapshot.isDraggingOver ? 'dragging-over' : '',
                      draggableSnapshot.isDragging ? 'dragging' : '',
                      this.props.columnCardLimitState !== 'disabled' &&
                      this.state.formData.cardLimit &&
                      this.state.formData.cardLimit < boardColumn.cards.length
                        ? 'static-border-danger'
                        : '',
                    ].join(' ')}
                  >
                    <BoardColumnHeader
                      provided={isDraggable ? draggableProvided : undefined}
                    >
                      <div className="column pb-2xs large">
                        {this.state.showEditColumn ? (
                          <form
                            onSubmit={this.handleSubmit}
                            onBlur={this.handleSubmit}
                            className="fill"
                          >
                            <TextBox
                              id="columnTitle"
                              name="columnTitle"
                              label="Column Title"
                              srOnly={true}
                              type="text"
                              value={this.state.formData.columnTitle}
                              onChange={this.setColumnTitle}
                              onKeyDown={this.handleInputKeyDown}
                              className="button-padding fill"
                              placeholder={t('enterColumnTitle')}
                              formGroupClassNames="mb-0"
                            />
                          </form>
                        ) : this.context.board.user?.role !== 'owner' &&
                          this.context.board.user?.role !== 'admin' ? (
                          <div
                            className="ghost-button soft-disabled"
                            title={boardColumn.title}
                          >
                            <span className="pe-none card-board-list-title">
                              {boardColumn.title}
                            </span>
                            &nbsp;
                            {this.renderColumnCount()}
                          </div>
                        ) : (
                          <div className="no-wrap">
                            <Button
                              className="ghost-button"
                              title={boardColumn.title}
                              onClick={this.toggleShowEditColumn}
                            >
                              <span className="pe-none card-board-list-title">
                                {boardColumn.title}
                              </span>
                            </Button>{' '}
                            <span className="inline-block mt-xs">
                              {this.renderColumnCount()}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="column pb-2xs flex-h-end">
                        <ColumnContext
                          triggerClassDefault="ghost-button"
                          triggerClassActive="secondary-button"
                          contextMenuClassName="align-h-start"
                          setDeletedColumn={this.handleDelete}
                          onArchiveAllCards={this.onArchiveAllCards}
                          onSetLimit={this.onSetLimit}
                          setLimit={this.setLimit}
                          limit={this.state.formData.cardLimit || 0}
                          handleCollapseColumn={this.handleCollapseColumn}
                          totalCards={boardColumn.cards.length}
                          columnCardLimitState={this.props.columnCardLimitState}
                        />
                      </div>
                    </BoardColumnHeader>
                    <BoardColumnBody provided={droppableProvided}>
                      <AutoSizer disableHeight>
                        {({ width }) => (
                          <VElementScrollbar>
                            <List
                              className={unite(
                                'scroll-content',
                                this.state.showAddCard ? 'form-open' : '',
                              )}
                              tabIndex={-1}
                              height={window.innerHeight - 168}
                              overscanRowCount={this.props.overscanRowCount}
                              rowCount={
                                droppableSnapshot.isUsingPlaceholder
                                  ? boardColumn.visibleCards.length + 1
                                  : boardColumn.visibleCards.length
                              }
                              rowHeight={this.cache.rowHeight}
                              width={width}
                              deferredMeasurementCache={this.cache}
                              rowRenderer={this.getRowRender(
                                boardColumn.visibleCards,
                              )}
                              style={{
                                transition: 'background-color 0.2s ease',
                              }}
                              ref={(ref) => {
                                if (ref) {
                                  this.setListRef(ref);
                                  if (ref instanceof HTMLElement) {
                                    droppableProvided.innerRef(
                                      ref as unknown as HTMLElement,
                                    );
                                  }
                                }
                              }}
                            />
                          </VElementScrollbar>
                        )}
                      </AutoSizer>
                      {this.renderAdd()}
                      {this.state.showAddCard && (
                        <BoardCardPlaceholder
                          addCardForm={this.addCardForm}
                          boardColumn={boardColumn}
                          setNewCard={(columnId, card) => {
                            this.props.setNewCard(columnId, card);
                          }}
                        />
                      )}
                    </BoardColumnBody>
                  </div>
                </div>
              )}
            </Droppable>
          )}
        </Draggable>
      </div>
    );
  }
}

export default withStyledTranslation('boardColumnExpanded')(
  BoardColumnExpanded,
);
BoardColumnExpanded.contextType = BoardContext;
