import React, { useEffect, useRef } from 'react';
import TextBox from '../../TextBox';
import Button from '../../Button';

interface UrlFormProps {
  url: string;
  setUrl: (url: string) => void;
  position: { top: number; left: number };
  handleUrlSubmit: () => void;
}

const UrlForm: React.FC<UrlFormProps> = ({
  url,
  setUrl,
  position,
  handleUrlSubmit,
}) => {
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        handleUrlSubmit();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleUrlSubmit]);

  return (
    <div
      ref={formRef}
      className="card opaque"
      style={{
        position: 'absolute',
        top: position.top,
        left: position.left,
        zIndex: 1,
        backgroundColor: 'rgba(var(--app-bg-color, 1)',
      }}
    >
      <TextBox
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Type URL here..."
        label={'Link URL'}
        name={'url'}
        id={'url'}
      />
      <ul className="control-list-component">
        <li>
          <Button
            className="primary-button"
            onClick={handleUrlSubmit}
            title="Save"
          >
            Save
          </Button>
        </li>
        <li>
          <Button
            className="ghost-button"
            onClick={handleUrlSubmit}
            title="Close form"
          >
            <span className="fas fa-times pe-none"></span>
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default UrlForm;
