import React, { Component } from 'react';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import Button from '../../controls/Button';
import DirectionalButton from '../../controls/DirectionalButton';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import ComboBox from '../../controls/ComboBox';
import { IRestoreColumn } from '../../../common/interfaces/RestoreColumn';

interface Props extends WithTranslation {
  directionalButton: IDirectionalButton;
  restoreColumnId: string | null;
  restoreColumns: IRestoreColumn[];
  setRestoreColumnId: (
    _: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    restoreColumnId: number | string | IRestoreColumn | undefined,
  ) => void;
  updatingCard: boolean;
  handleRestoreCard: () => void;
}

class Restore extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <>
        <li>
          <DirectionalButton
            directionalButton={this.props.directionalButton}
            className={`flex-h-spread`}
          />
        </li>
        <li>
          <div className="fill">
            <small className="faint-text">{t('column')}</small>
            <ComboBox
              id="restoreColumnId"
              value={this.props.restoreColumnId}
              required={true}
              options={this.props.restoreColumns}
              onChange={this.props.setRestoreColumnId}
              getValue={(op) => op.id}
              getLabel={(op) => op?.title}
              formGroupClassname="mb-xs"
              disabled={this.props.updatingCard}
            />
            <hr />
            <Button
              className="secondary-button fill"
              onClick={this.props.handleRestoreCard}
              disabled={this.props.updatingCard}
            >
              <span className="icon fas fa-reply"></span>
              <span className="text">{t('restore')}</span>
            </Button>
          </div>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('boardArchivedCardFlyout')(Restore);
