import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  TextFormatType,
  FORMAT_ELEMENT_COMMAND,
  ElementFormatType,
  ElementNode,
  UNDO_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  CAN_UNDO_COMMAND,
  CAN_REDO_COMMAND,
  $getNodeByKey,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../Button';
import { textStylingButtons } from './textStylingButtons';
import { alignmentButtons } from './alignmentButtons';
import {
  FormatState,
  getInitialFormatState,
  updateFormatState,
} from './formatState';
import UrlForm from './UrlForm';
import ContextMenu from '../../ContextMenu';
import BlockMenuOptions, {
  BlockType,
  blockTypeToBlockName,
  supportedBlockTypes,
} from './BlockMenuOptions';
import { $isListNode, ListNode } from '@lexical/list';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $isCodeNode,
  getCodeLanguages,
  getDefaultCodeLanguage,
} from '@lexical/code';
import ComboBox from '../../ComboBox';
import { t } from 'i18next';
import { indentationButtons } from './indentationButtons';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import { iconForAttachment } from './helpers/iconForAttachment';
import { $createAttachmentBlockNode } from './nodes/AttachmentBlockNode';

export default function ToolbarPlugin2({
  cardAttachments,
}: {
  cardAttachments: AttachmentDTO[];
}) {
  const [editor] = useLexicalComposerContext();
  const [formatState, setFormatState] = useState<FormatState>(
    getInitialFormatState(),
  );
  const [urlInputVisible, setUrlInputVisible] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null,
  );
  const [codeLanguage, setCodeLanguage] = useState('');
  const [showAttachmentsMenu, setShowAttachmentsMenu] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getTopLevelElementOrThrow();
      const elementFormat = (element as ElementNode).getFormatType();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      //Begin block type
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = (
            $isHeadingNode(element) ? element.getTag() : element.getType()
          ) as BlockType;
          setBlockType(type);
          if ($isCodeNode(element)) {
            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      //End block type

      //Begin normalized element node position detection
      setFormatState(updateFormatState(selection, elementFormat));
      if (elementDOM) {
        const editorInner = elementDOM.closest('.editor-inner');
        const editorInput = elementDOM.closest('.editor-input');
        if (editorInner && editorInput) {
          const editorInputRect = editorInput.getBoundingClientRect();
          const elementDOMRect = elementDOM.getBoundingClientRect();
          const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
          setPosition({
            top:
              elementDOMRect.top -
              editorInputRect.top +
              elementDOMRect.height +
              toolbarHeight,
            left: elementDOMRect.left - editorInputRect.left,
          });
        }
      }
      //End normalized element node position detection
    }
  }, [editor]);

  useEffect(() => {
    const unregister = editor.registerUpdateListener(() => {
      editor.getEditorState().read(updateToolbar);
    });
    return unregister;
  }, [editor, updateToolbar]);

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(updateToolbar);
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        1,
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        1,
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        1,
      ),
    );
    return unregister;
  }, [editor, updateToolbar]);

  useEffect(() => {
    const handleDoubleClick = () => {
      editor.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = selection.anchor.getNode();
          const parent = node.getParent();
          if (parent && parent.getType() === 'link') {
            setUrl((parent as any).getURL());
            setUrlInputVisible(true);
          }
        }
      });
    };

    document.addEventListener('dblclick', handleDoubleClick);
    return () => {
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, [editor]);

  // Add keydown listener for applying indent/outdent via Tab and Shift+Tab keys
  useEffect(() => {
    const rootElement = editor.getRootElement();
    if (!rootElement) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if (event.shiftKey) {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        } else {
          editor.read(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const anchorNode = selection.anchor.getNode();
              const parent = anchorNode.getParent();
              if (parent && parent.getIndent() < 3) {
                editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
              }
            }
          });
        }
      }
    };
    rootElement.addEventListener('keydown', handleKeyDown);
    return () => {
      rootElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [editor]);

  const styleButtons = useMemo(() => [...textStylingButtons], []);
  const alignButtons = useMemo(
    () => [
      ...alignmentButtons,
      { format: 'link', icon: 'fas fa-link', ariaLabel: 'Insert Link' },
    ],
    [],
  );
  const indentButtons = useMemo(() => [...indentationButtons], []);

  const codeLanguages = useMemo(() => getCodeLanguages(), []);

  const onCodeLanguageSelect = useCallback(
    (e: unknown, value: number | string | undefined) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(value?.toString() || '');
          }
        }
      });
    },
    [editor, selectedElementKey],
  );

  const isFormatActive = (format: string): boolean => {
    const key =
      `is${format.charAt(0).toUpperCase() + format.slice(1)}` as keyof FormatState;
    return formatState[key];
  };

  const handleButtonClick = (format: string) => {
    if (['left', 'center', 'right', 'justify'].includes(format)) {
      editor.dispatchCommand(
        FORMAT_ELEMENT_COMMAND,
        format as ElementFormatType,
      );
    } else if (format === 'link') {
      editor.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = selection.anchor.getNode();
          const parent = node.getParent();
          if (parent && parent.getType() === 'link') {
            setUrl((parent as any).getURL());
          }
        }
      });
      setUrlInputVisible(true);
    } else {
      if (format === 'indent') {
        editor.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const parent = anchorNode.getParent();
            if (parent && parent.getIndent() < 3) {
              editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
            }
          }
        });
      } else if (format === 'outdent') {
        editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
      } else {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, format as TextFormatType);
      }
    }
  };

  const handleUrlSubmit = () => {
    let formattedUrl = url;
    if (!/^https?:\/\//i.test(url)) {
      formattedUrl = `http://${url}`;
    }
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, url ? formattedUrl : null);
    setUrlInputVisible(false);
    setUrl('');
  };

  function handleInsertAttachment(attachmentId: string) {
    console.log('handleInsertAttachment', attachmentId);
    editor.update(() => {
      const attachment = cardAttachments.find((att) => att.id === attachmentId);
      if (!attachment) return;
      const attachmentNode = $createAttachmentBlockNode(attachment);
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.insertNodes([attachmentNode]);
      }
      setShowAttachmentsMenu(false);
    });
  }

  return (
    <div
      ref={toolbarRef}
      className="card opaque py-2xs px-2xs"
      style={{ position: 'sticky', zIndex: 2, top: 0 }}
    >
      <ul className="control-list-component">
        <li>
          <Button
            disabled={!canUndo}
            onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
            className="secondary-button spaced"
            aria-label="Undo"
          >
            <i className="fas fa-undo" />
          </Button>
        </li>
        <li>
          <Button
            disabled={!canRedo}
            onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
            className="secondary-button"
            aria-label="Redo"
          >
            <i className="fas fa-redo" />
          </Button>
        </li>

        <li>
          {supportedBlockTypes.has(blockType) && (
            <>
              <ContextMenu
                dept={0}
                contextMenuClassName="align-v-start"
                triggerClassDefault="secondary-button"
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                showBlockOptionsDropDown={showBlockOptionsDropDown}
                triggerContent={
                  <>
                    <span className="text">
                      {blockTypeToBlockName[blockType]}
                    </span>
                    <span className="icon fas fa-angle-down"></span>
                  </>
                }
              >
                <BlockMenuOptions
                  editor={editor}
                  blockType={blockType}
                  toolbarRef={toolbarRef}
                  setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
                  setBlockType={setBlockType}
                />
              </ContextMenu>
            </>
          )}
        </li>
        {blockType === 'code' && (
          <>
            <li>
              <ComboBox
                id="code-language"
                formGroupClassname="pb-0 mb-0"
                label={t('codeLanguage')}
                title={t('codeLanguage')}
                classes="secondary-button flex-h-start"
                onChange={onCodeLanguageSelect}
                options={codeLanguages}
                value={codeLanguage}
                getValue={(option) => option}
                getLabel={(option) => option}
                srOnly={true}
              />
              <i className="chevron-down inside" />
            </li>
          </>
        )}
        {styleButtons.map(({ format, icon, ariaLabel }) => (
          <li key={format}>
            <Button
              disabled={blockType === 'code'}
              onClick={() => handleButtonClick(format)}
              title={ariaLabel}
              className={`secondary-button spaced ${isFormatActive(format) ? 'active-border' : ''}`}
              aria-label={ariaLabel}
            >
              <i className={`${icon} pe-none`} />
            </Button>
          </li>
        ))}
        {alignButtons.map(({ format, icon, ariaLabel }) => (
          <li key={format}>
            <Button
              onClick={() => handleButtonClick(format)}
              title={ariaLabel}
              className={`secondary-button spaced ${isFormatActive(format) ? 'active-border' : ''}`}
              aria-label={ariaLabel}
            >
              <i className={`${icon} pe-none`} />
            </Button>
          </li>
        ))}
        {indentButtons.map(({ format, icon, ariaLabel }) => (
          <li key={format}>
            <Button
              disabled={blockType === 'code'}
              onClick={() => handleButtonClick(format)}
              title={ariaLabel}
              className={`secondary-button spaced ${isFormatActive(format) ? 'active-border' : ''}`}
              aria-label={ariaLabel}
            >
              <i className={`${icon} pe-none`} />
            </Button>
          </li>
        ))}
        <li>
          <ContextMenu
            dept={0}
            contextMenuClassName="align-v-start"
            triggerClassDefault="secondary-button"
            triggerClassActive="secondary-button active-border"
            setShowBlockOptionsDropDown={setShowAttachmentsMenu}
            showBlockOptionsDropDown={showAttachmentsMenu}
            title="Attachments - ALT+3"
            name="attachmentsMenu"
            triggerContent={
              <>
                <i className="fas fa-paperclip pe-none" />
                <span className="icon fas fa-angle-down"></span>
              </>
            }
          >
            <>
              {cardAttachments.map((attachment) => (
                <li key={attachment.id}>
                  <Button
                    className="ghost-button"
                    onClick={() => handleInsertAttachment(attachment.id)}
                  >
                    <span
                      className={`${iconForAttachment(attachment.contentType)} icon`}
                    ></span>
                    <span className="text">{attachment.filename}</span>
                  </Button>
                </li>
              ))}
              {cardAttachments.length === 0 && (
                <li>
                  <p>Card has no attachments</p>
                </li>
              )}
            </>
          </ContextMenu>
        </li>
      </ul>
      {urlInputVisible && (
        <UrlForm
          url={url}
          setUrl={setUrl}
          position={position}
          handleUrlSubmit={handleUrlSubmit}
        />
      )}
    </div>
  );
}
