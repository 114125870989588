import isSupportedImageMimeType from '../../../../../common/helpers/isSupportedImageMimeType';
import isSupportedVideoMimeType from '../../../../../common/helpers/isSupportedVideoMimeType';

export function iconForAttachment(contentType: string): string {
  if (isSupportedImageMimeType(contentType)) {
    return 'fas fa-image';
  }
  if (isSupportedVideoMimeType(contentType)) {
    return 'fas fa-video';
  }
  return 'fas fa-download';
}

export function isViewable(contentType: string): boolean {
  return (
    isSupportedImageMimeType(contentType) ||
    isSupportedVideoMimeType(contentType)
  );
}
