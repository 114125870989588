import React, { useState, useEffect, useCallback } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import ComboBox from './ComboBox';

interface TabData {
  id: string;
  label: string;
  content: JSX.Element;
  animatedOnIcon?: JSX.Element;
  animatedOffIcon?: JSX.Element;
  icon?: string;
  disabled?: boolean;
}

interface Props {
  tabs: TabData[];
  vertical?: boolean;
  defaultTab?: string;
  setActiveTab?: (tab: string) => void;
  parentUrl?: string;
}

const MenuTabs: React.FC<Props> = (props) => {
  const {
    tabs,
    defaultTab,
    parentUrl,
    vertical,
    setActiveTab: externalSetActiveTab,
  } = props;
  const history = useHistory();
  const location = useLocation();

  const initialActive = defaultTab || tabs[0].id;
  const initialIndex = tabs.findIndex((tab) => tab.id === defaultTab);

  const [activeTab, setActiveTabLocal] = useState(initialActive);
  const [prevIndex, setPrevIndex] = useState<number | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  // Sync activeTab with current location changes
  useEffect(() => {
    if (parentUrl && location.pathname === parentUrl) {
      history.replace(`${parentUrl}/${activeTab}`);
    } else {
      tabs.forEach((tab) => {
        const regex = new RegExp(`/${tab.id}(/|$)`);
        if (regex.test(location.pathname)) {
          setActiveTabLocal(tab.id);
        }
      });
    }
  }, [location.pathname, parentUrl, activeTab, tabs, history]); // updated dependencies

  // Sync when history location changes vs current activeTab
  useEffect(() => {
    if (
      parentUrl &&
      history.location.pathname !== `${parentUrl}/${activeTab}`
    ) {
      const parts = history.location.pathname.split('/');
      const part = parts.pop() || activeTab;
      if (tabs.some((tab) => tab.id === part)) {
        setActiveTabLocal(part);
      }
    }
  }, [history.location.pathname, parentUrl, activeTab, tabs]); // updated dependencies

  // New effect: Redirect to 404 if URL tab id is invalid.
  useEffect(() => {
    if (!parentUrl) return;
    // Do not check when URL equals parentUrl
    if (location.pathname === parentUrl) return;
    const validPrefix = parentUrl.endsWith('/') ? parentUrl : `${parentUrl}/`;
    if (!location.pathname.startsWith(validPrefix)) return;
    const remainingPath = location.pathname.slice(validPrefix.length);
    const tabId = remainingPath.split('/')[0]; // use first segment after parentUrl
    if (!tabs.some((tab) => tab.id === tabId)) {
      history.replace('/404');
    }
  }, [location.pathname, parentUrl, tabs, history]);

  const setActiveTab = useCallback(
    (newActiveTab: string, redirect?: boolean) => {
      if (parentUrl) {
        if (redirect) {
          history.replace(`${parentUrl}/${newActiveTab}`);
        } else {
          history.push(`${parentUrl}/${newActiveTab}`);
        }
      }
      setPrevIndex(currentIndex);
      setCurrentIndex(tabs.findIndex((tab) => tab.id === newActiveTab));
      setActiveTabLocal(newActiveTab);
      if (externalSetActiveTab) externalSetActiveTab(newActiveTab);
    },
    [history, parentUrl, currentIndex, tabs, externalSetActiveTab],
  );

  const setActiveTabMobile = (
    ev: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<Element>,
    value: unknown,
  ) => {
    setActiveTab(value as string);
  };

  const tabAnimation = (id: string): string => {
    if (location.pathname.includes(id) || (!parentUrl && activeTab === id)) {
      if (prevIndex !== undefined) {
        return prevIndex < currentIndex ? 'active' : 'active reverse';
      }
      return 'active';
    }
    return '';
  };

  const panelAnimation = (): string => {
    if (vertical) {
      if (prevIndex !== undefined) {
        return prevIndex < currentIndex
          ? 'reveal-up-1-no-stack'
          : 'reveal-down-1-no-stack';
      }
      return '';
    } else {
      if (prevIndex !== undefined) {
        return prevIndex < currentIndex ? 'reveal-left-1' : 'reveal-right-1';
      }
      return '';
    }
  };

  const renderTabs = () => (
    <>
      <ul className="nav-list hidden-mobile">
        {tabs.map((tab) => (
          <li
            className="nav-li"
            key={tab.id}
          >
            <button
              className={`nav-link ${tabAnimation(tab.id)}`}
              onClick={() => setActiveTab(tab.id)}
              disabled={tab.disabled || !!tabAnimation(tab.id)}
            >
              <div className={`content-wrapper ${vertical ? 'py-3xs' : ''}`}>
                {tab.animatedOnIcon && tab.animatedOffIcon ? (
                  <>
                    {tabAnimation(tab.id)
                      ? tab.animatedOnIcon
                      : tab.animatedOffIcon}
                  </>
                ) : (
                  <>
                    <span className={`${tab.icon} icon`}></span>
                  </>
                )}
                <span className="link-text">{tab.label}</span>
              </div>
            </button>
          </li>
        ))}
      </ul>
      <div className="flex-row visible-mobile">
        <div className="column pl-sm pt-2xs pb-0">
          <ComboBox
            id="tabNav"
            value={activeTab}
            disabled={false}
            options={tabs}
            onChange={setActiveTabMobile}
            getValue={(op) => op?.id}
            getLabel={(op) => op?.label}
            formGroupClassname="mb-0"
            title="Navigation"
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );

  const renderTabContent = (tab: TabData) => tab.content;

  return (
    <>
      {vertical ? (
        <div className="flex-row fill">
          <div className="column pt-0 pl-0 menu-tabs-structure">
            <div
              className="navigation-component"
              style={{ position: 'sticky', top: '60px' }}
            >
              {renderTabs()}
            </div>
          </div>
          <div className="column pt-0 large">
            {tabs.map((tab) => (
              <React.Fragment key={tab.id}>
                {parentUrl ? (
                  <Route path={`${parentUrl}/${tab.id}`}>
                    <div className={panelAnimation()}>
                      {renderTabContent(tab)}
                    </div>
                  </Route>
                ) : (
                  activeTab === tab.id && (
                    <div className={panelAnimation()}>
                      {renderTabContent(tab)}
                    </div>
                  )
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div className="flex-row fill">
            <div className="column pb-0">
              <div className="navigation-component horizontal">
                {renderTabs()}
              </div>
            </div>
          </div>
          {tabs.map((tab) => (
            <React.Fragment key={tab.id}>
              {parentUrl ? (
                <Route path={`${parentUrl}/${tab.id}`}>
                  <div className={panelAnimation()}>
                    {renderTabContent(tab)}
                  </div>
                </Route>
              ) : (
                activeTab === tab.id && (
                  <div className={panelAnimation()}>
                    {renderTabContent(tab)}
                  </div>
                )
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default MenuTabs;
