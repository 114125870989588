import { RangeSelection } from 'lexical';

export type FormatState = {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikethrough: boolean;
  isCode: boolean;
  isLeft: boolean;
  isCenter: boolean;
  isRight: boolean;
  isJustify: boolean;
};

export const getInitialFormatState = (): FormatState => ({
  isBold: false,
  isItalic: false,
  isUnderline: false,
  isStrikethrough: false,
  isCode: false,
  isLeft: false,
  isCenter: false,
  isRight: false,
  isJustify: false,
});

export const updateFormatState = (
  selection: RangeSelection,
  elementFormat: string,
): FormatState => ({
  isBold: selection.hasFormat('bold'),
  isItalic: selection.hasFormat('italic'),
  isUnderline: selection.hasFormat('underline'),
  isStrikethrough: selection.hasFormat('strikethrough'),
  isCode: selection.hasFormat('code'),
  isLeft: elementFormat === 'left',
  isCenter: elementFormat === 'center',
  isRight: elementFormat === 'right',
  isJustify: elementFormat === 'justify',
});
