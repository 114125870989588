import React, { useCallback, useMemo } from 'react';
import { TagDTO } from '../../../../../common/api/dtos/Tag';
import ContextMenu from '../../../../controls/ContextMenu';
import TagContext from '../../../../menus/Tag/TagContext';
import { getColorSymbol } from '../../../../../common/helpers/getColorSymbol';
import { TAG_COLORS } from '../../../../../common/configs/tag';
import RemoveTagMenu from './partial/RemoveTagMenu';

interface Props {
  cardId: string;
  tagIds: string[];
  tags: TagDTO[];
  disabled?: boolean;
}

const BoardCardTags: React.FC<Props> = ({
  cardId,
  tagIds,
  tags,
  disabled = false,
}) => {
  // Memoize the tag context to avoid unnecessary re-renders
  const renderTagContext = useMemo(() => {
    return (
      !disabled && (
        <li>
          <TagContext
            disabled={disabled}
            contextId={cardId}
            selectedTags={tagIds}
            amount={tagIds.length}
            action={'assign'}
            cardId={cardId}
            contextMenuClassName="align-h-start"
            triggerClassDefault="ghost-button"
            triggerClassActive="secondary-button"
            hideCounter={true}
            iconClass="fal fa-tags"
          />
        </li>
      )
    );
  }, [disabled, cardId, tagIds]);

  // Render tag items based on passed props
  const renderTags = useCallback(() => {
    return tags?.map((tag) => {
      const symbol = getColorSymbol(TAG_COLORS, tag.color);
      return (
        <li key={tag.id}>
          <ContextMenu
            dept={0}
            contextId={tag.id}
            contextMenuClassName="align-h-start"
            triggerClassDefault="ghost-button"
            triggerClassActive="secondary-button"
            isDisabled={disabled}
            triggerContent={
              <>
                <span
                  className={`cb-none badge-text uppercase accent-${tag.color}`}
                >
                  {tag.name}
                </span>
                <span
                  className={`cb-only flag-text flag-text-${tag.color} uppercase`}
                >
                  <span className={`${symbol} accent-text-${tag.color}`}></span>{' '}
                  {tag.name}
                </span>
              </>
            }
            rightClickTrigger={true}
          >
            <RemoveTagMenu
              cardId={cardId}
              tagId={tag.id}
              tagName={tag.name}
            />
          </ContextMenu>
        </li>
      );
    });
  }, [tags, disabled, cardId]);

  return (
    <div className="flex-row fill">
      <div className="column py-0">
        <ul className="control-list-component">
          {renderTagContext}
          {renderTags()}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(BoardCardTags);
