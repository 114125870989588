import React, { ChangeEvent, Component } from 'react';
import Joi from 'joi';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { FormErrorMsgs } from '../../../../common/configs/FormErrors';
import { processJoiFieldError } from '../../../../common/helpers/processJoiFieldError';
import { processJoiError } from '../../../../common/helpers/processJoiError';
import TextBox from '../../../controls/TextBox';
import Button from '../../../controls/Button';
import { restoreBoard } from '../../../../common/api/endpoints/board';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import { deleteBoard } from '../../../../common/api/endpoints/board';
import Dialog from '../../../controls/Dialog';
import ContextMenu from '../../../controls/ContextMenu';
import Thumbnail from '../../Thumbnail/Thumbnail';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import { Trans, WithTranslation } from 'react-i18next';

interface FormData {
  boardName: string;
}

type FormErrors = {
  [key in keyof FormData]?: string;
};

interface Props extends WithTranslation {
  id: string;
  name: string;
  description: string;
  removeFromArchive: (id: string) => void;
  owned?: boolean;
  thumbnail: string;
  color?: string;
}

interface State {
  showDelete: boolean;
  formData: FormData;
  formErrors: FormErrors;
  restoreStatus: TRequestStatus;
  deleteStatus: TRequestStatus;
  serverErrors: string[];
}

class BoardCardArchived extends Component<Props, State> {
  formSchema = Joi.object({
    boardName: Joi.string()
      .required()
      .equal(this.props.name)
      .label(this.props.t('labelNoMatch'))
      .trim(true)
      .messages(FormErrorMsgs.string),
  });

  constructor(props: Props) {
    super(props);
    this.state = {
      restoreStatus: 'idle',
      deleteStatus: 'idle',
      showDelete: false,
      formData: {
        boardName: '',
      },
      formErrors: {},
      serverErrors: [],
    };
  }

  toggleShowDelete = () => {
    this.setState((prevState) => {
      return {
        showDelete: !prevState.showDelete,
      };
    });
  };

  validateForm = () => {
    // reset form errors
    this.setState({
      formErrors: {},
    });

    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    if (result.error) {
      const formErrors = processJoiError(result.error);
      this.setState({
        // Assume type based on formSchema and Joi's error
        formErrors: formErrors,
      });

      return false;
    }

    return true;
  };

  validateFormField = (field: string) => {
    const result = this.formSchema.validate(this.state.formData, {
      abortEarly: false,
    });
    let errorMessage = '';

    if (result.error) {
      errorMessage = processJoiFieldError(result.error, field);
    }

    this.updateFormError(field, errorMessage);
  };

  updateFormError<K extends keyof FormData>(field: string, value: FormData[K]) {
    this.setState((prevState) => {
      return {
        formErrors: {
          ...prevState.formErrors,
          [field]: value,
        },
      };
    });
  }

  updateForm<K extends keyof FormData>(field: K, value: FormData[K]) {
    const formData = this.state.formData;
    this.setState(
      {
        formData: {
          ...formData,
          [field]: value,
        },
      },
      () => {
        this.validateFormField(field);
      },
    );
  }

  setBoardName = (ev: ChangeEvent<HTMLInputElement>) =>
    this.updateForm('boardName', ev.target.value);

  handleRestore = async () => {
    this.setState({
      restoreStatus: 'loading',
    });

    try {
      await restoreBoard(String(this.props.id));

      this.setState({
        restoreStatus: 'success',
      });

      this.props.removeFromArchive(this.props.id);
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];

      this.setState({
        restoreStatus: 'error',
        serverErrors: error,
      });
    }
  };

  handleDeleteBoard = async () => {
    const isValid = this.validateForm();

    if (isValid) {
      this.setState({
        deleteStatus: 'loading',
      });

      try {
        await deleteBoard(String(this.props.id));

        this.setState({
          deleteStatus: 'success',
        });

        this.props.removeFromArchive(this.props.id);
      } catch (err) {
        const error = Array.isArray(err) ? err : [err];

        this.setState({
          deleteStatus: 'error',
          serverErrors: error,
        });
      }
    }
  };

  handleRestoreBoard = async () => {
    try {
      await restoreBoard(String(this.props.id));
      this.props.removeFromArchive(this.props.id);
    } catch (err) {
      console.debug(err);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className="card py-xs pl-xs">
        <div className="flex-v-center flex-h-spread">
          <div className="flex-v-center fill">
            <div
              className={[
                'thumbnail-frame-component small',
                this.props.color ? `accent-${this.props.color}` : '',
              ].join(' ')}
            >
              <Thumbnail
                thumbnailData={this.props.thumbnail}
                classes={'radius size-48'}
                title={this.props.name}
              />
            </div>
            <p className="multiline-ellipsis text-sm l2 mb-0 ml-xs">
              <h3 className="text-sm text-400">{this.props.name}</h3>
              <span className="faint-text text-xs">
                {this.props.description}
              </span>
            </p>
          </div>
          <ul className="control-list-component mb-0 align-h-end">
            <ContextMenu
              dept={0}
              title={t('contextMenuTitle')}
              triggerContent={
                <>
                  <span className="fas fa-ellipsis-h"></span>
                </>
              }
              triggerClassDefault="ghost-button"
              triggerClassActive="secondary-button"
            >
              <li>
                <Button
                  className="ghost-button"
                  onClick={this.handleRestoreBoard}
                  disabled={
                    this.state.restoreStatus === 'loading' || !this.props.owned
                  }
                >
                  <RequestStatus status={this.state.restoreStatus} />
                  <span className="fal fa-archive icon"></span>
                  <span className="text">{t('restoreButton')}</span>
                </Button>
              </li>
              <li>
                <hr />
              </li>
              <li>
                <Button
                  className="ghost-button"
                  onClick={this.toggleShowDelete}
                  disabled={
                    this.state.restoreStatus === 'loading' || !this.props.owned
                  }
                >
                  <RequestStatus status={this.state.restoreStatus} />
                  <span className="fal fa-trash icon"></span>
                  <span className="text">{t('deleteButton')}</span>
                </Button>
              </li>
            </ContextMenu>
          </ul>
        </div>
        {this.state.showDelete && (
          <Dialog
            title={t('deleteDialogTitle')}
            onCancel={this.toggleShowDelete}
            onConfirm={this.handleDeleteBoard}
            confirmText={t('confirmText')}
            cancelText={t('cancelText')}
            message={
              <>
                <form noValidate={true}>
                  <p>
                    <span className="negative-text fas fa-exclamation-circle icon">
                      <span className="sr-only">{t('dangerIconSR')}</span>
                    </span>{' '}
                    <Trans
                      i18nKey="boardCardArchived:deleteWarning"
                      values={{
                        board: this.props.name,
                      }}
                      components={[<strong key="1"></strong>]}
                    />
                  </p>
                  <TextBox
                    label={t('boardNameLabel')}
                    type="text"
                    name="boardName"
                    id="boardName"
                    onChange={this.setBoardName}
                    required={true}
                    br={true}
                    error={this.state.formErrors.boardName}
                    autoFocus={true}
                    autoComplete="off"
                    ariaAutocomplete="none"
                    formGroupClassNames="mb-0"
                  />
                </form>
              </>
            }
          />
        )}
      </div>
    );
  }
}

export default withStyledTranslation('boardCardArchived')(BoardCardArchived);
