import React from 'react';
import {
  ActivityDTO,
  ActivityAddRemoveSubtaskDTO,
  ActivityUpdateSubtaskDTO,
} from '../../../../common/api/dtos/Activity';
import {
  ActivityAssignTagDTO,
  ActivityAssignUserDTO,
  ActivityAtCardCreationDTO,
  ActivityMoveCardDTO,
  ActivityRestoreCardDTO,
  ActivityUnassignTagDTO,
  ActivityUnassignUserDTO,
  ActivityUpdatePriorityDTO,
  ActivityUpdateTitleDTO,
  ActivityAddAttachmentDTO,
  ActivityRemoveAttachmentDTO,
} from '../../../../common/api/dtos/Activity';
import { THistory } from '../../../../common/types/Activity';
import { CommentDTO } from '../../../../common/api/dtos/Comment';
import { InviteeDTO, MemberDTO } from '../../../../common/api/dtos/Member';
import { TagDTO } from '../../../../common/api/dtos/Tag';
import { ColumnDTO } from '../../../../common/api/dtos/Column';
import Thumbnail from '../../Thumbnail/Thumbnail';
import UserComment from './UserComment';
import { IBoardCurrentUser } from '../../../../common/interfaces/BoardCurrentUser';
import { MsgWs } from '../../../pages/Board/BoardProvider/wsHandlers';
import { PriorityDTO } from '../../../../common/api/dtos/Priority';
import { GroupedActivityItem, IActivity, IComment } from './CardFlyout';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import { AttachmentDTO } from '../../../../common/api/dtos/Card';
import getTime from '../../../../common/helpers/getTime';
import Button from '../../../controls/Button';
import { Trans, WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../StyledTranslation/StyledTranslation';
import AppContext from '../../../../common/contexts/AppContext';
import MessageBar from '../../../controls/MessageBar';

interface Props extends WithTranslation {
  disabled: boolean;
  members: (MemberDTO | InviteeDTO)[];
  tags: TagDTO[];
  columnTitles: Partial<ColumnDTO>[];
  boardCard: BoardCard;
  boardUser: IBoardCurrentUser;
  reachedBottom: boolean;
  resetReachedBottom: () => void;
  addWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  removeWsListener: (code: string, callback: (message: MsgWs) => void) => void;
  priorities: PriorityDTO[];
  groupedActivityItems: GroupedActivityItem[];
  activityStatus: TRequestStatus;
  updateComment: (commentData: Partial<CommentDTO>) => void;
  historyType: THistory;
  setHistoryType: (historyType: THistory) => void;
  attachments: AttachmentDTO[];
  removeComment: (commentData: Partial<CommentDTO>) => void;
  handleDeleteComment?: () => void;
  groupedActivities: GroupedActivityItem[];
  groupedComments: GroupedActivityItem[];
}

export interface BoardCard {
  id: string;
  title: string;
  number: number;
  assigneeIds: string[];
  description: string;
  tagIds: string[];
  priorityId: string | null;
}

class CardActivity extends React.Component<Props> {
  getMemberName = (id: string | undefined) => {
    const member = this.props.members.find((m) => m.id === id);
    if (member) {
      if (member.name) {
        return member.name;
      } else if ((member as InviteeDTO).email) {
        return (member as InviteeDTO).email;
      }
    } else {
      return 'Unknown';
    }
  };

  getMemberAvatar = (id: string) => {
    const member = this.props.members.find((m) => m.id === id);

    return member && member.avatar;
  };

  getColumnTitle = (id: string) => {
    const column = this.props.columnTitles.find((c) => c.id === id);

    return column ? column.title : undefined;
  };

  getTagName = (id: string) => {
    const tag = this.props.tags.find((t) => t.id === id);

    return tag ? tag.name : 'Unknown';
  };

  getAttachmentName = (id: string) => {
    const attachment = this.props.attachments.find((a) => a.id === id);

    return attachment ? attachment.filename : 'Unknown';
  };

  renderActivitiesAndComments = (groupedItem: GroupedActivityItem) => {
    return (
      <div
        className="flex-row fill reveal-up-1"
        key={groupedItem.date}
      >
        <div className="column pb-2xs">
          <div className="flex-row">
            <div className="column">
              <small className="faint-text">{groupedItem.date}</small>
            </div>
          </div>
          {groupedItem.items.map((item: IActivity | IComment) => {
            return (
              <div
                className={`flex-row no-reflow squeeze flex-v-top`}
                key={item.key}
              >
                <div className="column pt-0 pr-0 pb-2xs">
                  {this.renderAvatar(item)}
                </div>
                <div className="column pt-2xs pb-2xs fill">
                  {this.renderMessageByType(item)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderNoActivitiesOrComments = () => {
    const { t } = this.props;
    switch (this.props.historyType) {
      case 'all':
        if (
          !this.props.groupedActivities.length &&
          this.props.activityStatus === 'success'
        )
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noEntries')}</span>
            </div>
          );
        break;
      case 'activity':
        if (
          !this.props.groupedActivities.length &&
          this.props.activityStatus === 'success'
        )
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noActivity')}</span>
            </div>
          );
        break;
        break;
      case 'comments':
        if (
          !this.props.groupedComments.length &&
          this.props.activityStatus === 'success'
        )
          return (
            <div className="mt-xs card opaque">
              <span className="text-xs faint-text">{t('noComments')}</span>
            </div>
          );
    }
  };

  renderMessageByType = (item: ActivityDTO | CommentDTO) => {
    const { t } = this.props;
    const timeFormat: number =
      this.context.loggedUser.accountSettings.timeFormat ?? 12;
    if (
      !Object.prototype.hasOwnProperty.call(item, 'kind') &&
      Object.prototype.hasOwnProperty.call(item, 'content') &&
      (item as CommentDTO).content.length > 0
    ) {
      return (
        <>
          <p className="mb-2xs">
            <Trans
              i18nKey="cardActivity:commentWritten"
              values={{
                time: getTime(item.createdAt, timeFormat),
                author: this.getMemberName(item.authorId),
                edited:
                  item.createdAt !== (item as CommentDTO).updatedAt
                    ? t('edited')
                    : '',
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
                <span key="2"></span>,
              ]}
            />
          </p>
          <div>
            <UserComment
              disabled={this.props.disabled}
              boardCard={this.props.boardCard}
              boardUser={this.props.boardUser}
              comment={item as CommentDTO}
              updateComment={this.props.updateComment}
              removeComment={this.props.removeComment}
              handleDeleteComment={this.props.handleDeleteComment}
              members={this.props.members}
              cardAttachments={this.props.attachments}
            />
          </div>
        </>
      );
    } else {
      if (
        !Object.prototype.hasOwnProperty.call(item, 'kind') &&
        Object.prototype.hasOwnProperty.call(item, 'deletedAt')
      ) {
        if (
          this.getMemberName((item as CommentDTO).deletedBy) ===
          this.getMemberName((item as CommentDTO).authorId)
        ) {
          return (
            <p>
              <Trans
                i18nKey="cardActivity:commentRemovedByUser"
                values={{
                  time: getTime(item.createdAt, timeFormat),
                  user:
                    this.getMemberName((item as CommentDTO).deletedBy) ??
                    'Unknown',
                }}
                components={[
                  <small
                    key="0"
                    className="faint-text"
                  ></small>,
                  <strong key="1"></strong>,
                ]}
              />
            </p>
          );
        }
        return (
          <p>
            <Trans
              i18nKey="cardActivity:removedComment"
              values={{
                time: getTime(item.createdAt, timeFormat),
                deleter: this.getMemberName((item as CommentDTO).deletedBy),
                author: this.getMemberName(item.authorId) ?? 'Unknown',
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>, // Placeholder <0>
                <strong key="2"></strong>, // Placeholder <1>
                <strong key="3"></strong>, // Placeholder <2>
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'create') {
        const data = (item as ActivityDTO).data as ActivityAtCardCreationDTO;
        const assignedTags = data.tagIds
          .map((id) => this.getTagName(id))
          .join(', ');
        const assignedMembers = data.assigneeIds
          .map((id) => this.getMemberName(id))
          .join(', ');

        const tagsPart =
          assignedTags.length > 0
            ? this.props.t('withTags', { tags: assignedTags })
            : '';
        const membersPart =
          assignedMembers.length > 0
            ? this.props.t('withMembers', { members: assignedMembers })
            : '';
        const priorityPart = data.priorityName
          ? this.props.t('withPriority', { priority: data.priorityName })
          : '';

        return (
          <p>
            <Trans
              i18nKey="cardActivity:activityCardCreated"
              values={{
                time: getTime(item.createdAt, timeFormat),
                user: this.getMemberName(item.authorId),
                title: ((item as ActivityDTO).data as ActivityAtCardCreationDTO)
                  .title,
                column: this.getColumnTitle(
                  ((item as ActivityDTO).data as ActivityAtCardCreationDTO)
                    .columnId,
                ),
                tags: tagsPart,
                priority: priorityPart,
                members: membersPart,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>,
                <strong key="2"></strong>,
                <strong key="3"></strong>,
                <strong key="4"></strong>,
                assignedTags.length > 0 ? <strong key="5"></strong> : <></>,
                ((item as ActivityDTO).data as ActivityAtCardCreationDTO)
                  .priorityId ? (
                  <strong key="6"></strong>
                ) : (
                  <></>
                ),
                assignedMembers.length > 0 ? <strong key="7"></strong> : <></>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'update_title') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:titleChanged"
              values={{
                time: getTime(item.createdAt, timeFormat),
                user: this.getMemberName(item.authorId),
                newTitle: ((item as ActivityDTO).data as ActivityUpdateTitleDTO)
                  .title,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>,
                <strong key="2"></strong>,
                <strong key="3"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'add_subtask') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:subtaskAdded"
              values={{
                time: getTime(item.createdAt, timeFormat),
                author: this.getMemberName(item.authorId),
                title: (
                  (item as ActivityDTO)
                    .data as unknown as ActivityAddRemoveSubtaskDTO
                ).subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>,
                <strong key="2"></strong>,
                <strong key="3"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'update_subtask') {
        return (
          <p>
            <Trans
              i18nKey={
                (
                  (item as ActivityDTO)
                    .data as unknown as ActivityUpdateSubtaskDTO
                ).subtaskChecked == true
                  ? 'cardActivity:subtaskComplete'
                  : 'cardActivity:subtaskIncomplete'
              }
              values={{
                time: getTime(item.createdAt, timeFormat),
                author: this.getMemberName(item.authorId),
                title: (
                  (item as ActivityDTO)
                    .data as unknown as ActivityUpdateSubtaskDTO
                ).subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>,
                <strong key="2"></strong>,
                <strong key="3"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'remove_subtask') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:subtaskRemoved"
              values={{
                time: getTime(item.createdAt, timeFormat),
                author: this.getMemberName(item.authorId),
                title: (
                  (item as ActivityDTO)
                    .data as unknown as ActivityAddRemoveSubtaskDTO
                ).subtaskTitle,
              }}
              components={[
                <small
                  key="1"
                  className="faint-text"
                ></small>,
                <strong key="2"></strong>,
                <strong key="3"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO as ActivityDTO).kind === 'update_description') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:descriptionChanged"
              values={{
                time: getTime(item.createdAt, timeFormat),
                changer: this.getMemberName(item.authorId),
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
              ]}
            />{' '}
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'update_priority') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:priorityChanged"
              values={{
                time: getTime(item.createdAt, timeFormat),
                changer: this.getMemberName(item.authorId),
                priority:
                  ((item as ActivityDTO).data as ActivityUpdatePriorityDTO)
                    .priorityName || t('none'),
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
                <strong key="2"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'archive') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardArchived"
              values={{
                time: getTime(item.createdAt, timeFormat),
                archiver: this.getMemberName(item.authorId),
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'restore') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardRestored"
              values={{
                time: getTime(item.createdAt, timeFormat),
                restorer: this.getMemberName(item.authorId),
                column: ((item as ActivityDTO).data as ActivityRestoreCardDTO)
                  .columnTitle,
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
                <strong key="2"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'move') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:cardMoved"
              values={{
                time: getTime(item.createdAt, timeFormat),
                mover: this.getMemberName(item.authorId),
                column: ((item as ActivityDTO).data as ActivityMoveCardDTO)
                  .columnTitle,
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
                <strong key="2"></strong>,
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'assigne_user') {
        return (
          <p>
            {item.authorId ===
            ((item as ActivityDTO).data as ActivityAssignUserDTO).memberId ? (
              <Trans
                i18nKey="cardActivity:userAssignedSelfToCard"
                values={{
                  time: getTime(item.createdAt, timeFormat),
                  assigner: this.getMemberName(item.authorId),
                }}
                components={[
                  <small
                    className="faint-text"
                    key="1"
                  ></small>, // <0>
                  <strong key="2"></strong>, // <1>
                ]}
              />
            ) : (
              <Trans
                i18nKey="cardActivity:userAssignedToCard"
                values={{
                  time: getTime(item.createdAt, timeFormat),
                  assigner: this.getMemberName(item.authorId),
                  assignee: this.getMemberName(
                    ((item as ActivityDTO).data as ActivityAssignUserDTO)
                      .memberId,
                  ),
                }}
                components={[
                  <small
                    className="faint-text"
                    key="1"
                  ></small>, // <0>
                  <strong key="2"></strong>, // <1>
                  <strong key="3"></strong>, // <2>
                ]}
              />
            )}
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'unassigne_user') {
        return (
          <p>
            {item.authorId ===
            ((item as ActivityDTO).data as ActivityAssignUserDTO).memberId ? (
              <Trans
                i18nKey="cardActivity:userUnassignedSelfFromCard"
                values={{
                  time: getTime(item.createdAt, timeFormat),
                  assigner: this.getMemberName(item.authorId),
                }}
                components={[
                  <small
                    className="faint-text"
                    key="1"
                  ></small>, // <0>
                  <strong key="2"></strong>, // <1>
                ]}
              />
            ) : (
              <Trans
                i18nKey="cardActivity:userUnassignedFromCard"
                values={{
                  time: getTime(item.createdAt, timeFormat),
                  unassigner: this.getMemberName(item.authorId),
                  unassigned: this.getMemberName(
                    ((item as ActivityDTO).data as ActivityUnassignUserDTO)
                      .memberId,
                  ),
                }}
                components={[
                  <small
                    className="faint-text"
                    key="1"
                  ></small>, // <0>
                  <strong key="2"></strong>, // <1>
                  <strong key="3"></strong>, // <2>
                ]}
              />
            )}
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'assigne_tag') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:tagAddedToCard"
              values={{
                time: getTime(item.createdAt, timeFormat),
                assigner: this.getMemberName(item.authorId),
                tagName: ((item as ActivityDTO).data as ActivityAssignTagDTO)
                  .tagName,
              }}
              components={[
                <small
                  className="faint-text"
                  key="1"
                ></small>, // <0>
                <strong key="2"></strong>, // <1>
                <strong key="3"></strong>, // <2>
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'unassigne_tag') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:tagRemovedFromCard"
              values={{
                time: getTime(item.createdAt, timeFormat),
                remover: this.getMemberName(item.authorId),
                tagName: ((item as ActivityDTO).data as ActivityUnassignTagDTO)
                  .tagName,
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>, // <0>
                <strong key="1"></strong>, // <1>
                <strong key="2"></strong>, // <2>
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'add_attachment') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:attachmentAdded"
              values={{
                time: getTime(item.createdAt, timeFormat),
                adder: this.getMemberName(item.authorId),
                attachmentFilename: (
                  (item as ActivityDTO).data as ActivityAddAttachmentDTO
                ).attachmentFilename,
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>, // <0>
                <strong key="1"></strong>, // <1>
                <strong key="2"></strong>, // <2>
              ]}
            />
          </p>
        );
      }
      if ((item as ActivityDTO).kind === 'remove_attachment') {
        return (
          <p>
            <Trans
              i18nKey="cardActivity:attachmentRemoved"
              values={{
                time: getTime(item.createdAt, timeFormat),
                remover: this.getMemberName(item.authorId),
                attachmentFilename: (
                  (item as ActivityDTO).data as ActivityRemoveAttachmentDTO
                ).attachmentFilename,
              }}
              components={[
                <small
                  className="faint-text"
                  key="0"
                ></small>,
                <strong key="1"></strong>,
                <strong key="2"></strong>,
              ]}
            />
          </p>
        );
      }
    }
  };

  renderAvatar = (item: ActivityDTO | CommentDTO) => {
    const authorId =
      (item as CommentDTO).deletedBy || (item as ActivityDTO).authorId;

    return (
      <Thumbnail
        classes="size-24 icon"
        avatarData={this.getMemberAvatar(authorId)}
        title={this.getMemberName(authorId)}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="flex-row fill">
          <div className="column py-0">
            <div className="flex-row squeeze">
              <div className="column py-0">
                <div className="navigation-component horizontal">
                  <ul className="nav-list">
                    <li className="nav-li pl-0">
                      <Button
                        onClick={() => this.props.setHistoryType('all')}
                        className={`nav-link  ${
                          this.props.historyType === 'all' ? 'active' : ''
                        }`}
                      >
                        <div className="content-wrapper pt-2xs pb-3xs">
                          {t('allHistory')}
                        </div>
                      </Button>
                    </li>
                    <li className="nav-li">
                      <Button
                        onClick={() => this.props.setHistoryType('activity')}
                        className={`nav-link  ${
                          this.props.historyType === 'activity' ? 'active' : ''
                        }`}
                      >
                        <div className="content-wrapper pt-2xs pb-3xs">
                          {t('activity')}
                        </div>
                      </Button>
                    </li>
                    <li className="nav-li">
                      <Button
                        onClick={() => this.props.setHistoryType('comments')}
                        className={`nav-link  ${
                          this.props.historyType === 'comments' ? 'active' : ''
                        }`}
                      >
                        <div className="content-wrapper pt-2xs pb-3xs">
                          {t('comments')}
                        </div>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-break-word">
          {this.renderNoActivitiesOrComments()}
          {this.props.historyType === 'all' &&
            this.props.groupedActivityItems.map(
              (groupedItem: GroupedActivityItem) => {
                return this.renderActivitiesAndComments(groupedItem);
              },
            )}

          {this.props.historyType === 'activity' &&
            this.props.groupedActivities.map(
              (groupedItem: GroupedActivityItem) => {
                return this.renderActivitiesAndComments(groupedItem);
              },
            )}

          {this.props.historyType === 'comments' &&
            this.props.groupedComments.map(
              (groupedItem: GroupedActivityItem) => {
                return this.renderActivitiesAndComments(groupedItem);
              },
            )}

          {this.props.activityStatus === 'loading' && (
            <div className="flex-v-center py-sm">
              <p>
                <span className="loader"></span>{' '}
                <span className="ml-xs">{t('loadingEntries')}</span>
              </p>
            </div>
          )}
          {this.props.activityStatus === 'error' && (
            <div className="py-sm">
              <MessageBar
                type="danger"
                icon="exclamation-triangle accent-text-red"
              >
                <span className="fas fa-exclamation-triangle"></span>{' '}
                <span>{t('problemFetchingErrors')}</span>
              </MessageBar>
            </div>
          )}
          {(this.props.activityStatus === 'success' ||
            this.props.activityStatus === 'idle') && (
            <div
              className="flex-v-center py-sm"
              style={{ visibility: 'hidden' }}
            >
              <p>
                <span className="loader"></span>{' '}
                <span className="ml-xs">&nbsp;</span>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('cardActivity')(CardActivity);
CardActivity.contextType = AppContext;
