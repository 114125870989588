import React, { useState, useEffect, useRef, useCallback } from 'react';

interface RootScrollbarProps {
  offsetY?: number;
}

const RootScrollbar = ({ offsetY = 0 }: RootScrollbarProps) => {
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbTop, setThumbTop] = useState(0);
  const [thumbWidth, setThumbWidth] = useState(0);
  const [thumbLeft, setThumbLeft] = useState(0);
  const [isVVisible, setIsVVisible] = useState(true);
  const [isHVisible, setIsHVisible] = useState(true);
  const isDraggingV = useRef(false);
  const isDraggingH = useRef(false);
  const startY = useRef(0);
  const startX = useRef(0);

  const handleMouseDownV = (e: React.MouseEvent) => {
    e.preventDefault();
    isDraggingV.current = true;
    startY.current = e.clientY;
  };

  const handleMouseDownH = (e: React.MouseEvent) => {
    e.preventDefault();
    isDraggingH.current = true;
    startX.current = e.clientX;
  };

  const updateThumb = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop, scrollLeft } =
      document.documentElement;
    const bodyScrollHeight = document.body.scrollHeight;
    const bodyScrollWidth = document.body.scrollWidth;
    const clientWidth = document.body.clientWidth;
    const scrollWidth = document.body.scrollWidth;
    const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
    const thumbTop = (scrollTop / scrollHeight) * clientHeight;
    const thumbWidth = (clientWidth / scrollWidth) * clientWidth;
    const thumbLeft = (scrollLeft / scrollWidth) * clientWidth;
    setThumbHeight(thumbHeight);
    setThumbTop(thumbTop);
    setThumbWidth(thumbWidth);
    setThumbLeft(thumbLeft);
    setIsVVisible(
      scrollHeight > clientHeight || bodyScrollHeight > clientHeight,
    );
    setIsHVisible(scrollWidth > clientWidth || bodyScrollWidth > clientWidth);
  }, []);

  useEffect(() => {
    const handleMutation = () => {
      updateThumb();
    };

    const observer = new MutationObserver(handleMutation);
    observer.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const bodyObserver = new MutationObserver(handleMutation);
    bodyObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    const handleMouseMove = (e: MouseEvent) => {
      if (isDraggingV.current) {
        const deltaY = e.clientY - startY.current;
        document.documentElement.scrollTop +=
          (deltaY / document.documentElement.clientHeight) *
          document.documentElement.scrollHeight;
        startY.current = e.clientY;
      }
      if (isDraggingH.current) {
        const deltaX = e.clientX - startX.current;
        document.documentElement.scrollLeft +=
          (deltaX / document.documentElement.clientWidth) *
          document.documentElement.scrollWidth;
        startX.current = e.clientX;
      }
    };

    const handleMouseUp = () => {
      isDraggingV.current = false;
      isDraggingH.current = false;
    };

    window.addEventListener('scroll', updateThumb);
    window.addEventListener('resize', updateThumb);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    updateThumb();

    return () => {
      observer.disconnect();
      bodyObserver.disconnect();
      window.removeEventListener('scroll', updateThumb);
      window.removeEventListener('resize', updateThumb);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [updateThumb]);

  return (
    <>
      {isVVisible && (
        <div className="root-scrollbar-track vertical">
          <div
            className="root-scrollbar-thumb"
            style={{ height: thumbHeight - offsetY, top: thumbTop + offsetY }}
            onMouseDown={handleMouseDownV}
          ></div>
        </div>
      )}
      {isHVisible && (
        <div className="root-scrollbar-track horizontal">
          <div
            className="root-scrollbar-thumb"
            style={{ width: thumbWidth, left: thumbLeft }}
            onMouseDown={handleMouseDownH}
          ></div>
        </div>
      )}
    </>
  );
};

export default RootScrollbar;
