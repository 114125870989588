import React, { forwardRef, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  className?: string;
  activeClassName?: string;
  to: string;
  disabled?: boolean;
  target?: string;
  isRoute?: boolean;
  id?: string;
  tabIndex?: number;
  value?: string;
  styles?: React.CSSProperties;
  title?: string;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLAnchorElement | HTMLButtonElement | HTMLElement
  >;
  children?: React.ReactNode;
}

const LinkButton = forwardRef<
  HTMLAnchorElement | HTMLButtonElement | HTMLElement,
  Props
>(
  (
    {
      className = '',
      activeClassName = '',
      to,
      disabled,
      target,
      isRoute = true,
      id,
      tabIndex,
      styles,
      title,
      onKeyDown = () => false,
      children,
    },
    ref,
  ) => {
    const combinedClass = `${className}${disabled ? ' disabled' : ''}`;

    const preventDrag = useCallback(
      (e: React.DragEvent) => e.preventDefault(),
      [],
    );

    if (disabled) {
      return (
        <div
          title={title}
          className={combinedClass}
          id={id}
          tabIndex={tabIndex}
          style={styles}
          onKeyDown={onKeyDown}
        >
          {children}
        </div>
      );
    }

    return isRoute ? (
      <NavLink
        to={to}
        title={title}
        target={target}
        ref={ref as React.Ref<HTMLAnchorElement>}
        className={(isActive: boolean) =>
          `${combinedClass}${isActive && activeClassName ? ` ${activeClassName}` : ''}`
        }
        id={id}
        tabIndex={tabIndex}
        style={styles}
        onKeyDown={onKeyDown}
        onDragStart={preventDrag}
      >
        {children}
      </NavLink>
    ) : (
      <a
        href={to}
        title={title}
        target={target}
        ref={ref as React.Ref<HTMLAnchorElement>}
        className={combinedClass}
        id={id}
        tabIndex={tabIndex}
        style={styles}
        onKeyDown={onKeyDown}
        onDragStart={preventDrag}
      >
        {children}
      </a>
    );
  },
);

// Add displayName for better debugging and ESLint compliance
LinkButton.displayName = 'LinkButton';

export default LinkButton;
