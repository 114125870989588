import React, { ChangeEvent, MouseEvent } from 'react';
import Button from '../../controls/Button';
import { CARD_FILTER_LABELS } from '../../../common/configs/CardFilterLabels';
import {
  CardFilterField,
  CARD_FILTER_FIELDS,
} from '../../../common/contexts/BoardContext';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import AppContext from '../../../common/contexts/AppContext';

interface Props extends WithTranslation {
  initialValue: string | null;
  fields: CardFilterField[];
  filteredCount?: number;
  filter: (value: string | null, fields: CardFilterField[]) => void;
  addOrRemoveField?: (field: string) => void;
  resetFields?: () => void;
}

interface State {
  value: string;
}

class CardFilterMenu extends React.Component<Props, State> {
  timeoutId: number | null;
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.props.initialValue || '',
    };

    this.timeoutId = null;
    this.inputRef = React.createRef();
  }

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // clear old timeout
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    // set new timeout
    const timeoutId = setTimeout(() => {
      this.props.filter(value, this.props.fields);
    }, 500);
    this.timeoutId = timeoutId as unknown as number;

    this.setState({ value });
  };

  clear = () => {
    // clear old timeout
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    // forcefully clear the filter
    this.props.filter('', this.props.fields);
    this.inputRef.current?.focus();

    this.setState({ value: '' });
  };

  selectField = (event: MouseEvent<HTMLButtonElement>) => {
    const field = event.currentTarget.value as CardFilterField;
    const newFields = this.getFields().filter((f) => f !== field);

    // if the field wasn't removed, add it
    if (newFields.length === this.getFields().length) {
      newFields.push(field);
    }

    this.props.filter(this.state.value, newFields);
    this.props.addOrRemoveField?.(field);
  };

  selectAllFields = () => {
    this.props.filter(this.state.value, [...CARD_FILTER_FIELDS]);
    this.props.resetFields?.();
  };

  selectAssignedToMe = () => {
    this.setState({ value: this.context.loggedUser.name });
    this.props.filter(this.context.loggedUser.name, ['member']);
  };

  getFields() {
    return this.props.fields || [];
  }

  isWrappedInQuotes(text: string) {
    return text.startsWith('"') && text.endsWith('"');
  }

  render() {
    const { t } = this.props;
    const filterFields = this.getFields();
    return (
      <>
        <li>
          <input
            type="text"
            placeholder={t('typeToFilter')}
            onChange={this.handleInput}
            value={this.state.value}
            ref={this.inputRef}
            autoComplete="off"
            aria-autocomplete="none"
          />
          {this.state.value !== '' && (
            <li>
              <button
                className="secondary-button ml-xs"
                onClick={this.clear}
              >
                {t('clear')}
              </button>
            </li>
          )}
        </li>
        <li className="pt-2xs">
          {this.props.filteredCount === 0 &&
          this.isWrappedInQuotes(this.state.value) ? (
            <p className="text-xs normal-wrap faint-text">
              <span className="fas fa-info-circle"></span>{' '}
              <span>{t('noResult')}</span>
            </p>
          ) : (
            <p className="text-xs normal-wrap faint-text">
              <span className="fas fa-info-circle"></span>{' '}
              <span>{t('note')}</span>
            </p>
          )}
        </li>
        <li>
          <hr />
        </li>
        <li>
          <ul className="control-list-component horizontal flex-h-spread">
            <li>
              <p className="text-sm">
                <span>{t('filterThrough')}</span>
              </p>
            </li>
            <li>
              <Button
                className={['link-button'].join(' ')}
                onClick={this.selectAllFields}
              >
                <span className="text">{t('reset')}</span>
              </Button>{' '}
            </li>
          </ul>
        </li>

        <li>
          <ul className="control-list-component horizontal">
            {CARD_FILTER_FIELDS.map((field: CardFilterField) => {
              const isActive = filterFields.includes(field);

              return (
                <li
                  className={[isActive ? 'active' : ''].join(' ')}
                  key={field}
                >
                  <Button
                    className={[
                      isActive ? 'active ghost-button' : 'ghost-button',
                    ].join(' ')}
                    value={field}
                    onClick={this.selectField}
                  >
                    <span className="text capitalized">
                      {t(CARD_FILTER_LABELS[field])}
                    </span>
                  </Button>
                </li>
              );
            })}
          </ul>
        </li>
        <li>
          <hr />
        </li>
        <li>
          <ul className="control-list-component horizontal flex-h-spread">
            <li>
              <p className="text-sm">
                <span>{t('quickFilters')}</span>
              </p>
            </li>
            <li>
              <Button
                className={['link-button'].join(' ')}
                onClick={this.selectAssignedToMe}
              >
                <span className="text">{t('assignedToMe')}</span>
              </Button>{' '}
            </li>
          </ul>
        </li>
      </>
    );
  }
}

export default withStyledTranslation('cardFilterMenu')(CardFilterMenu);
CardFilterMenu.contextType = AppContext;
