import {
  DraggableStateSnapshot,
  DraggingStyle,
  NotDraggingStyle,
} from '@wavemyth/react-beautiful-dnd';

export function getDropStyle(
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot,
) {
  if (!snapshot) return;

  if (!snapshot.isDropAnimating) {
    return style;
  }

  // Extract current transition properties if they exist
  const transitionProps = style?.transition ? style.transition.split(',') : [];
  const otherTransitionProps = transitionProps
    .filter((prop: any) => !prop.includes('ms') && !prop.includes('s'))
    .join(',');

  return {
    ...style,
    marginBottom: '30px',
    // Apply only the duration separately to avoid conflicts
    transitionProperty: otherTransitionProps || 'transform',
    transitionDuration: `0.1s`,
  };
}
