import React, { useContext, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { IDirectionalButton } from '../../common/interfaces/DirectionalButton';
import AppContext from '../../common/contexts/AppContext';

interface Props {
  directionalButton: IDirectionalButton;
  className: string;
}

const DirectionalButton: React.FC<Props> = ({
  directionalButton,
  className,
}) => {
  const context = useContext(AppContext);
  const { disabled, direction, text, onClick } = directionalButton;

  // Memoized ID computation
  const directionalButtonId = useMemo(() => {
    return `#${text.toLowerCase().replaceAll(' ', '-')}-directional-button`;
  }, [text]);

  const handleClick = useCallback(() => {
    onClick();
    if (direction === 'right') {
      // New id is only set when moving forward
      context?.setDirectionalButtonId?.(directionalButtonId);
    }
  }, [onClick, direction, context, directionalButtonId]);

  return (
    <button
      disabled={disabled}
      className={classNames('ghost-button fill', className)}
      onClick={!disabled ? handleClick : undefined}
      id={directionalButtonId}
      aria-label={`${direction} directional button`}
    >
      {direction === 'left' && (
        <span className={`icon fas fa-angle-${direction}`}></span>
      )}
      <span className="text">{text}</span>
      {direction === 'right' && (
        <span className={`icon fas fa-angle-${direction}`}></span>
      )}
    </button>
  );
};

export default DirectionalButton;
