import React, { ChangeEvent } from 'react';

interface Props {
  id: string;
  name: string;
  changeMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  labelText?: string;
  isDisabled?: boolean;
  icon?: string;
  value?: string;
  isChecked?: boolean;
  multiline?: boolean;
  tight?: boolean;
  classes?: string;
  srOnly?: boolean;
  title?: string;
  children?: JSX.Element;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  labelClassName?: string;
}

const ToggleSwitch: React.FC<Props> = React.memo(
  ({
    id,
    name,
    changeMethod,
    labelText,
    isDisabled,
    icon,
    value,
    isChecked = false,
    multiline,
    tight,
    classes,
    srOnly,
    title,
    children,
    onBlur,
    labelClassName,
  }) => {
    return (
      <div className={`form-group ${tight ? 'mb-0' : ''}`}>
        <div
          className={`toggle ${classes} ${multiline ? 'multiline' : ''}`}
          title={title}
        >
          <input
            id={id}
            type="checkbox"
            name={name}
            onChange={changeMethod}
            disabled={isDisabled}
            value={value}
            checked={isChecked}
            onBlur={onBlur}
          />
          {!multiline ? (
            <label
              htmlFor={id}
              className={labelClassName}
            >
              {srOnly && <span className="sr-only">{labelText}</span>}
              {icon && <span className={icon}></span>}
              {!srOnly && <span>{labelText}</span>}
            </label>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    );
  },
);

export default ToggleSwitch;

ToggleSwitch.displayName = 'ToggleSwitch';
