import React, { FormEvent } from 'react';
import Button from '../../controls/Button';
import PriorityForm from '../../forms/PriorityForm/PriorityForm';
import { AddPriorityDTO, PriorityDTO } from '../../../common/api/dtos/Priority';
import {
  createBoardPriority,
  deletePriority,
  updatePriority,
} from '../../../common/api/endpoints/priority';
import AppContext, { IAppContext } from '../../../common/contexts/AppContext';
import { NotificationMessage } from '../../../common/contexts/NotificationsContext';
import { IDirectionalButton } from '../../../common/interfaces/DirectionalButton';
import DirectionalButton from '../../controls/DirectionalButton';
import { showErrorNotifications } from '../../../common/helpers/showNotifications';
import { TContextMenu } from '../../../common/types/ContextMenu';
import { withContextAdapters } from '../../partials/ContextAdapter/withContextAdapter';
import { UserDTO } from '../../../common/api/dtos/User';
import BoardContext from '../../../common/contexts/BoardContext';
import { withStyledTranslation } from '../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Dialog from '../../controls/Dialog';
import {
  IBoardContext,
  UpdatePriorityData,
} from '../../../common/interfaces/BoardContext';

interface AppContextProps {
  loggedUser: UserDTO;
  setMessages: (messages: NotificationMessage | NotificationMessage[]) => void;
}
interface BoardContextProps {
  boardId: string;
  priorities: PriorityDTO[];
  addPriority: (item: PriorityDTO) => void;
  updatePriority: (priorityId: string, data: UpdatePriorityData) => void;
  removePriority: (priorityId: string) => void;
}
interface ExternalProps {
  fixedPositionParent?: boolean;
  directionalButton?: IDirectionalButton;
  setSelectedContext?: (context: TContextMenu) => void;
  selectedPriority?: PriorityDTO | null;
  legendText: string;
  selectedContext: TContextMenu;
}
interface Props
  extends ExternalProps,
    AppContextProps,
    BoardContextProps,
    WithTranslation {}

interface State {
  reorder: boolean;
  showDeletionPrompt: boolean;
}

class PriorityDetailMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reorder: false,
      showDeletionPrompt: false,
    };
  }

  addPriority = async (
    e: FormEvent<HTMLFormElement>,
    priorityData: AddPriorityDTO,
  ) => {
    e.preventDefault();

    try {
      const result = await createBoardPriority(
        this.props.boardId,
        priorityData,
      );
      this.props.addPriority(result);

      this.props.setSelectedContext &&
        this.props.setSelectedContext('managePriorities');
    } catch (err) {
      console.debug(err);
    }
  };

  editPriority = async (priorityData: PriorityDTO) => {
    try {
      const updatedPriority = await updatePriority(priorityData);
      this.props.updatePriority(updatedPriority.id, updatedPriority);

      this.props.setSelectedContext &&
        this.props.setSelectedContext('managePriorities');
    } catch (error) {
      showErrorNotifications(error, this.props.setMessages);
    }
  };

  deletePriority = async (priorityId: string) => {
    try {
      await deletePriority(priorityId);
      this.props.removePriority(priorityId);
    } catch (error) {
      showErrorNotifications(error, this.props.setMessages);
    }
  };

  handleSubmit = (
    e: FormEvent<HTMLFormElement>,
    priorityData: PriorityDTO | AddPriorityDTO,
  ) => {
    e.preventDefault();
    if (this.props.selectedContext === 'addPriority') {
      this.addPriority(e, priorityData as AddPriorityDTO);
    } else if (this.props.selectedContext === 'editPriority') {
      this.editPriority(priorityData as PriorityDTO);
    }
  };

  handleDelete = () => {
    if (this.props.selectedPriority) {
      this.deletePriority(this.props.selectedPriority.id!);
      this.props.setSelectedContext &&
        this.props.setSelectedContext('managePriorities');
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.directionalButton && (
          <li>
            <DirectionalButton
              directionalButton={{
                text: this.props.directionalButton.text,
                direction: this.props.directionalButton.direction,
                onClick: this.props.directionalButton.onClick,
              }}
              className="flex-h-start"
            />
          </li>
        )}
        {this.state.showDeletionPrompt && (
          <Dialog
            title="Priority deletion"
            message={`Permanently delete this priority?`}
            info={
              <p className="text-sm faint-text">
                <span className="accent-text-red fas fa-exclamation-circle"></span>{' '}
                <span>This operation is irreversible.</span>
              </p>
            }
            cancelText="Cancel"
            confirmText="Permanently delete"
            onCancel={() => this.setState({ showDeletionPrompt: false })}
            onConfirm={() => {
              this.setState({
                showDeletionPrompt: false,
              });
              this.handleDelete();
            }}
          />
        )}
        <li>
          <div className="fill">
            <div className="flex-row fill">
              <div className="column pt-0">
                <hr />
                <PriorityForm
                  legendText={this.props.legendText}
                  handleSubmit={this.handleSubmit}
                  loggedUser={this.props.loggedUser}
                  selectedPriority={this.props.selectedPriority}
                  setSelectedContext={this.props.setSelectedContext}
                >
                  {this.props.selectedContext === 'editPriority' && (
                    <>
                      <hr />
                      <div className="initiate-delete">
                        <Button
                          className="secondary-button"
                          onClick={() =>
                            this.setState({
                              showDeletionPrompt: true,
                            })
                          }
                        >
                          <span className="text">{t('deleteButtonText')}</span>
                        </Button>
                      </div>
                    </>
                  )}
                </PriorityForm>
              </div>
            </div>
          </div>
        </li>
      </>
    );
  }
}

const AppContextAdapter = {
  ctx: AppContext,
  adapt: (ctx: IAppContext): AppContextProps => {
    return {
      loggedUser: ctx.loggedUser!,
      setMessages: ctx.notifications.setMessages!,
    };
  },
};
const BoardContextAdapter = {
  ctx: BoardContext,
  adapt: (ctx: IBoardContext): BoardContextProps => {
    return {
      boardId: ctx.board.id,
      priorities: ctx.board.priorities,
      addPriority: ctx.addPriority,
      updatePriority: ctx.updatePriority,
      removePriority: ctx.removePriority,
    };
  },
};

export default withContextAdapters<
  ExternalProps,
  IAppContext,
  AppContextProps,
  IBoardContext,
  BoardContextProps
>(
  withStyledTranslation('priorityDetailMenu')(PriorityDetailMenu),
  AppContextAdapter,
  BoardContextAdapter,
);
