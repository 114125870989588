import { EditorState, LexicalEditor } from 'lexical';
import { useEffect, useMemo, useCallback } from 'react';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import CONDUIT_BRIDGE from '../EditorControl/themes/conduit';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import ToolbarPlugin from '../EditorControl/plugins/ToolbarPlugin';
// import ToolbarPluginLegacy from '../LexicalControl/plugins/ToolbarPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import AutoLinkPlugin from '../EditorControl/plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import { AttachmentDTO } from '../../../common/api/dtos/Card';
import { AttachmentBlockNode } from './plugins/nodes/AttachmentBlockNode';

const theme = CONDUIT_BRIDGE;

interface EditorControlProps {
  onChange: (value: string | null) => void;
  initialEditorState?: string | null;
  namespace?: string;
  cardAttachments: AttachmentDTO[];
}

function onError(error: unknown) {
  console.error(error);
}

function ContentChangedPlugin({
  onChange,
}: {
  onChange: (editorState: EditorState) => void;
}) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
  }, [editor, onChange]);
  return null;
}

export default function EditorControl({
  onChange,
  initialEditorState,
  namespace,
  cardAttachments,
}: EditorControlProps) {
  const initialConfig = useMemo(
    () => ({
      namespace: namespace ?? 'EditorControl',
      theme,
      onError,
      editorState: initialEditorState
        ? (editor: LexicalEditor) =>
            editor.parseEditorState(JSON.parse(initialEditorState))
        : undefined,
      editable: true,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        AttachmentBlockNode,
      ],
      transformers: TRANSFORMERS,
    }),
    [initialEditorState, namespace],
  );

  const handleChange = useCallback(
    (editorState: EditorState | null) => {
      if (editorState == null) {
        onChange(null);
      } else {
        const editorStateJSON = editorState.toJSON();
        onChange(JSON.stringify(editorStateJSON));
      }
    },
    [onChange],
  );

  return (
    <div className="flex-row fill">
      <div className="column pt-0 pb-2xs">
        <LexicalComposer initialConfig={initialConfig}>
          <EditorContent
            editorState={initialEditorState}
            handleChange={handleChange}
            cardAttachments={cardAttachments}
          />
          <AutoLinkPlugin />
        </LexicalComposer>
      </div>
    </div>
  );
}

function EditorContent({
  editorState,
  handleChange,
  cardAttachments,
}: {
  editorState?: string | null;
  handleChange: (editorState: EditorState | null) => void;
  cardAttachments: AttachmentDTO[];
}) {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);

  useEffect(() => {
    if (editorState && editor) {
      editor.update(() => {
        const parsedState = editor.parseEditorState(JSON.parse(editorState));
        editor.setEditorState(parsedState);
      });
    }
  }, [editorState, editor]);

  useEffect(() => {
    handleChange(isEmpty ? null : editor.getEditorState());
  }, [isEmpty, editor, handleChange]);

  return (
    <div className="lexical-control">
      <div className="editor-inner">
        <ToolbarPlugin cardAttachments={cardAttachments} />
        {/* <ToolbarPluginLegacy /> */}
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              style={{
                marginTop: '-10px',
                paddingTop: '30px',
                minHeight: '100px',
              }}
              className="card editor-input opaque"
              spellCheck={true}
              disabled={false}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        {/* AutoFocusPlugin causes the box to drop from the top instead of staying fixed */}
        <AutoFocusPlugin />
        <ClearEditorPlugin />
        <HistoryPlugin />
        <LinkPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <CheckListPlugin />
        <ContentChangedPlugin
          onChange={(editorState: EditorState) => {
            // This ensures the change handling logic is centralized.
            return isEmpty ? handleChange(null) : handleChange(editorState);
          }}
        />
      </div>
    </div>
  );
}
