import React, { Component, FormEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { resendConfirmation } from '../../../../common/api/endpoints/register';
import AppContext from '../../../../common/contexts/AppContext';
import { showErrorNotifications } from '../../../../common/helpers/showNotifications';
import { TRequestStatus } from '../../../../common/types/RequestStatus';
import Button from '../../../controls/Button';
import PageMessage from '../../../partials/PageMessage/PageMessage';
import RequestStatus from '../../../partials/RequestStatus/RequestStatus';
import LinkButton from '../../../controls/LinkButton';

interface State {
  status: TRequestStatus;
  errorMessages: string[];
  resendStatus: TRequestStatus;
  isConfirmationSent: boolean;
}

interface FormData {
  email: string;
}

export type Props = RouteComponentProps;

class ResendConfirmation extends Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      status: 'loading',
      errorMessages: [],
      resendStatus: 'idle',
      isConfirmationSent: false,
    };
  }

  resendConfirmationLink = async (e: FormEvent) => {
    e.preventDefault();

    this.setState({
      resendStatus: 'loading',
    });

    try {
      const email = (this.props.location.state as FormData).email;

      await resendConfirmation(email);
      this.setState({
        resendStatus: 'success',
        isConfirmationSent: true,
      });
    } catch (err) {
      const error = Array.isArray(err) ? err : [err];
      showErrorNotifications(error, this.context.notifications.setMessages);
      this.setState({
        resendStatus: 'error',
      });
    }
  };

  renderPageMessageContent = () => {
    if (this.state.isConfirmationSent) {
      return (
        <React.Fragment>
          <p>
            Confirmation email sent. Please check your email before you
            continue.
          </p>
          <br />
          <p>
            If you have already confirmed your email, click the button below to
            go back to the login page.
          </p>
          <br />
          <LinkButton
            to="/"
            className="primary-button"
          >
            <span className="text">Login</span>
          </LinkButton>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>
            Click the button below to receive a confirmation email for the email{' '}
            {(this.props.location.state as FormData).email}.
          </p>
          <br />
          <Button
            className="primary-button ml-sm"
            onClick={this.resendConfirmationLink}
          >
            <RequestStatus status={this.state.resendStatus} />
            <span className="text">Resend Confirmation Link</span>
          </Button>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <PageMessage>
        <h1 className="primary-title normalcase pb-xs mb-sm">
          Resend Confirmation
        </h1>
        {this.renderPageMessageContent()}
      </PageMessage>
    );
  }
}

export default ResendConfirmation;
