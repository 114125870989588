import { Component } from 'react';
import { TRequestStatus } from '../../../common/types/RequestStatus';
import { TeamDTO } from '../../../common/api/dtos/Team';
import Button from '../../controls/Button';
import { leaveTeam, listTeams } from '../../../common/api/endpoints/team';
import Dialog from '../../controls/Dialog';
import { WithTranslation } from 'react-i18next';
import { withStyledTranslation } from '../StyledTranslation/StyledTranslation';
import MessageBar from '../../controls/MessageBar';
import ContentLoader from '../ContentLoader/ContentLoader';

interface Props extends WithTranslation {
  history: any;
}

interface State {
  showLeavePrompt: boolean;
  selectedTeamId: string | null;
  status: TRequestStatus;
  teams: TeamDTO[];
  loadingMore: boolean;
  nextPage: string | null;
}

export class Membership extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showLeavePrompt: false,
      selectedTeamId: null,
      status: 'loading',
      teams: [],
      loadingMore: false,
      nextPage: null,
    };
  }

  componentDidMount() {
    this.fetchAllTeams();

    window.addEventListener('scroll', this.handleScroll); // Add scroll listener
    window.addEventListener('resize', this.handleResize); // Add resize listener

    // Check if we need to load more teams on mount
    this.checkIfMoreContentNeeded();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Clean up scroll listener
    window.removeEventListener('resize', this.handleResize); // Clean up resize listener
  }

  checkIfMoreContentNeeded = () => {
    if (this.state.loadingMore) return;
    if (
      document.documentElement.scrollHeight - 100 <= window.innerHeight &&
      this.state.nextPage
    ) {
      this.setState({ loadingMore: true }, () => {
        this.fetchMoreTeams();
      });
    }
  };

  handleResize = () => {
    this.checkIfMoreContentNeeded(); // Trigger content check on resize
  };

  handleScroll = () => {
    const { loadingMore, nextPage } = this.state;

    if (loadingMore || !nextPage) return;

    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      this.setState({ loadingMore: true }, () => {
        this.fetchMoreTeams();
      });
    }
  };

  fetchMoreTeams = async () => {
    const { nextPage } = this.state;

    try {
      const { teams: newTeams, nextPage: newNextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
        nextPage,
      );

      this.setState(
        (prevState) => ({
          teams: [...prevState.teams, ...newTeams],
          loadingMore: false,
          nextPage: newNextPage,
        }),
        () => {
          // Check if more content is needed after updating state
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        loadingMore: false,
        status: 'error',
      });
    }
  };

  fetchAllTeams = async () => {
    this.setState({ status: 'loading' });
    try {
      const { teams, nextPage } = await listTeams(
        process.env.REACT_APP_TEAM_LAZYLOAD_PAGE_SIZE,
      );

      this.setState(
        {
          teams,
          status: 'success',
          nextPage,
        },
        () => {
          this.checkIfMoreContentNeeded();
        },
      );
    } catch (err) {
      this.setState({
        status: 'error',
        teams: [],
      });
    }
  };

  setShowLeavePrompt = (
    showLeavePrompt: boolean,
    teamId: string | null = null,
  ) => {
    this.setState({
      showLeavePrompt,
      selectedTeamId: teamId,
    });
  };

  handleSubmit = async (teamId: string) => {
    this.setState({
      status: 'loading',
    });

    try {
      await leaveTeam(teamId);
      this.setState({
        status: 'success',
        teams: this.state.teams.filter((team) => team.id !== teamId),
      });
    } catch (err) {
      this.setState({
        status: 'error',
      });
    }
  };

  render() {
    const { t } = this.props;
    const { teams, showLeavePrompt, selectedTeamId, status, loadingMore } =
      this.state;

    const foreignTeams = teams.filter((team) => !team.owned);

    return (
      <>
        <div className="flex-row">
          <div className="column pb-xs">
            <h1 className="primary-title h3 normalcase">
              {t('membershipTitle')}
            </h1>
          </div>
        </div>
        <div
          className="flex-row fill"
          style={{ maxWidth: '1000px' }}
        >
          <div className="column pt-0">
            {this.state.status === 'loading' && (
              <>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
                <div className="card accordion-row-like transparent">
                  <ContentLoader height="66px" />
                </div>
              </>
            )}
            {this.state.status !== 'loading' && foreignTeams.length === 0 && (
              <div className="card accordion-row-like transparent">
                <MessageBar
                  type="info"
                  icon="fa-info-circle"
                >
                  {t('noTeamsInfo')}
                </MessageBar>
              </div>
            )}
            {foreignTeams.map((team) => {
              return (
                <div
                  key={team.id}
                  className="card accordion-row-like"
                >
                  <div className="flex-row spread flex-v-center">
                    <div className="column py-0">
                      <h2 className="text-sm text-400">{team.name}</h2>
                    </div>
                    <div className="column py-0 flex-h-end">
                      <Button
                        className="secondary-button"
                        onClick={() => this.setShowLeavePrompt(true, team.id)}
                      >
                        {t('leaveTeamButton')}
                      </Button>
                      {showLeavePrompt && selectedTeamId === team.id && (
                        <Dialog
                          title={t('leavingTeamDialogTitle')}
                          size={'medium'}
                          message={t('leavingTeamDialogMessage', {
                            teamName: team.name,
                          })}
                          confirmText={t('leavingTeamDialogConfirmText')}
                          cancelText={t('leavingTeamDialogCancelText')}
                          info={
                            <>
                              <MessageBar
                                type="danger"
                                icon="fa-exclamation-circle"
                              >
                                {t('leavingTeamDialogInfo')}
                              </MessageBar>
                            </>
                          }
                          status={status}
                          onConfirm={() => this.handleSubmit(team.id)}
                          onCancel={() => this.setShowLeavePrompt(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {/* Infinite Scroll Loader */}
            {loadingMore && <div className="loader"></div>}
          </div>
        </div>
      </>
    );
  }
}

export default withStyledTranslation('membership')(Membership);
