import React, { Component, ChangeEvent } from 'react';
import { withStyledTranslation } from '../../../partials/StyledTranslation/StyledTranslation';
import { WithTranslation } from 'react-i18next';
import Button from '../../../controls/Button';

interface Props extends WithTranslation {
  uxStep: number;
  onNext: (teamName: string) => void;
  familiarity: 'exploring' | 'usedBefore' | 'expert' | undefined;
  teamName: string;
}

interface State {
  teamName: string;
}

class StepSpace extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      teamName: this.props.teamName || '',
    };
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ teamName: value });
  };

  handleNext = () => {
    const { onNext } = this.props;
    onNext(this.state.teamName);
  };

  render() {
    const { t, familiarity } = this.props;
    const { teamName } = this.state;

    const headingText = {
      exploring: t('step2.headingExploring'),
      usedBefore: t('step2.headingUsedBefore'),
      expert: t('step2.headingExpert'),
    }[familiarity ?? 'exploring'];

    return (
      <div className="flex-row fill">
        <div className="column flex-v-center flex-h-center">
          <span className="oobe-icon icon bg-text fad fa-users-cog"></span>
        </div>
        <div className="column large text-left flex-v-center">
          <div>
            <h2>{t('step2.title', { count: this.props.uxStep })}</h2>
            <div className="scrollable-content flex-v-center">
              <div>
                <p>{headingText}</p>
                <input
                  type="text"
                  placeholder={t('step2.teamNamePlaceholder')}
                  maxLength={255}
                  value={teamName}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            <div className="button-container">
              <Button
                className="primary-button"
                onClick={this.handleNext}
                disabled={!teamName}
              >
                {t('next')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyledTranslation('stepSpace')(StepSpace);
