import React, { memo } from 'react';
import Thumbnail from './Thumbnail/Thumbnail';

interface BorddoUserProps {
  size?: 16 | 24 | 32 | 40 | 48 | 64 | 96;
  avatarData?: string;
  title?: string;
  email?: string;
  avatarOnly?: boolean;
}

const BorddoUser: React.FC<BorddoUserProps> = ({
  size,
  avatarData,
  title,
  email,
  avatarOnly,
}) => {
  return (
    <div className="flex-v-center">
      <Thumbnail
        classes={`size-${size || 32}`}
        avatarData={avatarData}
        title={title}
      />
      {!avatarOnly && (
        <div>
          {email && <span className="text">{email}</span>}
          <span className="text">{title}</span>
        </div>
      )}
    </div>
  );
};

export default memo(BorddoUser);
